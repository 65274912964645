import ApiIntegrface from "../api"
const hpoApiEndpoint='https://ontology.jax.org'


export function searchHpoTerms(query,page, limit, callback){
  const api = new ApiIntegrface(hpoApiEndpoint, false)
  const params={
    q:query,
    page:page,
    limit:limit
  }
  const url = '/api/hp/search'
  api.get(url, params, callback)
}

export function searchHpoDiseases(query,page, limit, callback){
  const api = new ApiIntegrface(hpoApiEndpoint, false)
  const params={
    q:query,
    page:page,
    limit:limit
  }
  const url = '/api/network/search/disease'
  api.get(url, params, callback)
}




export function getPhenotypeGenesHpo(termId, offset, max, callback){
  const api = new ApiIntegrface(hpoApiEndpoint, false)
  const params={
    offset:offset,
    max:max
  }
  const url = '/api/hpo/term/'+ termId +'/genes'
  api.get(url, params, callback,null,null,null, false)

}



export function getPhenotypeHpo(termId, callback){
  const api = new ApiIntegrface(hpoApiEndpoint, false)
  const url = '/api/hp/terms/' + termId
  api.get(url, null, callback,null,null,null, false)
}
export function getPhenotypeHpoParents(termId, callback){
  const api = new ApiIntegrface(hpoApiEndpoint, false)
  const url = '/api/hp/terms/' + termId + '/parents'
  api.get(url, null, callback,null,null,null, false)
}
export function getPhenotypeHpoChildren(termId, callback){
  const api = new ApiIntegrface(hpoApiEndpoint, false)
  const url = '/api/hp/terms/' + termId + '/children'
  api.get(url, null, callback,null,null,null, false)
}

export function getAnnotation(diseaseId, callback){
  const api = new ApiIntegrface(hpoApiEndpoint, false)
  const url = '/api/network/annotation/' + diseaseId
  api.get(url, null, callback,null,null,null, false)
}