<template>
  
<div class="ml-4 d-flex flex-column">
  <div class="d-flex flex-row">
    <div class="text-h5 font-weight-medium" > <v-icon color="primary">mdi-dna</v-icon> {{ dataset?.datasetType?.name }}</div>    
    <div class="ml-auto">
      <router-link v-for="item in menuItems" :to="item.to" :key="item.title" class="text-decoration-none ml-8">
          <v-icon color="primary">{{ item.icon }}</v-icon> {{ item.title }}
      </router-link>
    </div>
  </div>
    <div class="pl-8 mt-4" >
       <router-view :key='$route.fullPath'/>
    </div>
</div>
</template>

<script>
import {getDataset} from '@/api/dataset'

  export default {
    name: 'ProjectDataset',
    data () {
      return {
        menuItems: [
          { title:'INFORMATION',    icon:"mdi-information-outline", to: {name:'DatasetInfo', params:{ iD:this.$route.params.id, datasetId: this.$route.params.datasetId }}} ,
          { title:'IMPORT DATA',    icon:"mdi-upload-outline", to:{ name:'DatasetUpload',    params:{ id:this.$route.params.id, datasetId: this.$route.params.datasetId }}} ,
          { title:'MANAGE COLUMNS', icon:"mdi-table-cog", to:{name:'DatasetColumns',         params:{ id:this.$route.params.id, datasetId: this.$route.params.datasetId }}},
          ],
        dataset:{
          name:'',
        }
      }
    },
    created () {
      const _this = this
      getDataset(this.$route.params.datasetId, function(ds){
        _this.dataset = ds
      })
    }
  }
</script>
