<template>
  <v-app>
    <v-navigation-drawer mobile-breakpoint="0" expand-on-hover mini-variant mini-variant-width="60"  app dark color="black"  >
      <!-- overlay-opacity="50"  -->
      <div class="d-flex flex-column font-weight-bold" style="height:100%">
        <div >
          <v-list shaped dense>
            <v-list-item class="mt-2" v-for="item in menuItems" :key="item.text" link :to="item.to" >
              <v-list-item-icon>
                <v-icon  size="33" >{{item.icon}}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="text-uppercase">{{item.text}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

          </v-list>
        </div>
        <v-spacer class="mr-16"></v-spacer>
        <div>
          <v-divider></v-divider>
          <v-list shaped nav>
            <v-list-item link to="/admin/profile/changepassword">
              <v-list-item-icon>
                <v-icon  size="33">mdi-key</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="text-uppercase">Change Password</v-list-item-title>
            </v-list-item>
            <v-list-item @click="logout()">
              <v-list-item-icon>
                <v-icon  size="33">mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>LOGOUT</v-list-item-title>
            </v-list-item>

          </v-list>
        </div>
      </div>

    </v-navigation-drawer>
    <v-main >
          <v-slide-x-transition mode="out-in">
            <router-view></router-view>
          </v-slide-x-transition>
    </v-main>

  </v-app>
</template>

<script>
  import { getPanelSettings } from '@/api/settings.js'

export default {
  name: 'Home',

  data: () => ({
    iconColor:'#75ca71',
    iconColordark:'rgba(14, 45, 76, 0.8)',
    logo:'lucid',

    menuItems: [
        {
          text: 'Cohorts',
          to: '/admin/cohorts',
          icon: 'mdi-ev-plug-type2'
        },
        {
          text: 'Projects',
          to: '/admin/projects',
          icon: 'mdi-database'
        },
        {
          text: 'Accounts',
          to: '/admin/users',
          icon: 'mdi-account-multiple'
        },
        {
          text: 'Annotations',
          to:'/admin/annotations',
          icon: 'mdi-format-annotation-plus'
        },
        {
          text: 'Transcripts',
          to:'/admin/transcripts',
          icon: 'mdi-align-vertical-center'
        },
        {
          text: 'Manual Curaton',
          to:'/admin/manualcuration',
          icon: 'mdi-progress-tag'

        },        
        {
          text: 'Settings',
          to:'/admin/settings',
          icon: 'mdi-cogs'
        }
      ],
       profileMenuItems: [
        {
          text: 'Profile',
          to: '/admin/profile/changepassword',
          icon: 'mdi-account',
        },
        {
          text: 'Logout',
          to: null,
          icon: 'mdi-logout',
        }
      ]

  }),
  methods: {
        logout () {
      this.$store.commit('logout')
      this.$router.push('/login')
    },
  },
  mounted() {
    const _this = this
    getPanelSettings('ui_logo',(res)=>{_this.logo = res.value})
  }

  //   mounted: function() {
  //   let elHtml = document.getElementsByTagName('html')[0]
  //   elHtml.style.overflowY = 'hidden'
  // },
  // destroyed: function() {
  //   let elHtml = document.getElementsByTagName('html')[0]
  //   elHtml.style.overflowY = null
  // }

};
</script>
