 <template>
  <div class="d-flex justify-center pt-1" >
    <div id="pedigreechart"></div>
  </div>

</template>
<script>
// import { SVG } from '@svgdotjs/svg.min.js'
// var SVG = require("browser-tool");

import { getSamplePedigree } from "@/api/sample.js";
import { uuidv4 } from "@/utils/generators.js"
const ROWS = 10
const COLUMNS = 20
let locations = Array(ROWS).fill()
for(let i=0; i< ROWS; i++){
  locations[i] = Array(COLUMNS).fill(null)  
}

class Person {
  #id;
  #name;
  #disease_status;
  #gender;
  #age;
  #father;
  #mother;
  #children=[];
  #spouses=[];
  #relatives=[];
  #twins=[];
  #i;
  #j;
  #draw;
  #group;
  #isProband=false;
  
  get textColor(){
    if(this.#isProband){
      return '#0393A8'
    } else {
      return 'black'
    }
  }
  static get NODE_WIDTH() {
    return 20;
  }
  static get MATRIX_CELL_SIZE() {
    return Person.NODE_WIDTH * 1.7;
  }
  
  get_disease_status_color(status) {
    switch (status) {
      case "affected":
        return "black";
      case "unaffected":
        return "white";
      case "unknown":
        return "grey";
      default:
        return "#aaaaaa";
    }
  }
  constructor(draw, id, name, disease_status, gender, isProband, age = null) {
    this.#draw = draw;
    this.#id = id;
    this.#name = name;
    this.#disease_status = disease_status;
    this.#gender = gender;
    this.#age = age;
    this.#isProband = isProband
    // this.draw()
  }
  drawLineToSpouse(){
    if(this.hasSpouse && this.#j < this.spouses[0].location.j){
      const line = this.#draw.line(0, 0, 2*Person.MATRIX_CELL_SIZE  , 0).stroke({ color:'#000000', width: 1 }).move(Person.MATRIX_CELL_SIZE - Person.MATRIX_CELL_SIZE/2,Person.MATRIX_CELL_SIZE/2+1)
      this.#group.add(line)
      if(this.relatives.findIndex( rel => rel.id == this.spouses[0].id ) > -1 || this.spouses[0].relatives.findIndex( rel => rel.id == this.id ) > -1  ){
        const clonedLine = line.clone();
        line.move(Person.MATRIX_CELL_SIZE - Person.MATRIX_CELL_SIZE/2, Person.MATRIX_CELL_SIZE/2 - 1 )
        clonedLine.move(Person.MATRIX_CELL_SIZE - Person.MATRIX_CELL_SIZE/2, Person.MATRIX_CELL_SIZE/2 + 2 )
        this.#group.add(clonedLine)
      }
    }

  }
  drawPersonShape(){
    const obj_pos = (Person.MATRIX_CELL_SIZE - Person.NODE_WIDTH) / 2;
    let obj = null;
    if (this.#gender == "M") {
      obj = this.#draw.rect(Person.NODE_WIDTH*0.9, Person.NODE_WIDTH*0.9);
    } else if (this.#gender == "F") {
      obj = this.#draw.circle(Person.NODE_WIDTH);
    } else {
      const s= Person.NODE_WIDTH * 0.85
      obj = this.#draw.rect(s, s).rotate(45 ,s-2,s+2);
    }
    obj.fill(this.get_disease_status_color(this.#disease_status)); // Color based on disease status
    obj.stroke({ width: 1, color: "black" });
    obj.move(obj_pos, obj_pos);
    this.#group.add(obj);

    if(this.#isProband){
        const arrowLength = 9;
        // let bias = this.#gender == 'M' ? 0 : (this.#gender == 'F' ? 1 : 2)
        const l = this.#draw.line(0, Person.MATRIX_CELL_SIZE , arrowLength , Person.MATRIX_CELL_SIZE - arrowLength ).stroke({ width: 1, color: this.textColor });
        const head = this.#draw.polyline([0,0, -8,4 ,-8,-4]).fill(this.textColor).move(arrowLength - 4 - 3, Person.MATRIX_CELL_SIZE - arrowLength - 4 + 2 ).rotate(-45);
        this.#group.add(l)
        this.#group.add(head)
    }
  }
  drawTexts(){
    const obj_pos = (Person.MATRIX_CELL_SIZE - Person.NODE_WIDTH) / 2;
    // const TRIM_LIMIT=4
    const text = this.#draw
      .text( this.#name)//.length > TRIM_LIMIT ? this.#name.substr(0,TRIM_LIMIT) + '...' : this.#name)
      .font({ size: 10, family: "Arial"  })
      .fill(this.textColor)
      ;

      this.#group.add(text);
      text.on('mouseout',()=>{
        text.font({ weight: 'regular' });
      })
      text.on('mouseover',()=>{
        text.font({ weight: 'bold' });

      })

    text.move( Math.max( Math.round((Person.MATRIX_CELL_SIZE - text.length())/2), 0) , Person.MATRIX_CELL_SIZE )
    // console.log(Math.round((Person.MATRIX_CELL_SIZE - text.length())/2))
    if (this.#age) {
      const age = this.#draw
        .text(this.#age.toString() + "y")
        .move(0, obj_pos + Person.NODE_WIDTH + 8)
        .font({ size: 9, family: "Arial" })
        .fill(this.textColor);
      this.#group.add(age);
    }
  }
  drawChildren(){
    if(this.hasChildren && this.#j < this.spouses[0].location.j ){
      const y2 = 1.5*Person.MATRIX_CELL_SIZE 
      // console.log('X1', 1.5*Person.MATRIX_CELL_SIZE)
      const vline = this.#draw.line(1.5*Person.MATRIX_CELL_SIZE, Person.MATRIX_CELL_SIZE/2 , 1.5*Person.MATRIX_CELL_SIZE , y2).stroke({ color:'#000000', width: 1 })
      this.#group.add(vline)

      const min = this.children.reduce((res,child)=>{  return Math.min(child.location.j, res)  }, 100000)
      const max = this.children.reduce((res,child)=>{  return Math.max(child.location.j, res) }, -1)

      //HORIZONTAL LINE
      const baseY = this.#i * Person.MATRIX_CELL_SIZE + y2
      let startX = min + 0.5 
      let endX = max +0.5
      if(this.children[0].hasTwin){
        startX += 1 
      }
      if(this.children[this.children.length-1].hasTwin){
        endX -= 1 
      }
      this.#draw.line(startX * Person.MATRIX_CELL_SIZE , 
                      baseY, 
                      endX * Person.MATRIX_CELL_SIZE , 
                      baseY )
                      .stroke({ color:'#000000', width: 1 })
       //VERTICAL LINE FOREACH CHILD
      this.children.forEach((child,index) => {
        const x = child.location.j + 0.5
        let twinOffset = 0
        if(child.hasTwin){
          const next = this.children[index + 1]
          if(next && next.hasTwin && next.twins.findIndex( x => x.#id == child.#id) >= 0){
            twinOffset = 1 
          }
          const prev = this.children[index - 1]
          if(prev && prev.hasTwin && prev.twins.findIndex( x => x.#id == child.#id) >= 0){
            twinOffset = -1 
          }
        }
        const y = baseY + Person.MATRIX_CELL_SIZE / 2 +  (Person.MATRIX_CELL_SIZE - Person.NODE_WIDTH) / 2
        // console.log('X2',(x + twinOffset) * Person.MATRIX_CELL_SIZE)
        this.#draw.line((x + twinOffset) * Person.MATRIX_CELL_SIZE, 
                      baseY, 
                      x * Person.MATRIX_CELL_SIZE, 
                      y )
                      .stroke({ color:'#000000', width: 1 })
        
      })
    }
  }
  draw() {
    if (this.#group !== undefined){
      return
    }
    this.#group = this.#draw.group();
    this.drawLineToSpouse()

    //DEBUG Do not remove!
    const testBorder = this.#draw.rect(Person.MATRIX_CELL_SIZE, Person.MATRIX_CELL_SIZE).stroke({ width: 0, color: "red" }).fill('transparent').move(0,0);
    this.#group.add(testBorder)

    this.drawPersonShape()
    this.drawTexts()
    this.drawChildren()
    
    this.#group.move(this.#j * Person.MATRIX_CELL_SIZE, this.#i * Person.MATRIX_CELL_SIZE)


  }
  // move(){
  //   this.#obj.move(this.#j * Person.MATRIX_CELL_SIZE, this.#i * Person.MATRIX_CELL_SIZE)
  // }

  // CHILDREN
  addChild(p){
    if(p){
      this.#children.push(p);
    }
  }
  get hasChildren(){
    return this.#children.length > 0
  }
  get children(){
    return this.#children
  }
  orderChildren(){
    if( this.hasChildren ){
      // //MOVE CHILDREN WITH PARENT-IN-LAW TO THE END
      // for(let i=0; i< this.#children.length; i++){
      //   if(this.#children[i].hasSpouse ){
      //     const sp = this.#children[i].spouses[0]
      //     if(sp.hasFather || sp.hasMother){

      //     }
      //     let twinPos=[]
      //     this.#children[i].twins.forEach( twin => 
      //       twinPos.push(this.#children.findIndex(ch => ch.#id == twin.#id))
      //     )
      //     twinPos.forEach(idx => {
      //       let tmp = this.#children[i+1]
      //       this.#children[i+1] = this.#children[idx]
      //       this.#children[idx] = tmp
      //       i++;
      //     })
      //   }
      // }

      
      //ORDER TWINS
      for(let i=0; i< this.#children.length; i++){
        if(this.#children[i].hasTwin){
          let twinPos=[]
          this.#children[i].twins.forEach( twin => 
            twinPos.push(this.#children.findIndex(ch => ch.#id == twin.#id))
          )
          twinPos.forEach(idx => {
            let tmp = this.#children[i+1]
            this.#children[i+1] = this.#children[idx]
            this.#children[idx] = tmp
            i++;
          })
        }
      }
    }
  }
  // SPOUSE
  addSpouse(p){
    if(p){
      this.#spouses.push(p);
    }
  }
  get hasSpouse(){
    return this.#spouses.length > 0
  }
  get spouses(){
     return this.#spouses 
  }
  // RELATIVES
  addRelative(p){
    if(p){
      this.#relatives.push(p);
    }
  }
  get hasRelative(){
    return this.#relatives.length > 0
  }
  get relatives(){
     return this.#relatives 
  }
  // TWINS
  addTwin(t){
    if(t){
      this.#twins.push(t);
    }
  }
  get hasTwin(){
    return this.#twins.length > 0
  }
  get twins(){
     return this.#twins
  }
  //FATHER and MOTHER
  setFather(f){
    this.#father = f
  }
  setMother(m){
    this.#mother = m
  }
  get hasFather(){
    return this.#father !== undefined
  }
  get hasMother(){
    return this.#mother !== undefined
  }



  get descendatntsWidth(){
    let sum = this.#children.length;
    this.#children.forEach(child => sum += (child.hasSpouse ? 1 : 0))
    let sum2 = 0
    this.#children.forEach(child => sum2 += child.descendatntsWidth)
    return sum + sum2
  }

  reArrange(){
    if(this.hasChildren){
      // console.log(this.name, this.children)

      const min = this.children.reduce((res,child)=>{
        let m = Math.min(child.location.j, res)
        // if(child.hasSpouse){
        //   m = Math.min(child.spouses[0].location.j, m)
        // } 
        return m
      }, 100000)
      const max = this.children.reduce((res,child)=>{
        let m = Math.max(child.location.j, res)
        // if(child.hasSpouse){
        //   m = Math.max(child.spouses[0].location.j, m)
        // } 
        return m
      }, -1)
      
      const center = min +  Math.round( (max - min) / 2)
      // console.log(this.name, 'min:', min, 'max:',max, 'center:', center, 'this:', this.#j , 'spouse:', this.spouses[0].location.j)
      if( Math.min(this.#j , this.spouses[0].location.j) + Math.abs(this.#j - this.spouses[0].location.j)/2 != center ){
        locations[this.#i][this.#j] = null
        locations[this.spouses[0].location.i][this.spouses[0].location.j] = null

        if( this.location.j < this.spouses[0].location.j ){
          this.#j = center - 1
          this.spouses[0].setJ(center + 1)

        } else {
          this.#j = center + 1
          this.spouses[0].setJ(center - 1)
        }
        locations[this.#i][this.#j] = this.#id
        locations[this.spouses[0].location.i][this.spouses[0].location.j] = this.spouses[0].id
      }
    }
  }
  setJ(j){
    this.#j = j
  }
  setI(i){
    this.#i = i
  }
  setLocation(i,j){
    if(this.isLocated){
      return
    }
    
    this.#i = i
    this.#j = j
    locations[i][j] = this.#id
    if(this.hasSpouse){
      this.spouses[0].setLocation(i,this.#j + 2)
    }
    if(this.hasChildren){
      let k = (this.#j + this.spouses[0].location.j) / 2
      let add = 0
      this.#children.forEach(child => {
        if(child.hasChildren){
           k += add
        }
        child.setLocation(i+2, k)
        k += 2
        if(child.hasSpouse){
          k += 2
        }
        add = child.descendatntsWidth
      })

    }
  }
  get isLocated(){
    return this.#i !== undefined
  }
  get location(){
    return {i: this.#i, j: this.#j}
  }


  get [Symbol.toStringTag]() {
    return this.name
  }

  get name(){
    return this.#name
  }
  get id(){
    return this.#id
  }  
}

export default {
  name: "PatientPedigree",
  props: {
    sampleInfo: {
      type: Object,
      required: true
    },
    cohortId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    persons:{},
    pedigreeDt: [],

  }),
  computed: {
    pedigreeData() {
      return this.pedigreeDt.map((item) => {
        // item.data.gender = 'unknown'
        item.data.avatar = `/img/pedigree/pedigree-chart-${item.data.gender}-${item.data.disease_status}.png`;
        return item;
      });
    },
    personsWithNoParents() {
      return this.pedigreeData.filter((item) => !item.rels.father && !item.rels.father);
    },
  },
  mounted() {
    const _this = this;
    // fetch('./tests/pedigree/8_Father_3-Children-GrandChildren.json')
    // fetch('./tests/pedigree/9-real-sample.json')
    // fetch('./tests/pedigree/7_Father_3-Children-GrandChild.json')
    // fetch('./tests/pedigree/7.json')
      // .then(response => response.json())
      // .then(data => { 
      //   _this.pedigreeDt = data
      //   setTimeout(()=>{
      //     _this.createPedigreeChart();
      //   }, 100)
      // })

    getSamplePedigree(this.cohortId, this.projectId, this.sampleInfo.id, (res) => {
      _this.pedigreeDt = res;
      setTimeout(()=>{
        _this.createPedigreeChart();
      }, 100)
    });
  },
  methods: {
    createPedigreeChart() {
      const draw = this.$svg().addTo("#pedigreechart");
      // draw.size('100%', '100%')

      // let i = 1;
      this.pedigreeDt.forEach(item => {
        const isProband = item.data.sample_name == this.sampleInfo.sample_name
        this.persons[item.id] = new Person(draw, item.id, item.data.sample_name, item.data.disease_status, item.data.gender, isProband, item.data.age)
      })

      this.pedigreeDt.forEach(item => {
        const  p = this.persons[item.id]
        p.setFather(this.persons[item.rels.father])
        p.setMother(this.persons[item.rels.mother])
        item.rels.children.forEach(childId => {
          p.addChild(this.persons[childId])
        })

        item.data.twins.forEach(twinId => {
          if( twinId != item.id){
            p.addTwin(this.persons[twinId])
          }
        })
        item.rels.spouses.forEach(spouseId => {
          p.addSpouse(this.persons[spouseId])
        })
        const relatives = item.data.relatives ? [item.data.relatives] : []
        relatives.filter(relId => item.father != relId && item.mother != relId && ( !item.hasChildren || !item.children.includes(relId)) ).forEach(relId => {
            p.addRelative(this.persons[relId])
        })
        // console.log(p)
      })

      if(this.pedigreeDt.length == 1){
        const p = this.persons[this.pedigreeDt[0].id]
        const dummyFatherId = uuidv4()
        const dummyMotherId = uuidv4()
        const dummyFather = new Person(draw, dummyFatherId, 'unknown', 'unknown', 'M', false, undefined)
        const dummyMother = new Person(draw, dummyMotherId, 'unknown', 'unknown', 'F', false, undefined)

        p.setFather(dummyFather)
        p.setMother(dummyMother)
        dummyFather.addSpouse(dummyMother)
        dummyMother.addSpouse(dummyFather)
        dummyFather.addChild(p)
        dummyMother.addChild(p)
        this.persons[dummyFatherId] = dummyFather
        this.persons[dummyMotherId] = dummyMother
      }
      //Adding missing Nodes
      Object.entries(this.persons).forEach( ent => {
        const p = ent[1]
        p.orderChildren()
        if( p.hasChildren && !p.hasSpouse){
          const id = uuidv4()
          let gender = 'unknown'
          if(p.gender == 'F'){
            gender == 'M'
          } else if (p.gender == 'M'){
            gender == 'F'
          }
          const newSpouse = new Person(draw, id, 'unknown', 'unknown', 'unknown', false, undefined)
          this.persons[id] = newSpouse
          p.addSpouse(newSpouse)
          p.children.forEach( child => {
            if(!child.hasFather){
              child.setFather(newSpouse)
            }
            if(!child.hasMother){
              child.setFather(newSpouse)
            }
          })
        }


      }
      )

      Object.entries(this.persons).forEach( ent => {
        const p = ent[1]
        if( !p.hasFather && !p.hasMother && !p.isLocated){
          p.setLocation(0,0)
        }
      }
      )

      // console.table(locations)


      for(let i=ROWS -1 ; i >= 0; i--){
        for(let j=0;  j< COLUMNS; j++){
          if(locations[i][j] != null){
            this.persons[locations[i][j]].reArrange()
          }
        }
      }

      // console.table(locations)

      Object.entries(this.persons).forEach( ent => {
        ent[1].draw()
      }
      )


      let maxI = 0;
      let maxJ = 0;
      for(let i=0 ; i < ROWS; i++){
        for(let j=0;  j< COLUMNS; j++){
          if(locations[i][j] != null){
            maxI =  Math.max(i, maxI)
            maxJ = Math.max(j, maxJ)
          }
        }
      }
      // console.table(locations)
      // console.log('min:',maxI ,'max:',maxJ )
      const coef1 = 1.3
      const coef2 = 1.1
      draw.size((maxJ+1) * Person.MATRIX_CELL_SIZE * coef1, (maxI+1) * Person.MATRIX_CELL_SIZE * coef1)
      draw.viewbox(0, 0, (maxJ+1) * Person.MATRIX_CELL_SIZE * coef2, (maxI+1) * Person.MATRIX_CELL_SIZE * coef2);
      draw.node.setAttribute('preserveAspectRatio', 'xMidYMid meet');
      

      // this.personsWithNoParents.forEach(person => drawFamily(person, 1,1))
      
      
      // drawFamily(this.personsWithNoParents[0], 1, 1);

      // Start drawing the main person (root of the family tree)
      // const mainPerson = this.pedigreeDt.find(p => p.main);
      // console.log(mainPerson)
      // if (mainPerson) {
      //   drawFamily(mainPerson, 400, 60); // Start drawing from the center of the SVG canvas
      // }
    },
    draw() {
      // var draw = window.SVG().addTo('#FamilyChart').size(100, 100)
      // draw.rect(30, 30).move(40, 50).fill('#f06')
      // // const draw = SVG().addTo('#drawing').size(100, 100);
      // draw.circle(50).fill('red').move(25, 25);
    },
  },
};
</script>
<style lang="scss" scoped>
svg {
            // width: 100%;
            // height: 300px;
 }
</style>