<template>
  <div class="boxFrame pa-10">
    <IndividualAnalysisSampleList :projectId="projectId" :cohortId="cohortId" @onSampleChanged="onSampleChanged"></IndividualAnalysisSampleList>

  </div>
</template>
<script>
import IndividualAnalysisSampleList from "@/components/IndividualAnalysis/IndividualAnalysisSampleList.vue";
// import LucidAllAnalysisPatientInfo from "@/components/LucidAllAnalysis/LucidAllAnalysisPatientInfo.vue";

export default {
  name:"LucidAllAnalysisSamples",
  components:{IndividualAnalysisSampleList},
  data:()=>({
    expand:null,
        projectId: null,
        cohortId: null,
        selectedSample: null,
    }),
    created(){
        this.projectId = Number(this.$route.params.id);
        this.cohortId = Number(this.$route.params.cohortid);
    },
    methods:{
      onSampleChanged(val){
        this.selectedSample = Object.assign({}, val)
      }
    }
}
</script>