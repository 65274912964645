<template>
  <div class="pb-0 mt-4">
    <v-row>
      <v-col cols="4" >
        <span class="grey--text text--darken-1">Status:</span> {{ dataset?.status?.name }}
        <v-btn class="ml-4" disabled outlined x-small color="primary" @click="onSave()"> Reset Status </v-btn>
      </v-col>
      <v-col cols="8">
        <span class="grey--text text--darken-1">Created:</span> {{ dataset?.createdAtAgo }} <span class="caption ml-1"> {{ dataset?.createdAt }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4">
        <span class="grey--text text--darken-1">Uploaded:</span> {{ dataset?.uploadedAtAgo }} <span class="caption ml-1">{{ dataset?.uploadedAt }}</span>
      </v-col>
      <v-col cols="8">
        <span class="grey--text text--darken-1">Imported:</span> {{ dataset?.importedAtAgo }} <span class="caption ml-1">{{ dataset?.importedAt }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4"> <span class="grey--text text--darken-1">Rows:</span> {{ dataset?.recordCount }} </v-col>
      <v-col cols="8"> <span class="grey--text text--darken-1">Columns:</span> {{ dataset?.columnCount }} </v-col>
    </v-row>
    <v-row>
      <v-col cols="4"> <span class="grey--text text--darken-1">File Size:</span> {{ dataset?.fileSize }} </v-col>
      <v-col cols="8"> </v-col>
    </v-row>

  </div>
</template>
<script>
import { getDataset, updateDatasetStatus } from "@/api/dataset";

import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
TimeAgo.addLocale(en);

const timeAgo = new TimeAgo("en-UK");

export default {
  name: "DatasetInfo",
  data() {
    return {
      datasetId: this.$route.params.datasetId,
      dsInfo: null,
    };
  },
  computed: {
    dataset() {
      if (this.dsInfo == null) {
        return null;
      }
      let ds = this.dsInfo;
      ds.createdAt = this.toStrDate(ds.createdAt);
      ds.uploadedAt = this.toStrDate(ds.uploadedAt);
      ds.importedAt = this.toStrDate(ds.importedAt);
      ds.createdAtAgo = this.agoFormat(ds.createdAt);
      ds.uploadedAtAgo = this.agoFormat(ds.uploadedAt);
      ds.importedAtAgo = this.agoFormat(ds.importedAt);

      if (ds.fileSize) {
        if (ds.fileSize > 1024 * 1024) {
          ds.fileSize = Math.round((ds.fileSize / 1024 / 1024) * 100) / 100 + " MB";
        } else if (ds.fileSize > 1024) {
          ds.fileSize = Math.round((ds.fileSize / 1024) * 10) / 10 + " KB";
        } else if (ds.fileSize !== null) {
          ds.fileSize = ds.fileSize + " Bytes";
        }
      }
      return ds;
    },
    datasetAnalysisStatus() {
      if (this.dataset.postprocessStatus === "POSTPROCESSED") {
        return "Analyzed";
      } else if (this.dataset.postprocessStatus === null) {
        return "NOT Analyzed";
      } else if (this.dataset.postprocessStatus === "POSTPROCESSING") {
        return "Analyzing";
      }
      return "Unknown!";
    },
  },
  methods: {
    loadDataset(){
      const _this = this;
      getDataset(this.datasetId, function (ds) {
        _this.dsInfo = ds;
      });
    },
    onSave() {
      const _this = this
      updateDatasetStatus(this.datasetId, { status: 10},  function (){
        _this.$root.notify.show({ message: "Dataset reset successfully" });
        _this.loadDataset()
      })
    },
    toStrDate(dt) {
      if (dt) {
        var d = new Date(dt);
        return d.toDateString() + "    " + d.toLocaleTimeString();
      } else {
        return "";
      }
    },
    agoFormat(dt) {
      if (dt) {
        var d = new Date(dt);
        return timeAgo.format(d);
      } else {
        return "";
      }
    },
  },
  created() {
    this.loadDataset()
  },
};
</script>
