<template>
  <div>
    <div v-if="sampleInfo " class="d-flex flex-column">
      <div ref="variantTabs" class="d-flex flex-row">
      </div>
      <v-btn :disabled="exploreResult.length == 0" small text color="primary" class="ml-auto mt-n4" @click="onExportResultClick"> <v-icon small class="mr-1">mdi-download-outline</v-icon>Export Result </v-btn>

      <div style="position: relative !important; background-color: #ffffff !important">
        <v-data-table
          ref="detailGrid"
          :loading="loading"
          loading-text="Loading the variants ..."
          disable-pagination
          :hide-default-footer="true"
          :headers="headers"
          :items="exploreResult"
          @click:row="clickRow"
          class="elevation-1 mb-8"
          :class="databaseType + '-Class'"
          :item-key="'ID'"
          :expanded.sync="expanded"
          :single-expand="true"
          single-select
          :value="selectedVariant"
          @current-items="onDataTableCurrentItemChanged"
          @update:sort-by="onDatatableSorted"
          multi-sort
        >
          <template v-for="h in headers" v-slot:[`header.${h.value}`]="{}">
            <span :key="'hed3-' + h">
              <span>{{ h.text }} </span>
              <Help section="VARIANT" :lookupKey="h.text"></Help>
            </span>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length" class="px-8 pb-8 pt-4">
              <div style="position: relative" :style="'width: ' + coolboxFrameWidth">
                <TadCoolbox
                  v-if="databaseType == 'SV'"
                  class="stickyCoolbox"
                  ref="tadCoolbox"
                  :isDialog="false"
                  type="SV"
                  :cohortId="cohortId"
                  :selectedDatabaseId="selectedDatabaseId"
                  :variantId="item['ID']"
                  :sv="[{ id: item['ID'], start: item['START'], end: item['END'], type: item['TYPE'], size: item['SIZE'], info: null }]"
                  :selectedUsersGenes="selectedUsersGenes"
                  :chromosome="{ id: item?.CHR || null, start: 0, end: 0 }"
                  :differentiallyExpressedGenes="item['DEGS'] || []"
                  rarePathogenicTrackHintCondition="( GNOMADG_AF ≤ 0.01, QUAL ≥ 30, GT ≠ 0/0 ) "
                  ></TadCoolbox>
                <TrSnvCoolbox
                v-if="databaseType == 'SNV'"
                  ref="tadCoolbox"
                  :type="databaseType"
                  :cohortId="cohortId"
                  :selectedDatabaseId="selectedDatabaseId"
                  :variantId="item['ID']"
                  :sv="[{ id: item['ID'], start: item['START'], end: item['END'], type: item['TYPE'], size: item['SIZE'], info: null }]"
                  :chromosome="{ id: item?.CHR || null, start: 0, end: 0 }"
                  :selectedUsersGenes="selectedUsersGenes"
                  :differentiallyExpressedGenes="item['DEGS'] || []"
                  rarePathogenicTrackHintCondition="( QUAL ≥ 30, GT ≠ 0/0 ) "
                ></TrSnvCoolbox>
                <TrSnvCoolbox
                v-if="databaseType == 'TR'"
                  ref="tadCoolbox"
                  :type="databaseType"
                  :cohortId="cohortId"
                  :selectedDatabaseId="selectedDatabaseId"
                  :variantId="item['ID']"
                  :sv="[{ id: item['ID'], start: item['START'], end: item['END'], type: item['TYPE'], size: item['SIZE'], repcn: item['REPCN'],ref_cn: item['REF_CN'] ,rep_unit: item['REP_UNIT'], info: null }]"
                  :chromosome="{ id: item?.CHR || null, start: 0, end: 0 }"
                  :selectedUsersGenes="selectedUsersGenes"
                  :differentiallyExpressedGenes="item['DEGS'] || []"
                  rarePathogenicTrackHintCondition="( QUAL ≥ 20, GT ≠ 0/0, FILTER=PASS, MAX_POP_DIV ≥ 2 ) "
                ></TrSnvCoolbox>                
              </div>
            </td>
          </template>
          <template v-slot:item.is_pathogenic="{ item }">
            <v-tooltip v-if="item.is_pathogenic == true" top>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on" small color="orange">mdi-alert-outline</v-icon>
              </template>
              <span>
                The variant is labeled as pathogenic in ClinVar.
              </span>
            </v-tooltip>
          </template>
          <template v-slot:item.LABEL="{ item }">
            <v-menu offset-y :rounded="false" transition="slide-y-transition" bottom right style="box-shadow: none !important">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-show="expanded.length > 0 && expanded[0] == item" class="mx-n1" small elevation="0" :color="variantLabelColors[item.__usr?.label?.id] || 'grey'" outlined v-bind="attrs" v-on="on" width="120px">
                  {{ truncate(item.__usr?.label?.title || "") }} <v-icon small class="ml-auto">mdi-chevron-down</v-icon>
                </v-btn>
                <div
                  v-show="!(expanded.length > 0 && expanded[0] == item)"
                  class="mx-n1 text-uppercase text-caption font-weight-medium"
                  small
                  elevation="0"
                  :style="'color:' + variantLabelColors[item.__usr?.label?.id] || 'grey'"
                  outlined
                  width="140px"
                >
                  {{ item.__usr?.label?.title || "" }}
                </div>
              </template>
              <v-list dense outlined>
                <v-list-item style="min-height: 35px !important" class="body-2" dense v-for="lbl in variantLabelsInfo" rounded :key="lbl.id" @click="onMarkTag(item, lbl)">
                  <v-icon class="mr-1" color="lbl.color" small v-if="(item.__usr?.label?.title || '') == lbl.title">mdi-check</v-icon>
                  <div v-else style="width: 20px"></div>
                  {{ lbl.title }}
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template v-slot:item.__GNOMAD_INFO="{ item }">
            <v-tooltip :open-delay="600" bottom color="primary  lighten-1">
              <template v-slot:activator="{ on, attrs }">
                <a
                  v-bind="attrs"
                  v-on="on"
                  onclick="event.stopPropagation()"
                  :href="item.__GNOMAD_INFO.href"
                  target="_black"
                >
                  <v-icon small class="mb-1" color="primary">mdi-open-in-new</v-icon>
                </a>
              </template>
              <span>{{ item.__GNOMAD_INFO.tooltip }}</span>
            </v-tooltip>
          </template>

          <template v-slot:item.__DECIPHER_INFO="{ item }">
            <v-tooltip :open-delay="600" bottom>
              <template v-slot:activator="{ on, attrs }">
                <a v-bind="attrs" v-on="on" onclick="event.stopPropagation()" :href="item.__DECIPHER_INFO.href" target="_black">
                  <v-icon small class="mb-1" color="primary">mdi-open-in-new</v-icon>
                </a>
              </template>
              <span>{{ item.__DECIPHER_INFO.tooltip }} </span>
            </v-tooltip>
          </template>

          <template v-slot:item.ORGANIZATION="{ item }">
            <LucidAllAnalysisOrganization v-if="item.__organization && item.__organization.length > 0" :organization="item.__organization" :variantLabelColors="variantLabelColors"></LucidAllAnalysisOrganization>
          </template>
          <template v-slot:item.NOTE="{ item }">
            <LucidAllAnalysisNote :variantId="item.ID" :cohortId="cohortId" :databaseId="selectedDatabaseId" :note="item.__usr?.note || null"></LucidAllAnalysisNote>
          </template>
          <template v-slot:item.OVERLAPPING_GENES="{ item }">
            <div class="d-flex flex-row">
              <GeneInfo class="mr-1 mb-1" v-for="gene in item.OVERLAPPING_GENES.slice(0, 3)" :key="gene" :id="gene"></GeneInfo>
              <v-menu v-if="item.OVERLAPPING_GENES.length > 3" right open-on-hover offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" color="grey" class="mb-1">mdi-dots-horizontal</v-icon>
                </template>
                <div class="pa-2 white d-flex flex-wrap" style="width: 280px">
                  <GeneInfo class="mr-1 mb-1" v-for="gene in item.OVERLAPPING_GENES" :key="gene" :id="gene"></GeneInfo>
                </div>
              </v-menu>
            </div>
          </template>
          <template v-slot:item.CLINVAR_LINK="{ item }">
            <a onclick="event.stopPropagation()" :href="'https://www.ncbi.nlm.nih.gov/clinvar/' + item[clinvarFieldName] + '/?redir=rcv'" target="_black">
              {{ item[clinvarFieldName] }}
            </a>
          </template>
          <template v-slot:item.OMIM_LINK="{ item }">
            <a onclick="event.stopPropagation()" :href="'https://www.omim.org/entry/' + item[omimFieldName] + '?search=' + item[omimFieldName] + '&highlight=' + item[omimFieldName]" target="_black">
              {{ item[omimFieldName] }}
            </a>
          </template>
          <template v-slot:item.SNP_LINK="{ item }">
            <a onclick="event.stopPropagation()" :href="'https://www.ncbi.nlm.nih.gov/snp/' + item[snpFieldName]" target="_black">
              {{ item[snpFieldName] }}
            </a>
          </template>
          <template v-slot:no-data> {{ emptyMessage }} </template>
        </v-data-table>
      </div>
    </div>
  </div>
</template>
<script>
import { getFieldGroups } from "@/api/settings";
import { getVariantLabels } from "@/api/settings";
import { updateVariantAnnotation, updateDatasetSampleStatus } from "@/api/user/dataset";
import { saveVariantAnalysisResultAnnotation, deleteVariantAnalysisResultAnnotation } from "@/api/user/dataset";
import { getTargets } from "@/api/user/dataset";
import { getDatasetFields } from "@/api/dataset";
import { getData } from "@/api/user/dataset";

import GeneInfo from "@/components/defaultAnalysis/GeneInfo.vue";
import TadCoolbox from "@/components/defaultAnalysis/tad/TadCoolbox.vue";
import TrSnvCoolbox from "@/components/defaultAnalysis/tad/TrSnvCoolbox.vue";
import LucidAllAnalysisNote from "@/components/LucidAllAnalysis/LucidAllAnalysisNote.vue";
import LucidAllAnalysisOrganization from "@/components/LucidAllAnalysis/LucidAllAnalysisOrganization.vue";
import exportToExcel from "@/mixins/exportToExcel.js";
import Help from "@/components/Common/Help.vue";

export default {
  name: "LucidAllAnalysisViewVariants",
  components: { GeneInfo, TadCoolbox, TrSnvCoolbox, LucidAllAnalysisNote, LucidAllAnalysisOrganization, Help },
  mixins: [exportToExcel],

  props: {
    selectedDatabaseId: {
      type: Number,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
    databaseType: {
      type: String,
      required: true,
    },
    targetType: {
      type: String,
      required: true,
    },
    sampleInfo:{
      type: Object,
      required: true,
    },
    removeDiscards: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: null,
    },
    inheritanceModes: {
      type: Array,
      required: true,
    },
    filters: {
      type: Object,
      defaul: () => {},
    },
    strictOmimMatch:{
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    sampleFieldName: null,
    expanded: [],
    loading: true,

    allDatasetFields: [],
    fieldGroups: [],
    datasetViewFields: [],
    datasetFields:[],
    clinvarFieldName: null,
    omimFieldName: null,
    snpFieldName: null,

    rawExploreResult: [],
    selectedItem: {},
    variantLabels: [],
    dataTableComputedItems: [],
    selectedVariantIndex: -1,
    variantLabelstoInclude: [1, 3, 4],

    selectedUsersGenes: [],
    // coolboxInfo: null,
    variantLabelColors: {
      1: "#00CC88", //KEEP
      2: "#FF0000", //DISCARD
      3: "#615D69", //DON'T KNOW
      4: "orange", //True with unclear significance
    },
    variantLabelsToKeep: [1, 3, 4],
  }),
  computed: {
    organizationName() {
      // console.log('store',this.$store.state)
      return this.$store.state.Organization.name;
    },
    emptyMessage() {
      if (this.removeDiscards) {
        const labels = this.variantLabels
          .filter((item) => this.variantLabelstoInclude.includes(item.id))
          .map((item) => item.title)
          .join(", ");
        return `No variant is marked as ${labels}`;
      } else {
        return "No variant has been found.";
      }
    },
    variantLabelsInfo() {
      return this.variantLabels.map((item) => {
        let x = item;
        x.color = this.variantLabelColors[item.id] || "grey";
        return x;
      });
    },
    coolboxFrameWidth() {
      return String(this.$refs.variantTabs.clientWidth * 0.96) + "px";
    },
    tableTitle() {
      return `${this.title} ${this.databaseType}s`;
    },
    dataTableHeight() {
      return 100 + Math.min(this.totalItems * 45, 220);
    },

    exploreResult() {
      // console.log( this.rawExploreResult)
      return this.rawExploreResult
        .filter((item) => this.variantLabelstoInclude.indexOf(item?.__usr?.label?.id || null) >= 0 || !this.removeDiscards)
        .map((item, index) => {
          let x = item;
          x["__row_number"] = index + 1;
          if (this.databaseType == "SV") {
            x["__DECIPHER_INFO"] = {
              href: "https://www.deciphergenomics.org/search/patients/results?q=" + item.CHR.substr(3) + "%3A" + item.START + "-" + item.END,
              tooltip: "View " + item.CHR + " from " + item.START + " to " + item.END + " on www.deciphergenomics.org",
            };
            x["__GNOMAD_INFO"] = {
              href: `https://gnomad.broadinstitute.org/region/${item['CHR'].substr(3)}-${item['START']}-${item['END']}?dataset=gnomad_sv_r4`,
              tooltip:  'View ' + item["CHR"] + "-" + item["START"] + "-" + item["END"] + ' on gnomad.broadinstitute.org'
            }
          }
          if(this.databaseType == 'SNV'){
            x["__GNOMAD_INFO"] = {
              href: 'https://gnomad.broadinstitute.org/variant/' + item['CHR'].substr(3) + '-' + item['START'] + '-' + item['REF'] + '-' + item['ALT'] + '?dataset=gnomad_r4' ,
              tooltip:  'View ' + item["CHR"] + "-" + item["START"] + "-" + item["REF"] + "-" + item["ALT"] + ' on gnomad.broadinstitute.org'
            }
          }
          return x;
        });
    },
    totalItems() {
      return this.exploreResult.length;
    },
    headers() {
      if (this.exploreResult.length == 0) {
        return [];
      }

      let toExclude = ["__usr", "__row_number", "ID", "__organization", "__DECIPHER_INFO", "is_pathogenic", ""];
      // console.log('NOT_FOUND', Object.keys(this.exploreResult[0]).filter(f =>  this.datasetViewFields.findIndex((item) => item.title == f ) < 0))
      if (this.datasetViewFields.findIndex((item) => item.title == "DEGS") == -1) {
        toExclude.push("DEGS");
      }
      // console.log(toExclude,  Object.keys(this.exploreResult[0]))
      // console.log(this.exploreResult[0])
      const headers = Object.keys(this.exploreResult[0])
        .filter((h) => toExclude.indexOf(h) == -1)
        .filter(h => this.datasetViewFields.findIndex((item) => item.title == h ) >= 0)
        .sort((a, b) => this.datasetFields.find((f) => f.title === a).order - this.datasetFields.find((f) => f.title === b).order)
        .map((hd) => {
          const sortable = this.datasetFields.find((f) => f.title === hd).groups.findIndex((x) => x.groupType === "SORTABLE") > -1;
          let actionColumn = hd;
          if (this.datasetFields.find((f) => f.title === hd).groups.findIndex((x) => x.groupType === "CLINVAR_LINK") > -1) {
            actionColumn = "CLINVAR_LINK";
          } else if (this.datasetFields.find((f) => f.title === hd).groups.findIndex((x) => x.groupType === "OMIM_LINK") > -1) {
            actionColumn = "OMIM_LINK";
          } else if (this.datasetFields.find((f) => f.title === hd).groups.findIndex((x) => x.groupType === "SNP_LINK") > -1) {
            actionColumn = "SNP_LINK";
          }
          return {
            text: hd,
            value: actionColumn,
            class: "primary white--text font-weight-meduim text-center text-no-wrap" + (sortable ? " text-decoration-underline" : ""),
            sortable: sortable,
            // width: String(Math.max(80, hd.split(" ").length * 40)) + "px",
            cellClass: "variantCell",
            align: "center",
          };
        });
      headers.push({ text: "", sortable: false, value: "data-table-expand", class: "primary white--text font-weight-medium rounded-r", cellClass: "variantCell", width: "40px" });
      if (this.databaseType == "SNV") {
        headers.unshift({ text: "", value: "is_pathogenic", divider: false, class: "primary white--text mx-0", cellClass: "variantCell mx-0 px-0", sortable: false, width: "20px", align: "center" });
      }
      headers.unshift({ text: this.organizationName, value: "ORGANIZATION", divider: true, class: "primary white--text ", cellClass: "variantCell", sortable: false, width: "20px", align: "center" });
      headers.unshift({ text: "Note", value: "NOTE", class: "primary white--text ", cellClass: "variantCell", sortable: false, width: "20px", align: "center" });
      headers.unshift({ text: "Label", value: "LABEL", class: "primary white--text ", cellClass: "variantCell", sortable: false, width: "145px", align: "center" });
      headers.unshift({ text: "#", value: "__row_number", class: "primary white--text rounded-l ", cellClass: "variantCell", sortable: false, width: "20px", align: "center" });

      const hasGnomad = ["ALT", "CHR", "START", "REF"].every((v) => headers.map((h) => h.text).includes(v));
      if (hasGnomad || this.databaseType == "SV") {
        headers.splice(5, 0, { text: "GnomAD", value: "__GNOMAD_INFO", class: "primary white--text ", cellClass: "variantCell", sortable: false, width: "20px", align: "center" });
      }

      if (this.databaseType == "SV") {
        const isDecipherEligible = ["START", "END", "CHR"].every((v) => headers.findIndex((item) => item.text == v) >= 0);
        if (isDecipherEligible) {
          const idx = headers.findIndex((item) => item.text == "END");
          headers.splice(idx + 1, 0, { text: "Decipher", value: "__DECIPHER_INFO", class: "primary white--text ", cellClass: "variantCell", sortable: false, width: "20px", align: "center" });
        }
      }
      return headers;
    },
    selectedVariant() {
      if (this.selectedVariantIndex >= 0) {
        return [this.dataTableComputedItems[this.selectedVariantIndex]];
      } else {
        return [];
      }
    },
  },
  created() {
    const _this = this;
    
    getDatasetFields(this.selectedDatabaseId, function (res) {
      _this.allDatasetFields = res;
      _this.sampleFieldName = res.find((ds) => ds.title == "SAMPLE").name;
      getVariantLabels((res) => {
        _this.variantLabels = res;
      });
      getFieldGroups(function (res) {
        _this.fieldGroups = res;
        _this.loadDatasetFields();
      });
    });
  },

  methods: {
    onExportResultClick() {
      this.exportToExcel(this.sampleInfo.sample_name + "-" + this.tableTitle, [{ name: this.tableTitle, data: this.exploreResult }]);
    },
    onDatatableSorted() {
      this.selectedVariantIndex = -1;
      this.expanded = [];
    },
    onDataTableCurrentItemChanged(items) {
      this.dataTableComputedItems = items;
    },
    truncate(text) {
      if (text && text.length > 11) {
        return text.substr(0, 11) + "…";
      }
      return text;
    },
    // clickRow(item, event) {
    clickRow(item, row) {
      this.selectedVariantIndex = row.index;
      row.select(true);
      this.expanded = [];
      if (!row.isExpanded) {
        this.$nextTick(() => {
          this.expanded = [item];
        });
      }
      // console.log('ITEM',item, 'ROW', row.index)
    },
    selectNextVariant() {
      if (this.selectedVariantIndex < this.dataTableComputedItems.length - 1) {
        this.selectedVariantIndex++;

        this.expanded = [];
        this.$nextTick(() => {
          this.expanded = [this.dataTableComputedItems[this.selectedVariantIndex]];
        });

        setTimeout(() => {
          const selectedRows = document.getElementsByClassName("v-data-table__selected");
          for (let i = 0; i < selectedRows.length; i++) {
            const classList = Array.from(selectedRows[i].parentNode.parentNode.parentNode.parentNode.classList);
            if (classList.indexOf(this.databaseType + "-Class") > 0) {
              const row = selectedRows[i];
              row.scrollIntoView({ behavior: "smooth" });
              break;
            }
          }
        }, 150);
      } else if (this.selectedVariantIndex == this.dataTableComputedItems.length - 1) {
        this.expanded = [];
      }
    },

    onMarkTag(variant, label) {
      const annotation = {
        labelId: label.id,
      };
      const _this = this;
      updateVariantAnnotation(
        this.cohortId,
        this.selectedDatabaseId,
        variant.ID,
        annotation,
        () => {
          const i = _this.rawExploreResult.findIndex((item) => item.ID == variant.ID);
          if (_this.rawExploreResult[i].__usr) {
            _this.rawExploreResult[i].__usr["label"] = label;
          } else {
            _this.$set(_this.rawExploreResult[i], "__usr", { label: label });
          }
          _this.saveVariantAnalysisResult(variant.ID, label);
          updateDatasetSampleStatus(_this.cohortId, _this.selectedDatabaseId, _this.sampleInfo.sample_name, "IN_PROGRESS");
        },
        "Variant marked as " + label.title
      );
    },
    saveVariantAnalysisResult(variantId, label) {
      const _this = this;
      if (this.variantLabelsToKeep.indexOf(label.id) >= 0) {
        const payload = {
          tadFile: this.$refs.tadCoolbox.getImage(),
          varaintInfo: {
            LABEL: label.title,
          },
          sampleId: this.sampleInfo.sample_name,
        };
        saveVariantAnalysisResultAnnotation(this.cohortId, this.selectedDatabaseId, variantId, payload, () => {
          _this.selectNextVariant();
        });
      } else {
        deleteVariantAnalysisResultAnnotation(this.cohortId, this.selectedDatabaseId, variantId, () => {
          _this.selectNextVariant();
        });
      }
    },

    loadDatasetFields() {
      const _this = this;
      getDatasetFields(this.selectedDatabaseId, (rs) => {
        _this.datasetFields = rs;
        _this.datasetViewFields = rs.filter((f) => f.groups.findIndex((g) => g.groupType === "VIEW") > -1);
        _this.clinvarFieldName = _this.datasetViewFields.find((x) => x.groups.findIndex((x) => x.groupType === "CLINVAR_LINK") > -1)?.title || null;
        _this.omimFieldName = _this.datasetViewFields.find((x) => x.groups.findIndex((x) => x.groupType === "OMIM_LINK") > -1)?.title || null;
        _this.snpFieldName = _this.datasetViewFields.find((x) => x.groups.findIndex((x) => x.groupType === "SNP_LINK") > -1)?.title || null;
        _this.loadData();
      });
      // getDatasetFieldsByOneOfGroups(this.selectedDatabaseId, groups, function (rs) {
      // });
    },
    loadData() {
      const _this = this;
      const columnGroups = this.fieldGroups.filter((g) => g.groupType === "VIEW");
      const selectedColumnGroups = Object.fromEntries(columnGroups.map((item) => [item.id, true]));
      const groups = Object.entries(selectedColumnGroups)
        .filter((item) => item[1] == true)
        .map((x) => Number(x[0]));
      if (groups.length == 0) {
        _this.$root.notify.show({ message: "At least one column group should be selected!", type: "warning" });
        return;
      }
      this.selectedUsersGenes = [];

      if (this.targetType == "ALL") {
        let sampleFilter = {};
        sampleFilter[this.sampleFieldName] = this.sampleInfo.sample_name;
        let allExactFilters = [sampleFilter];
        getData(
          this.cohortId,
          this.selectedDatabaseId,
          allExactFilters,
          [], //rangeFilter
          [], //inFilters
          [],
          groups,
          "one_of_all",
          200,
          0,
          // itemsPerPage,
          // pageNumber,
          [],
          this.variantLabelstoInclude,
          [],
          [],
          [],
          function (res) {
            _this.rawExploreResult = res.result;
            _this.loading = false;
          }
        );
      } else {
        getTargets(this.cohortId, this.selectedDatabaseId, this.sampleInfo.sample_name, this.targetType, this.inheritanceModes,this.strictOmimMatch, this.filters, function (res) {
          _this.rawExploreResult = res;
          _this.loading = false;
        });
      }
    },
  },
};
</script>
<style scoped  lang="scss">
::v-deep tr.v-data-table__selected {
  background: var(--v-accent-base) !important;
  border-bottom: 0px !important;
}
.v-data-table::v-deep .v-data-table__expanded__content {
  box-shadow: none !important;
}
.theme--light.v-data-table::v-deep {
  background-color: transparent;
}
.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr > td.variantCell {
  height: 48px !important;
  // border-spacing: 10px 10px !important;
  // border-bottom: 1px solid var(--v-primary-base) !important;
  border-bottom: 1px solid #0393a850 !important;
}

.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr.v-data-table__expanded__row > td.variantCell {
  border-bottom: 0px solid red !important;
}

.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr:not(.v-data-table__expanded__content):hover {
  cursor: pointer;
}
.stickyCoolbox {
  position: -webkit-sticky !important; /* Safari */
  position: sticky !important;
  left: 0 !important;
  // z-index: 10 !important;
  background-color: bule !important;
}
</style>