<template>
  <div class="pa-10">
    <div class="text-h4 font-weight-bold ">
      <v-icon x-large class="mb-2" color="primary">mdi-account-group-outline</v-icon>
      {{ this.projectInfo?.name }}
    </div>
    <div class="ml-14 body-2 grey--text text--darken-1">
        <span >Disease:</span> <span class="font-weight-medium"> {{ this.projectInfo?.disease?.name }} </span>
        <span class="ml-4 "> Technology:</span> <span class="font-weight-medium">{{this.projectInfo?.technology?.name}}</span> 
    </div>

    <div class="d-flex flex-row mt-10 ml-n2">
      <v-card width="200" flat >
        <v-navigation-drawer permanent>
          <v-list nav >
            <v-list-item-group v-model="selectedItem" color="primary">
              <v-list-item v-for="(item, i) in menuItems" :key="i" :to="item.link" dense>
                <v-list-item-icon class="mr-4">
                  <v-icon v-text="item.icon" ></v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-h6 " v-text="item.text"></v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-card>
      <div class="flex-grow-1">
          <router-view :key='$route.fullPath'></router-view>

      </div>
    </div>

  </div>
</template>
<script>
import { getProject } from "@/api/project.js";
import { getDatasetsByProject } from "@/api/dataset.js";

export default {
  name: "ProjectInfo",
  data: () => ({
    projectId: null,
    projectInfo: null,
    selectedItem: 0,
    menuItems: [
    ],
  }),
  created() {
    this.projectId = this.$route.params.id;
    const _this = this;
    getProject(this.projectId, (res) => {
      _this.projectInfo = res;
    });

    getDatasetsByProject(this.projectId, function (res) {
      _this.menuItems.push({ text: "Samples", icon: "mdi-chart-timeline" ,link:{name:'ProjectSamples' , params:{id: _this.projectId}}})
      res.sort((a, b) => a.datasetType.id - b.datasetType.id)
      res.forEach( ds => 
        {
            _this.menuItems.push({ text: ds.datasetType.name , icon: "mdi-dna" ,link:{name:'DatasetInfo' , params:{id: _this.projectId, datasetId: ds.id}}})
        }
      )
    });
    





  },
};
</script>