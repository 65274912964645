<template>
  <div ref="variantTabs" class="frame d-flex flex-column">
    <div v-if="!!sharedItem" class="text-h5 font-weight-medium mb-8">
      <v-icon class="mb-1 mr-1" color="primary">mdi-share-variant-outline</v-icon> Analysis Request on <b>{{ sharedItem.sample.name }}</b> from <i> {{ sharedItem.user.first_name }} {{ sharedItem.user.last_name }} </i>
    </div>
    <v-sheet v-if="isDataLoaded" rounded="" color="white" class="d-flex flex-row pa-3 mb-3 flex-wrap sharedItem">
      <v-tabs  v-model="tab" color="primary" grow>
        <v-tab class="rounded-t-lg" active-class="rounded-t-lg grey lighten-3">SNVs</v-tab>
        <v-tab class="rounded-t-lg" active-class="rounded-t-lg grey lighten-3">SVs</v-tab>
        <v-tab class="rounded-t-lg" v-if="datasets.TR.filters" active-class="rounded-t-lg grey lighten-3">TRs</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" style="background-color: #f0f0f0">
        <v-tab-item eager transition="slide-y-transition" ref="commonTabItems">
          <ViewVariantsTable
            :total="datasets.SNV.total"
            @onOptionsChanged="onOptionsChanged"
            :parentWidth="width"
            :loading="datasets.SNV.loading"
            :rawExploreResult="datasets.SNV.result"
            :fields="datasets.SNV.fields"
            :selectedDatabaseId="sharedItem.snv_filters.datasetId"
            :selectedUsersGenes="sharedItem.snv_filters.userSelectedGenes"
            databaseType="SNV"
            :cohortId="sharedItem.snv_filters.cohortId"
          >
          </ViewVariantsTable>
        </v-tab-item>
        <v-tab-item eager transition="slide-y-transition" ref="commonTabItems">
          <ViewVariantsTable
            :total="datasets.SV.total"
            @onOptionsChanged="onOptionsChanged"
            :parentWidth="width"
            :loading="datasets.SV.loading"
            :rawExploreResult="datasets.SV.result"
            :fields="datasets.SV.fields"
            :selectedDatabaseId="sharedItem.sv_filters.datasetId"
            :selectedUsersGenes="sharedItem.sv_filters.userSelectedGenes"
            databaseType="SV"
            :cohortId="sharedItem.sv_filters.cohortId"
          >
          </ViewVariantsTable>
        </v-tab-item>
        <v-tab-item eager v-if="datasets.TR.filters" transition="slide-y-transition" ref="commonTabItems">
          <ViewVariantsTable
            :total="datasets.TR.total"
            @onOptionsChanged="onOptionsChanged"
            :parentWidth="width"
            :loading="datasets.TR.loading"
            :rawExploreResult="datasets.TR.result"
            :fields="datasets.TR.fields"
            :selectedDatabaseId="sharedItem.tr_filters.datasetId"
            :selectedUsersGenes="sharedItem.tr_filters.userSelectedGenes"
            databaseType="TR"
            :cohortId="sharedItem.sv_filters.cohortId"
          >
          </ViewVariantsTable>
        </v-tab-item>
      </v-tabs-items>
    </v-sheet>
  </div>
</template>
  <script>
import { getDatasetsByProject } from "@/api/dataset.js";
import { getData } from "@/api/user/dataset";
import { getDatasetFields } from "@/api/dataset";
import { getSharedWithMeItem } from "@/api/user/collab.js";
import TimeAgo from "javascript-time-ago";
// English.
import ViewVariantsTable from "@/components/ViewVariantsTable.vue";

import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);
export default {
  name: "SharedWithMeItem",
  components:{ViewVariantsTable},
  data: () => ({
    tab: 0,
    sharedItemId: null,
    sharedItem: null,
    width: 0,
    isDataLoaded: false,
    datasets: {
      SNV: {
        info: null,
        result: [],
        fields: [],
        loading: true,
        filters: null,
        total: 0,
      },
      SV: {
        info: null,
        result: [],
        fields: [],
        loading: true,
        filters: null,
        total: 0,
      },
      TR: {
        info: null,
        result: [],
        fields: [],
        loading: true,
        filters: null,
        total: 0,
      },
    },
  }),
  created() {
    this.sharedItemId = this.$route.params.id;
    const _this = this;
    getSharedWithMeItem(this.sharedItemId, (res) => {
      const timeAgo = new TimeAgo("en-US");
      _this.sharedItem = res;
      _this.sharedItem.requested_at_hr = timeAgo.format(new Date(res.requested_at)); //.toLocaleString();
      _this.datasets.SNV.filters = res.snv_filters;
      _this.datasets.SV.filters = res.sv_filters;
      _this.datasets.TR.filters = res.tr_filters;
      _this.$nextTick(() => {
        _this.width = _this.$refs.variantTabs.clientWidth;
        console.log('_this.width',_this.width)
      });

      getDatasetsByProject(_this.sharedItem.sample.id, (res) => {
        Object.keys(_this.datasets).forEach((dsType) => {
          _this.datasets[dsType].info = res.find((item) => item.datasetType.name == dsType);
            getDatasetFields(_this.datasets[dsType].info.id, (rs) => {
              _this.datasets[dsType].fields = rs;
              _this.isDataLoaded = true;
              _this.loadData(dsType);
            });
        });
      });
    });
  },
  methods: {
    onOptionsChanged(options, dsType) {
      var sort = options.sortBy.map((item, index) => {
        let o = {};
        o[this.datasets[dsType].fields.find((x) => x.title == item).name] = { order: options.sortDesc[index] ? "desc" : "asc" };
        return o;
      });
      this.loadData(dsType, options.page, options.itemsPerPage, sort);
    },
    loadData(dsType, page = 1, itemsPerPage = 10, sort = []) {
      const _this = this;
      if(!_this.datasets[dsType].filters){
        return
      }
      let collabLabels = this.datasets[dsType].filters.collaborators_labels;
    //   this.datasets[dsType].filters.variantAnnotationLabels.forEach((lbl) => {
    //     collabLabels.push({ user_id: _this.sharedItem.user.id, label_id: lbl });
    //   });

      let inFilters = [...this.datasets[dsType].filters.inFilters]
      if(this.datasets[dsType].filters.variantAnnotationLabels.length > 0){
          const ownerlabelFilters = {}
          ownerlabelFilters[`__usr_${_this.sharedItem.user.id}.label.id`] =this.datasets[dsType].filters.variantAnnotationLabels 
          inFilters.push(ownerlabelFilters);
      }

      getData(
        this.datasets[dsType].filters.cohortId,
        this.datasets[dsType].filters.datasetId,
        this.datasets[dsType].filters.exactFilters,
        this.datasets[dsType].filters.rangeFilters,
        inFilters,//this.datasets[dsType].filters.inFilters,
        this.datasets[dsType].filters?.notFilters || [],
        this.datasets[dsType].filters.groups,
        this.datasets[dsType].filters.column_group_membership,
        itemsPerPage,
        page - 1,
        sort.length > 0 ? sort : this.datasets[dsType].filters.sort,
        [], //variant labels
        this.datasets[dsType].filters.variantLabelstoExclude, //varaint labels to exclude
        this.datasets[dsType].filters.columnTitles,
        collabLabels,
        function (res) {
          _this.$set(_this.datasets[dsType], "result", res.result);
          _this.$set(_this.datasets[dsType], "total", res.total);
          _this.$set(_this.datasets[dsType], "loading", false);
        }
      );
    },
  },
};
</script>
  <style scoped lang="scss">
.frame {
  border-radius: 16px;
  border: 1px solid #95d9e3;
  // border-image-source: linear-gradient(96.36deg, #9ADBE4 -1.08%, #0393A8 92.31%);
  padding: 30px;
  background: linear-gradient(90deg, rgba(3, 146, 168, 0.09) -59.56%, rgba(3, 146, 168, 0.025) 74.32%);
  border-width: 1px, 1px, 1px, 0px;
  border-style: solid;
  // background: linear-gradient(96.36deg, #9ADBE4 -1.08%, #0393A8 92.31%);
}
.sharedItem {
  box-shadow: 0px 4px 16px rgba(217, 217, 217, 0.5);
}
</style>