<template>
  <div ref="container" class="pt-2 pl-2 d-flex flex-column" style="width: 100%; position: relative">
    <div class="d-flex flex-row pr-3" style="position: absolute; top: 4; right: 0; z-index: 1">
      <CardMenu title="Show/Hide Details" v-model="showHideLayers" tooltip="Show/Hide details in the regulome" width="350px" customClass="mx-0 px-0">
        <template v-slot:button>
          <v-btn icon color="primary"> <v-icon class="mx-0 px-0">mdi-table-headers-eye</v-icon></v-btn>
        </template>
        <template v-slot:body>
          <div>Click on each section to show/hide it</div>
          <v-list>
            <v-list-item dense link v-for="item in Object.entries(regolumeDetailsVisibility)" :key="item[0]" @click="onRegolumeDetailsVisibilityChanged(item[0])" class="d-flex flex-row">
              <div>
                {{ item[1].name }}
              </div>
              <v-icon class="ml-auto" :color="item[1].show ? 'primary' : 'grey'">{{ item[1].show ? "mdi-eye-outline" : "mdi-eye-off-outline" }} </v-icon>
            </v-list-item>
          </v-list>
        </template>
      </CardMenu>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on" icon @click="onResetZoom()"> <v-icon>mdi-magnify-scan</v-icon></v-btn>
        </template>
        <span>Reset Zoom</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="primary" v-bind="attrs" v-on="on" @click="onExportAsPng"> <v-icon>mdi-download</v-icon></v-btn>
        </template>
        <span>Download as PNG</span>
      </v-tooltip>
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" v-bind="attrs" v-on="on">mdi-help</v-icon>
        </template>
        <span>Hold Ctrl to enable Sticky-Tooltip mode. It helps you to:</span>
        <ul>
          <li>See multiple tooltips at the same time</li>
          <li>Drag and move tooltips</li>
        </ul>
      </v-tooltip>
    </div>
    <v-stage ref="stage" :config="stageSize" @mousedown="onZoomStarted" @mouseup="onZoomEnded" @mousemove="onZoomSelecting">
      <v-layer>
        <v-rect :config="backgroundRectConfig"></v-rect>
      </v-layer>
      <v-layer>
        <Chromosome :spec="chromosomeInfo" :baseLineY="0"></Chromosome>
        <template v-if="regolumeDetailsVisibility.TADS.show">
          <Tad :tadInfo="tadInfo" v-for="tad in tads" :key="tad.id" :start="tad.start" :name="tad.name" :end="tad.end" :color="tadColor" :baseLineY="baseLineY" />
        </template>
        <Axis :tadInfo="tadInfo" :baseLineY="axisBaseline"></Axis>
        <GeneTrack
          :showEnhancers="regolumeDetailsVisibility.ENHANCERS.show"
          :showConnections="regolumeDetailsVisibility.CONNECTIONS.show"
          :legendItems="legendItems"
          :svHighlightInfo="svHighlightInfo"
          :tadInfo="tadInfo"
          :baseLineY="geneTrackBaseline"
          :genes="allGenes"
          :elements="allREs"
          :connections="allConnections"
          @onGeneTrackHeight="onGeneTrackHeight"
        ></GeneTrack>
        <!-- <MutationTrack  trackName="Structural Variant" :tadInfo="tadInfo" v-if="mutations.length > 0" :baseLineY="baseLineY + geneTrackHeight" :mutations="mutations" v-on:onMutationMouseOver="onMutationMouseOver" v-on:onMutationMouseOut="onMutationMouseOut" @onSvBar="onSvBar"></MutationTrack> -->
        <RarePathogenicSVsTrack
          :enableHightlighSameSamples="true"
          :hidden="!regolumeDetailsVisibility.COHORT_SVS.show"
          key="SvTrack"
          title="SVs"
          :svHighlightInfo="svHighlightInfo"
          :tadInfo="tadInfo"
          :baseLineY="sVsTrackBaseLine"
          :svs="svs"
          @onRarePathogenicSVsTrackHeight="onRareSVsTrackHeight"
        ></RarePathogenicSVsTrack>
        <RarePathogenicSVsTrack
          :enableHightlighSameSamples="true"
          :hidden="!regolumeDetailsVisibility.COHORT_SNVS.show"
          key="SnvTrack"
          title="SNVs"
          :showLegend="false"
          :svHighlightInfo="svHighlightInfo"
          :tadInfo="tadInfo"
          :baseLineY="sNVsTrackBaseLine"
          :svs="snvs"
          @onRarePathogenicSVsTrackHeight="onRareSNVsTrackHeight"
        ></RarePathogenicSVsTrack>
        <RarePathogenicSVsTrack
          :enableHightlighSameSamples="true"
          :hidden="!regolumeDetailsVisibility.COHORT_TRS.show"
          key="TrTrack"
          title="TRs"
          :showLegend="false"
          :svHighlightInfo="svHighlightInfo"
          :tadInfo="tadInfo"
          :baseLineY="trsTrackBaseLine"
          :svs="trs"
          @onRarePathogenicSVsTrackHeight="onRareTRsTrackHeight"
        ></RarePathogenicSVsTrack>       



        <FishboneTrack
          :hidden="!regolumeDetailsVisibility.ENSEMBLE_TRANSCRIPTS.show"
          ref="FishboneTrack"
          :svHighlightInfo="svHighlightInfo"
          :tadInfo="tadInfo"
          :baseLineY="fishboneTrackBaseline"
          :isoforms="canonicalIsoforms"
          @onFishboneTrackHeight="onFishboneTrackHeight"
        ></FishboneTrack>

        <template v-if="regolumeDetailsVisibility.EPIGENETIC_INFORMATION.show">
          <TrackSeparator :tadInfo="tadInfo" :y="dynamicTrackBaseLine - 2"></TrackSeparator>
          <v-text :config="dynamicTrackTitleConfig"></v-text>

          <DynamicTrack :svHighlightInfo="svHighlightInfo" :tadInfo="tadInfo" v-for="(track, index) in dynamicTracks" :key="track.name" :baseLineY="dynamicTrackBaseLine + index * 51" :trackInfo="track"></DynamicTrack>
        </template>
      </v-layer>

      <v-layer>
        <v-rect :config="zoomAreaRectConfig"></v-rect>
      </v-layer>
    </v-stage>
  </div>
</template>
<script>
// const height = window.innerHeight*0.85;
// const height = 900;
import Tad from "@/components/defaultAnalysis/tad/Tad.vue";
import Chromosome from "@/components/defaultAnalysis/tad/Chromosome.vue";
import Axis from "@/components/defaultAnalysis/tad/Axis.vue";
import GeneTrack from "@/components/defaultAnalysis/tad/GeneTrack.vue";
// import MutationTrack from "@/components/defaultAnalysis/tad/MutationTrack.vue";
import DynamicTrack from "@/components/defaultAnalysis/tad/DynamicTrack.vue";
import FishboneTrack from "@/components/defaultAnalysis/tad/Transcripts/FishboneTrack.vue";
import RarePathogenicSVsTrack from "@/components/defaultAnalysis/tad/RarePathogenicSVsTrack.vue";
import TrackSeparator from "@/components/defaultAnalysis/tad/TrackSeparator.vue";
import CardMenu from "@/components/Common/CardMenu.vue";

import { toScatedX } from "@/utils/tad.js";

export default {
  name: "CohortTadChart",
  components: { Tad, Axis, GeneTrack, Chromosome, DynamicTrack, FishboneTrack, RarePathogenicSVsTrack, TrackSeparator, CardMenu },
  //   components: { Tad, Axis, GeneTrack, MutationTrack, Chromosome, DynamicTrack, FishboneTrack, RarePathogenicSVsTrack,TrackSeparator },
  // components: { Tad , Axis, GeneTrack, MutationTrack, DynamicTrack},
  props: {
    tads: {
      type: Array(),
      default: () => [],
    },
    chromosome: null,
    geneEnhancers: null,
    dynamicTracks: {
      type: Array(),
      default: () => [],
    },
    isoforms: {
      type: Array(),
      default: () => [],
    },
    svs: {
      type: Array,
      default: () => [],
    },
    snvs: {
      type: Array,
      default: () => [],
    },
    trs: {
      type: Array,
      default: () => [],
    },    
    name: {
      type: String,
      // default: 'chr17-44524913-DEL-176'
      default: null,
      // required: true,
    },
  },

  data() {
    return {
      legendItems: [
        {
          text: "Selected Gene",
          // fillColor: null,
          fillColor: null,
          borderColor: "green",
          borderStyle: "dashed",
          type: "line",
        },
        {
          text: "Associated with an Enhancer",
          // fillColor: null,
          fillColor: null,
          borderColor: "grey",
          borderStyle: "solid",
          type: "line",
        },
        {
          text: "Hit by SV",
          // fillColor: null,
          fillColor: "#ff0000",
          borderColor: "black",
          borderStyle: "solid",
          type: "line",
        },
        {
          text: "Differentially Expressed (q-value < 0.05)",
          // fillColor: null,
          fillColor: null,
          borderColor: "blue",
          borderStyle: "solid",
          type: "hatched",
        },
        {
          text: "HPO-Associated",
          fillColor: "#ff7575", //#10
          borderColor: null,
          borderStyle: null,
          type: "square",
        },
        {
          text: "Provided in Background",
          fillColor: "#e60049", //#2
          borderColor: null,
          borderStyle: null,
          type: "square",
        },
        {
          text: "Others",
          fillColor: "#b3d4ff", //#0
          borderColor: null,
          borderStyle: null,
          type: "square",
        },
      ],
      showHideLayers: false,
      regolumeDetailsVisibility: {
        TADS: { name: "Tads", show: true },
        ENHANCERS: { name: "Enhancers", show: true },
        CONNECTIONS: { name: "Gene-Enhancer Connections", show: true },
        COHORT_SVS: { name: "Cohort SVs", show: true },
        COHORT_SNVS: { name: "Cohort SNVs", show: true },
        COHORT_TRS: { name: "Cohort TRs", show: true },
        ENSEMBLE_TRANSCRIPTS: { name: "Ensemble Transcripts", show: true },
        EPIGENETIC_INFORMATION: { name: "Epigenetic Information", show: true },
      },
      width: 0,
      mutationHighlightShow: false,
      geneTrackHeight: 40,
      fishboneTrackHeight: 50,
      sVsTrackHeight: 25,
      sNVsTrackHeight: 0,
      trsTrackHeight: 0,
      dynamicTrackTitleConfigHeight: 30,

      //COLORS from: https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
      colors: ["#b3d4ff", "#d0d0d0", "#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#00bfa0", "#ff7575"],
      tadColor: "#ff4d4d",
      svHighlightInfo: {
        start: 0,
        width: 0,
      },
      isZooming: false,
      zoomAreaRect: {
        end: 0,
        start: 0,
      },
      currentMinMax: null,
    };
  },
  mounted() {
    this.width = this.$refs.container.clientWidth * 0.97;
    this.$emit("onMinMax", this.minMax);
    const pref = this.$store.state.UserPreferences;
    if (pref && pref.cohortTadChartSetting) {
      Object.keys(this.regolumeDetailsVisibility).forEach( key => {
        this.regolumeDetailsVisibility[key].show = pref.cohortTadChartSetting[key]?.show || true;
      }
      )
      // this.regolumeDetailsVisibility = pref.cohortTadChartSetting;
      // if(this.regolumeDetailsVisibility.COHORT_TRS == undefined){
      //   this.regolumeDetailsVisibility.COHORT_TRS = {}
      //   this.regolumeDetailsVisibility.COHORT_TRS.name = "Cohort TRs"
      //   this.regolumeDetailsVisibility.COHORT_TRS.show = true
      //     // Object.assign({}, { name: "Cohort TRs", show: true })

      // }
    }
  },

  methods: {
    onRegolumeDetailsVisibilityChanged(item) {
      // console.log(item, this.regolumeDetailsVisibility[item])
      this.$set(this.regolumeDetailsVisibility[item], "show", !this.regolumeDetailsVisibility[item].show);
      let prf = this.$store.state.UserPreferences;
      if (!prf) {
        prf = {};
      }
      prf.cohortTadChartSetting = this.regolumeDetailsVisibility;
      this.$store.commit("userPreferences", prf);
    },
    onResetZoom() {
      this.currentMinMax = this.minMax;
    },
    onZoomStarted(e) {
      // console.log('onZoomStarted',e)
      if (!e.evt.ctrlKey && e.evt.button == 0) {
        this.isZooming = true;

        this.zoomAreaRect.start = e.currentTarget.getPointerPosition().x;
        this.zoomAreaRect.end = e.currentTarget.getPointerPosition().x;
      }
    },
    onZoomEnded() {
      if (this.isZooming) {
        this.isZooming = false;
        if (Math.abs(this.zoomAreaRect.start - this.zoomAreaRect.end) > 4) {
          if (this.currentMinMax == null) {
            this.currentMinMax = this.minMax;
          }
          const scale = (this.currentMinMax.mx - this.currentMinMax.mn) / this.width;
          const min = this.currentMinMax.mn + Math.round(Math.min(this.zoomAreaRect.start, this.zoomAreaRect.end) * scale);
          const max = this.currentMinMax.mn + Math.round(Math.max(this.zoomAreaRect.start, this.zoomAreaRect.end) * scale);
          this.currentMinMax = {
            mn: min,
            mx: max,
          };
          // console.log("currentMinMax", this.currentMinMax.mn, this.currentMinMax.mx);
        }
      }
    },
    onZoomSelecting(e) {
      if (this.isZooming) {
        this.zoomAreaRect.end = e.currentTarget.getPointerPosition().x;
      }
    },
    getImage() {
      let stage = this.$refs.stage.getNode();
      const header = "data:image/png;base64,";
      return stage.toDataURL({ pixelRatio: 1.5 }).slice(header.length);
      // return 'HI'
    },
    toX(num) {
      return toScatedX(num, this.tadInfo.min, this.tadInfo.scale);
    },
    // onMutationMouseOver() {
    // },
    // onMutationMouseOut() {
    // },
    onGeneTrackHeight(val) {
      this.geneTrackHeight = val + 92;
    },
    onFishboneTrackHeight(val) {
      this.fishboneTrackHeight = val;
    },
    onRareSVsTrackHeight(val) {
      this.sVsTrackHeight = val;
    },
    onRareSNVsTrackHeight(val) {
      // console.log('this.raremotherPathogenicSVsTrackHeight',this.sNVsTrackHeight)
      this.sNVsTrackHeight = val;
    },
    onRareTRsTrackHeight(val) {
      // console.log('this.raremotherPathogenicSVsTrackHeight',this.sNVsTrackHeight)
      this.trsTrackHeight = val;
    },
    downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;
    },
    onExportAsPng() {
      let stage = this.$refs.stage.getNode();
      var dataURL = stage.toDataURL({ pixelRatio: 2 });
      this.downloadURI(dataURL, this.name + ".png");
    },
  },
  computed: {
    dynamicTrackTitleConfig() {
      return {
        x: 4,
        y: this.dynamicTrackBaseLine - this.dynamicTrackTitleConfigHeight + 12,
        width: 200,
        text: "Epigenetic Information",
        fontSize: 13,
        fill: "#C585B7",
        align: "left",
        fontStyle: "bold",
      };
    },
    zoomAreaRectConfig() {
      return {
        x: Math.min(this.zoomAreaRect.end, this.zoomAreaRect.start),
        y: 0,
        width: this.isZooming ? Math.abs(this.zoomAreaRect.end - this.zoomAreaRect.start) : 0,
        height: this.stageSize.height,
        stroke: "#0393A8",
        // strokeWidth: this.selected? 1 : 0 ,
        strokeWidth: this.isZooming ? 0.1 : 0,
        // fill: this.colors[this.info.type],
        fill: "#0393A810",
      };
    },

    trsTrackBaseLine(){
      return this.baseLineY + this.geneTrackHeight + 1 + this.sVsTrackHeight + this.sNVsTrackHeight;
    },
    fishboneTrackBaseline() {
      return this.baseLineY + this.geneTrackHeight + 1 + this.sVsTrackHeight + this.sNVsTrackHeight + this.trsTrackHeight;
    },
    axisBaseline() {
      return this.baseLineY + 40;
    },
    geneTrackBaseline() {
      return this.baseLineY + 90;
    },
    dynamicTrackBaseLine() {
      return this.baseLineY + this.geneTrackHeight + 1 + this.sVsTrackHeight + this.sNVsTrackHeight + this.trsTrackHeight + this.fishboneTrackHeight + this.dynamicTrackTitleConfigHeight;
      //+ this.dynamicTracks.length * 52
    },
    sVsTrackBaseLine() {
      return this.baseLineY + this.geneTrackHeight;
      // return this.rareCohortPathogenicSVsTrackBaseLine + this.rareCohortPathogenicSVsTrackHeight
    },
    sNVsTrackBaseLine() {
      return this.sVsTrackBaseLine + this.sVsTrackHeight;
    },
    chromosomeInfo() {
      return {
        id: this.chromosome.id,
        start: this.tadInfo.min,
        end: this.tadInfo.max,
      };
    },
    // selectedMutation() {
    //   if (this.mutations.length > 0) {
    //     return this.mutations[0];
    //   } else {
    //     return null;
    //   }
    // },
    tadInfo() {
      let min = 0;
      let max = 0;
      if (this.currentMinMax != null) {
        // console.log(scale)
        min = this.currentMinMax.mn;
        max = this.currentMinMax.mx;
        // console.log(min,max)
      } else {
        min = this.minMax.mn;
        max = this.minMax.mx;
      }
      return {
        min: min,
        max: max,
        scale: this.width / (max - min),
      };
    },
    backgroundRectConfig() {
      return {
        x: 0,
        y: 0,
        width: this.stageSize.width,
        height: this.stageSize.height,
        // stroke: "black",
        // strokeWidth: this.selected? 1 : 0 ,
        strokeWidth: 0,
        // fill: this.colors[this.info.type],
        fill: "#ffffff",
      };
    },
    dynamicTracksHeight() {
      if (!this.regolumeDetailsVisibility.EPIGENETIC_INFORMATION.show) {
        return 0;
      } else {
        return this.dynamicTracks.length * 52;
      }
    },
    stageHeight() {
      return this.baseLineY 
             + this.geneTrackHeight 
             + 1 
             + this.sVsTrackHeight 
             + this.sNVsTrackHeight 
             + this.trsTrackHeight 
             + this.fishboneTrackHeight 
             + this.dynamicTrackTitleConfigHeight 
             + 20 
             + this.dynamicTracksHeight;
    },

    maxTadHeights() {
      const tadLength = this.tads.map((item) => this.toX(item.end) - this.toX(item.start));
      const maxTads = Math.max(...tadLength);
      return maxTads / 1.45 + 40;
    },

    stageSize() {
      // const h = this.baseLineY + this.maxTadHeights + 80  + this.geneTrackHeight + this.dynamicTracks.length * 40
      // const h = 1000
      // console.log(h, this.geneTrackBaseline , this.maxTadHeights , this.dynamicTracks.length)
      return {
        width: this.width + 30,
        height: this.stageHeight,
      };
    },
    normalizationFactor() {
      // console.log(this.minMax)
      return this.width / (this.minMax.mx - this.minMax.mn);
    },
    minMax() {
      let ends = this.tads.map((item) => item.end);
      ends = this.geneEnhancers.elements.map((item) => item.end).concat(ends);
      this.dynamicTracks.forEach((tr) => {
        tr.instants.forEach((ins) => ends.push(ins.end));
      });

      let starts = this.tads.map((item) => item.start);
      starts = this.geneEnhancers.elements.map((item) => item.start).concat(starts);
      this.dynamicTracks.forEach((tr) => {
        tr.instants.forEach((ins) => starts.push(ins.start));
      });

      const mx = ends.reduce((a, b) => Math.max(a, b), 0);
      const mn = starts.reduce((a, b) => Math.min(a, b), +Infinity);

      return { mn: mn, mx: mx };
    },
    baseLineY() {
      let tadHeights = 0;
      if (this.regolumeDetailsVisibility.TADS.show) {
        const heights = this.tads.map((item) => ((item.end - item.start) / 2) * Math.tan(Math.PI / 4));
        tadHeights = this.normalizationFactor * Math.max(...heights);
      }
      return tadHeights + 50;
    },
    allConnections() {
      return this.geneEnhancers.connections.map((cn) => {
        const el = this.geneEnhancers.elements.find((el) => el.id == cn.from);
        const gn = this.geneEnhancers.elements.find((gn) => gn.id == cn.to);
        const r = {
          from: el.start + (el.end - el.start) / 2,
          fromId: el.id,
          to: gn.dir == "+" ? gn.start : gn.end, //+ (gn.end - gn.start) / 2,
          toId: gn.id,
          color: cn.color,
          colorStr: this.colors[cn.color % this.colors.length],
        };
        return r;
      });
    },
    allGenes() {
      return this.geneEnhancers.elements
        .filter((item) => item.type == "GENE")
        .map((item) => {
          item.colorStr = this.colors[item.color % this.colors.length];
          item.isHit = false; //this.selectedMutation.end >= item.start && this.selectedMutation.start <= item.end;
          return item;
        });
    },
    allREs() {
      return this.geneEnhancers.elements
        .filter((item) => item.type == "RE")
        .map((item) => {
          item.colorStr = this.colors[item.color % this.colors.length];
          item.isHit = false; //this.selectedMutation.end >= item.start && this.selectedMutation.start <= item.end;
          return item;
        });
    },
    canonicalIsoforms() {
      return this.isoforms.filter((item) => item.canonical == true || item.canonical == undefined);
    },
  },
};
</script>

<style scoped>
.borderedBox {
  box-sizing: border-box;
  background-color: rgb(238, 238, 238);
  border: 1px solid rgb(177, 177, 177);
  border-radius: 8px;
}
</style>