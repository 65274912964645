<template>
    <div class="d-flex flex-row flex-wrap">
        <div v-for="(dt, index) in details" :key="index" class="text-body-2 grey--text text--darken-2 mx-1 px-3 py-1 rounded">
            <span class="grey--text text--darken-3">{{ dt.name }}: </span> 
            <span v-if="dt.type == null && dt.name.toLowerCase() != 'hgvsp'"> {{ dt.value }}</span>
            <a v-if="dt.type == 'OMIM_LINK'" :href="'https://www.omim.org/entry/' + dt.value + '?search=' + dt.value + '&highlight=' + dt.value" target="_black">
                {{ dt.value  }}
            </a>

            <a v-if="dt.type == 'CLINVAR_LINK'" :href="'https://www.ncbi.nlm.nih.gov/clinvar/' + dt.value  + '/?redir=rcv'" target="_black">
               {{ dt.value  }}
            </a>
            <a v-if="dt.type == 'SNP_LINK'" :href="'https://www.ncbi.nlm.nih.gov/snp/' +  dt.value" target="_black">
               {{ dt.value  }}
            </a>
            
            <template v-if="dt.type == 'GENE'">
                <a v-if="omimId != null" class="mr-1" :href="`https://www.omim.org/entry/${omimId}?search=${firstGene}&highlight=${firstGene}`" target="_black">
                   {{ firstGene }}
                </a >
                <span v-else> {{ firstGene }} </span>
                <span v-for="gene,index in genes.slice(1)"  :key="index" class="ml-1" >
                   {{ gene }}
                </span >
            </template>

            <template v-if="dt.name.toLowerCase() == 'hgvsp'">
                <a v-if="uniprotId != null" :href="`https://www.uniprot.org/uniprotkb/${uniprotId}/feature-viewer`" target="_black">
                   {{ HGVSp  }}
                </a>
                <span v-else> {{ HGVSp }} </span>
            </template>           

        </div>
    </div>
</template>
<script>
import { getGene } from "@/api/gene/gene.js";
import { searchUniprotByGene } from "@/api/gene/uniprot.js";

export default {
    name:'TrSnvDetail',
    props: {
        details: {
            type: Array(),
            required: true,
        }
    },
    data:()=>({
        omimId : null,
        uniprotId: null,
    }),
    computed:{
        genes(){
            let x = this.details.find(dt=> dt.type == 'GENE')
            if (!x) {
                return []
            } 
            return x.value
        },
        HGVSp(){
            let x = this.details.find(dt=> dt.name.toLowerCase() == 'hgvsp')
            if (!x) {
                return null
            } 
            return  x.value.substr(x.value.indexOf(':')+1) 
        },
        firstGene(){
            if (this.genes.length == 0){
                return null
            }
            return this.genes[0]
        }
    },
    // method()
    watch:{
        firstGene(val){
            // console.log('WATCH',val)
            this.omimId  = null
            this.uniprotId = null
            if(val){
                const _this = this
                getGene(
                    val,
                    (res) => {
                        _this.omimId = res.omim
                    },
                    ()=>{
                        _this.omimId = null
                    }
                )                
                searchUniprotByGene(
                    val,
                    (res) => {
                        // console.log(res.results)
                        if(res.results.length > 0){
                            _this.uniprotId = res.results[0].primaryAccession
                        } else{
                            _this.uniprotId = null
                        }
                    }
                )  
            } 
        }
    }

}
</script>