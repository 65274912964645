<template>
    <v-container >
  <div class="my-4 mx-4">
    <v-stepper alt-labels>
    <v-stepper-header>
      <v-stepper-step
        :rules="[() => (dataset.error === null)]"
        :complete="dataset.status.id >= 15 && dataset.error === null"
        step="1"
        :complete-icon="dataset.status.id <= 15 ? 'mdi-timer-sand' : 'mdi-check'"
        :color="dataset.status.id === 15 ? 'secondary' : 'primary'" >
        Choose File
        <v-progress-linear v-if="dataset.status.id === 15 && dataset.error === null"
            color="secondary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        <small v-if="dataset.error !== null">{{dataset.error.name}}</small>
      </v-stepper-step>
      <v-divider></v-divider>


      <v-stepper-step
        :rules="[() => (dataset.error === null)]"
        :complete="dataset.status.id >= 20 && dataset.error === null"
        step="2"
        :complete-icon="dataset.status.id <= 20 ? 'mdi-timer-sand' : 'mdi-check'"
        :color="dataset.status.id === 20 ? 'secondary' : 'primary'" >
        Upload
        <v-progress-linear v-if="dataset.status.id === 20 && dataset.error === null"
            color="secondary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        <small v-if="dataset.error !== null">{{dataset.error.name}}</small>
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step
        :rules="[() => (dataset.error === null)]"
        :complete="dataset.status.id >= 30 && dataset.error === null"
        step="3"
        :complete-icon="dataset.status.id <= 30 ? 'mdi-timer-sand' : 'mdi-check'"
        :color="dataset.status.id === 30 ? 'secondary' : 'primary'" >
        Extract
        <v-progress-linear v-if="dataset.status.id === 30 && dataset.error === null"
            color="secondary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        <small v-if="dataset.error !== null">{{dataset.error.name}}</small>
      </v-stepper-step>
      <v-divider></v-divider>

      <v-stepper-step
        :rules="[() => (dataset.error === null)]"
        :complete="dataset.status.id >= 40 && dataset.error === null"
        step="4"
        :complete-icon="dataset.status.id <= 40 ? 'mdi-timer-sand' : 'mdi-check'"
        :color="dataset.status.id === 40 ? 'secondary' : 'primary'" >
        Import
        <v-progress-linear v-if="dataset.status.id === 40 && dataset.error === null"
            color="secondary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        <small v-if="dataset.error !== null">{{dataset.error.name}}</small>
      </v-stepper-step>
      <v-divider></v-divider>
      <v-stepper-step
        :complete-icon="dataset.status.id < 50 ? 'mdi-timer-sand' : 'mdi-check'"
        :rules="[() => (dataset.error === null)]"
        :complete="dataset.status.id === 50"
        step="5" >
        Complete
      </v-stepper-step>
    </v-stepper-header>
  </v-stepper>
  </div>
  <div >

  <div class="mt-8 mx-4">
  <VueFileAgent
    :disabled="dataset.error === null && dataset.status.id > 15 && dataset.status.id < 50"
    ref="vueFileAgent"
    :theme="'list'"
    :multiple="false"
    :deletable="true"
    :meta="true"
    :accept="'.zip'"
    :maxSize="'1500MB'"
    :maxFiles="1"
    :helpText="'Click here to choose your database file or drag it here.'"
    :errorText="{
      type: 'Invalid file type. Only zip Allowed',
      size: 'Files should not exceed 1500MB in size',
    }"
    @select="filesSelected($event)"
    @beforedelete="onBeforeDelete($event)"
    @delete="fileDeleted($event)"
    @upload="onUploaded($event)"
    @upload:error="onUploadError($event)"
    v-model="fileRecords"
  ></VueFileAgent>
  </div>
  <div class="mx-4">
  <v-btn color="primary" class="mb-2 mt-4" :disabled="!fileRecordsForUpload.length || (dataset.error === null && dataset.status.id > 15 && dataset.status.id < 50)" @click="uploadFiles()" >
    Import File
  </v-btn>
  </div>
  <div class="red--text mx-4 my-4" v-if="dataset.error"><b>{{dataset.error ? dataset.error.name : '' }}!</b> Details: {{dataset.errorDetails}}</div>
  </div>
    </v-container>
</template>

<script>
  import VueFileAgentPlugin from 'vue-file-agent'
  import config from '@/config.js'
  // eslint-disable-next-line no-unused-vars
  import VueFileAgentStyles from 'vue-file-agent/dist/vue-file-agent.css'
  const VueFileAgent = VueFileAgentPlugin.VueFileAgent
  import {getDataset, updateDatasetStatus} from '@/api/dataset.js'
  import store from '@/store'

  export default {
    //TODO: if uploading the file fails, the component remains disabled!
    //TODO: if uploading the file fails, the inquiery job should be stopped

    name: 'DatasetUpload',
    components: {VueFileAgent },
   data: function () {
      return {
        fileRecords: [],
        datasetId: this.$route.params.datasetId,
        uploadUrl: config.backendEndpoint + "/api/v1/datasets/" + this.$route.params.datasetId + "/upload",
        // uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
        // uploadHeaders: ,
        fileRecordsForUpload: [], // maintain an upload queue
        dataset: {
          status: {
            id:10
          },
          error: {
            name: null
          },
          errorDetails: null,
        },
        getDatasetTimer: null
      };
    },
    created(){
      this.getDatasetInfo()

    },
    methods: {
      getUploadHeaders(){
        return {'Content-Type': 'application/zip', 'Authorization': 'Bearer ' +  store.state.Token }
      },
      getDatasetInfo (){
        const _this = this
        getDataset(this.datasetId, function (res){
          _this.dataset = res
          if(_this.getDatasetTimer === null && _this.dataset.error === null && _this.dataset.status.id < 50 && _this.dataset.status.id > 15  ) {
            _this.getDatasetTimer = setInterval(() => _this.getDatasetInfo(), 1000);
          }
          if ( _this.getDatasetTimer !== null && (res.error !== null || res.status.id === 50) ){
             clearInterval(_this.getDatasetTimer);
          }
        }, function (err){
          console.log(err)
        })
      },
      //TODO ON_ERROR
      onUploadError (err) {

        console.log(err)
      },
      onUploaded (responses){
        var succeeded = true
        for (var rsp of responses){
          if ( rsp.error){
            succeeded = false
            break
          }
        }
        if ( succeeded ){
          this.getDatasetTimer = setInterval(() => this.getDatasetInfo(), 1000);
          this.$root.notify.show({message: 'File uploaded successfully and it is now importing. You can leave the page and check back later'})
        }
      },
      uploadFiles: function () {
        const _this = this
        //TODO:Alert user that all previously uploaded data will be removed
        updateDatasetStatus(this.datasetId, { status: 20},  function (){
          _this.dataset.status.id = 20
          var url = _this.uploadUrl + "/" + _this.fileRecordsForUpload[0].name()
          // Using the default uploader. You may use another uploader instead.
          _this.$refs.vueFileAgent.upload(url, _this.getUploadHeaders(), _this.fileRecordsForUpload);
          _this.fileRecordsForUpload = []; //TODO: Making FileUploader empty?!
        })
      },
      deleteUploadedFile: function (fileRecord) {
        // Using the default uploader. You may use another uploader instead.
        this.$refs.vueFileAgent.deleteUpload(this.uploadUrl, this.getUploadHeaders(), fileRecord);
      },
      filesSelected: function (fileRecordsNewlySelected) {
        this.dataset.status.id = 15
        var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error);
        this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords);
      },
      onBeforeDelete: function (fileRecord) {
        this.dataset.status.id = 10

        var i = this.fileRecordsForUpload.indexOf(fileRecord);
        if (i !== -1) {
        // queued file, not yet uploaded. Just remove from the arrays
          this.fileRecordsForUpload.splice(i, 1);
          var k = this.fileRecords.indexOf(fileRecord);
          if (k !== -1) this.fileRecords.splice(k, 1);
        } else {
          if (confirm('Are you sure you want to delete?')) {
            this.$refs.vueFileAgent.deleteFileRecord(fileRecord); // will trigger 'delete' event
          }
        }
      },
      fileDeleted: function (fileRecord) {
        var i = this.fileRecordsForUpload.indexOf(fileRecord);
        if (i !== -1) {
          this.fileRecordsForUpload.splice(i, 1);
        } else {
          this.deleteUploadedFile(fileRecord);
        }
      },
    },
    beforeRouteLeave (to, from , next) {
      var canLeave = true
      if( this.dataset.status.id == 20){
        canLeave = window.confirm('The file is being uploaded. If you leave the page, it will be canceled! Are you sure?')
        if(canLeave){
          updateDatasetStatus(this.datasetId, { status: 10})
        }
      }
      if (canLeave && this.getDatasetTimer !== null ){
        clearInterval(this.getDatasetTimer);
      }
      next(canLeave)
    }
  }
</script>
