<template>
  <CardMenu :title="`Filter-Sets on ${cohortName}`" v-model="menu" tooltip="Show saved filter-sets" width="900px" customClass="mx-0 px-0">
    <template v-slot:button>
      <v-btn icon color="primary" class="mt-1"> <icon large id="filter-set-list" color="primary"></icon></v-btn>
    </template>
    <template v-slot:body>
      <v-tabs v-model="tab">
        <v-tab>My Filter-Sets</v-tab>
        <v-tab>{{ organizationName }} Filter-Sets</v-tab>
        <v-tab>Lucid Recommended Filter-Sets</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div class="mb-2 mt-4" style="max-height: 400px; min-height: 300px; overflow-y: scroll">
            <div v-for="(item, index) in filterSets" :key="index" class="frame">
              <div class="d-flex flex-row">
                <EditableLabel v-model="selectedFilterSetId" :maxLength="200" :id="item.id" :title="item.name" @onUpdate="onLabelUpdate"></EditableLabel>
                <Confirm customClass="ml-auto " :message="`Are you sure you want to delete '${item.name}'?`" @onAnswer="(res) => onConfirmDelete(res, item.id)">
                  <v-btn class="mb-1 mr-2" x-small text color="red">Delete</v-btn>
                </Confirm>
                <Confirm :buttons="hasFilters? [{val:'NO',color:'primary'}, {val:'YES',color:'primary'}] : [{val:'Close',color:'primary'}]" :message="!hasFilters? 'No filter has been selected!':`Are you sure you want to overwrite '${item.name}' with the currect filters?`" @onAnswer="(res) => onConfirmUpdateFilters(res, item.id)">
                  <v-btn   class="mb-1 mr-2" x-small text color="primary">Update</v-btn>
                </Confirm>
                <v-btn x-small outlined color="primary" width="70px" @click="onLoadFilters(item, 'user')">Load</v-btn>
              </div>
              <SelectedFilterChipsList :selectedPropertiesLabels="item.filters.filtersLabels" dense color="grey darken-1" :enableCloseChips="false"></SelectedFilterChipsList>
            </div>
            <div v-if="filterSets.length == 0" class="text-center grey--text text-lighten-2">You don't have any filter-sets</div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="mb-2 mt-4" style="max-height: 400px; min-height: 300px; overflow-y: scroll">
            <div v-for="(item, index) in collabFilterSets" :key="index" class="frame">
              <div class="d-flex flex-row">
                <div class="font-weight-bold mr-1">{{ item.name }}</div>
                <div class="grey--text text--darken-1">created by</div>
                <div class="ml-1 font-weight-medium font-italic">{{ item.user.first_name }} {{ item.user.last_name }}</div>
                <v-btn x-small outlined color="primary" class="ml-auto" width="70px" @click="onLoadFilters(item, 'user')">Load</v-btn>
              </div>
              <SelectedFilterChipsList :selectedPropertiesLabels="item.filters.filtersLabels" dense color="grey darken-1" :enableCloseChips="false"></SelectedFilterChipsList>
            </div>
            <div v-if="collabFilterSets.length == 0" class="text-center grey--text text-lighten-2">No filter-set is defined by your network on {{ organizationName }}</div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <div class="mb-2 mt-4" style="max-height: 400px; min-height: 300px; overflow-y: scroll">
            <div v-for="(item, index) in lucidFilterSets" :key="index" class="frame lucid">
              <div class="d-flex flex-row">
                <div class="font-weight-bold mr-1">{{ item.name }}</div>
                <div class="grey--text text--darken-1">recommended by</div>
                <div class="ml-1 font-weight-medium font-italic primary--text">Lucid Genomics</div>
                <v-btn x-small outlined color="primary" class="ml-auto" width="70px" @click="onLoadFilters(item, 'lucid')">Load</v-btn>
              </div>
              <SelectedFilterChipsList :selectedPropertiesLabels="item.filters.filtersLabels" dense color="grey darken-1" :enableCloseChips="false"></SelectedFilterChipsList>
            </div>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </template>
  </CardMenu>
</template>
<script>
import { getCohort } from "@/api/user/dataset.js";
import { getCohortFilterSets, getCohortCollabFilterSets, deleteCohortFilterSets, updateCohortFilterSets } from "@/api/user/user-filter-set.js";

import SelectedFilterChipsList from "@/components/defaultAnalysis/filters/SelectedFilterChipsList";
import Confirm from "@//components/Common/Confirm.vue";
import CardMenu from "@/components/Common/CardMenu.vue";
import EditableLabel from "@//components/Common/EditableLabel.vue";

export default {
  name: "ShowFilterSets",
  components: { SelectedFilterChipsList, Confirm, CardMenu, EditableLabel },
  props: {
    cohortId: {
      type: Number,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    cohortName: null,
    valid: false,
    menu: false,
    filterSets: [],
    collabFilterSets: [],
    selectedFilterSetId: null,
    selectedFilterSetName: null,
    lucidFilterSets: [
      {
        name: "Cohort Analysis Recommended Filters",
        filters: {
          filtersLabels: [
            {
              id: "FILTER",
              name: "FILTER",
              value: "PASS",
              fullText: '<b class="pr-1">FILTER </b> = PASS',
              operator: "=",
              rawValue: ["PASS"],
              truncatedText: null,
            },
            {
              id: "QUAL",
              name: "QUAL",
              value: "[ 0.4 , +∞ ] ",
              fullText: ' <b class="pr-1">QUAL </b> ≥ 0.4',
              operator: "in",
              rawValue: ["0.4", null],
              truncatedText: null,
            },
            {
              id: "DISEASE_STATUS",
              name: "DISEASE_STATUS",
              value: "affected",
              fullText: '<b class="pr-1">DISEASE_STATUS </b> = affected',
              operator: "=",
              rawValue: ["affected"],
              truncatedText: null,
            },
            {
              id: "CLINVAR",
              name: "CLINVAR",
              value:
                " [ ,affects ,association ,confers_sensitivity ,conflicting_interpretations_of_pathogenicity ,likely_pathogenic ,likely_risk_allele ,not_provided ,other ,pathogenic ,pathogenic/established_risk_allele ,uncertain_risk_allele ,uncertain_significance] ",
              fullText:
                '<b class="pr-1">CLINVAR </b> is one of  [ ,affects ,association ,confers_sensitivity ,conflicting_interpretations_of_pathogenicity ,likely_pathogenic ,likely_risk_allele ,not_provided ,other ,pathogenic ,pathogenic/established_risk_allele ,uncertain_risk_allele ,uncertain_significance] ',
              operator: "is one of",
              rawValue: [
                "",
                "affects",
                "association",
                "confers_sensitivity",
                "conflicting_interpretations_of_pathogenicity",
                "likely_pathogenic",
                "likely_risk_allele",
                "not_provided",
                "other",
                "pathogenic",
                "pathogenic/established_risk_allele",
                "uncertain_risk_allele",
                "uncertain_significance",
              ],
              truncatedText: '<b class="pr-1">CLINVAR </b> is one of [ ,affects ,association ,confers_sensitivity ,conflicting_interpretations_of_pathogenicity, ...] ',
            },
          ],
          selectedPropertiesLogic: {},
          effectiveSelectedProperties: {
            QUAL: ["0.4", null],
            FILTER: ["PASS"],
            CLINVAR: [
              "",
              "affects",
              "association",
              "confers_sensitivity",
              "conflicting_interpretations_of_pathogenicity",
              "likely_pathogenic",
              "likely_risk_allele",
              "not_provided",
              "pathogenic",
              "pathogenic/established_risk_allele",
              "uncertain_risk_allele",
              "uncertain_significance",
            ],
            DISEASE_STATUS: ["affected"],
          },
        },
      },
    ],
    tab: null,
  }),
  computed: {
    organizationName() {
      return this.$store.state.Organization.name;
    },
    hasFilters(){
      return this.filters.filtersLabels!=undefined && this.filters.filtersLabels != null && this.filters.filtersLabels.length > 0 
    }
  },
  created() {
    const _this = this;
    getCohort(this.cohortId, (res) => {
      _this.cohortName = res.name;
    });
    this.loadUserFilterSets();
  },
  watch: {
    menu(val) {
      console.log(val)
      if (val) {
        this.loadUserFilterSets();
      }
    },
  },
  methods: {
    onConfirmUpdateFilters(answer, id) {
      if (answer == "YES") {
        const _this = this;
        const selectedFilterIdx = this.filterSets.findIndex((item) => item.id == id);
        updateCohortFilterSets(
          this.cohortId,
          id,
          this.filterSets[selectedFilterIdx].name,
          this.filters,
          "Filter-Set updated successfully",
          () => {
            this.filterSets[selectedFilterIdx].filters = _this.filters;
          }, null
        );
      }
    },
    onLabelUpdate(id, text) {
      const _this = this;
      const selectedFilterIdx = this.filterSets.findIndex((item) => item.id == id);
      updateCohortFilterSets(
        this.cohortId,
        id,
        text,
        this.filterSets[selectedFilterIdx].filters,
        "Filter-Set's name updated successfully",
        () => {
          this.selectedFilterSetId = null;
          this.filterSets[selectedFilterIdx].name = text;
        },
        (err) => {
          const reason = err?.data?.Reason || null;
          if (reason == "NON_FIELD_ERRORS_ALREADY_EXIST") {
            _this.$root.notify.show({ message: `Filter-set name '${text}' already exists!`, type: "error" });
          }
        }
      );
    },
    onLoadFilters(filter, type) {
      this.$emit("onLoadFilter", filter, type);
      this.menu = false;
    },
    onConfirmDelete(answer, id) {
      if (answer == "YES") {
        const _this = this;
        deleteCohortFilterSets(this.cohortId, id, "Filter-set removed successfully", () => {
          _this.loadUserFilterSets();
        });
      }
    },
    onDeleteFilterset(id) {
      console.log(id);
    },
    loadUserFilterSets() {
      const _this = this;
      getCohortFilterSets(this.cohortId, (res) => {
        _this.filterSets = res.sort((a, b) => b.id - a.id);
      });
      getCohortCollabFilterSets(this.cohortId, (res) => {
        _this.collabFilterSets = res; //.filter((item) => item.user.first_name + " " + item.user.last_name != _this.$store.state.FullName);
      });
    },
    onCancelFilterSet() {
      this.menu = false;
    },
  },
};
</script>
<style scoped>
.frame {
  border-radius: 8px;
  border: 1px solid #0393a815;
  background: #bebebe25;
  border-style: solid;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  margin-left: 10px;
  /* box-shadow: 0 4px 12px rgba(0, 0, 0, 0.07) !important; */
}
.lucid {
  border: 1px solid #0393a850;
  background: #bebebe25;
}

::v-deep .updateFilterSetName .v-input__slot {
  min-height: 24px !important;
  height: 24px !important;
  display: flex !important;
  align-items: center !important;
}
</style>