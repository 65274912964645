import ApiIntegrface from "./api"

export function getSamples(cohortId, projectId, callback){
  const api = new ApiIntegrface()
  const url = `/api/v1/cohorts/${cohortId}/projects/${projectId}/samples`
  api.get(url, null, callback, null, null, null)
}

export function getSample(cohortId, projectId, sampleId, callback){
  const api = new ApiIntegrface()
  const url = `/api/v1/cohorts/${cohortId}/projects/${projectId}/samples/${sampleId}`
  api.get(url, null, callback,null, null, null, false)
}

export function getSamplePedigree(cohortId, projectId, sampleId, callback){
  const api = new ApiIntegrface()
  const url = `/api/v1/cohorts/${cohortId}/projects/${projectId}/samples/${sampleId}/pedigree`
  api.get(url, null, callback,null, null, null, false)
}
 
export function getSampleByName(cohortId, projectId, sampleName, callback){
  const api = new ApiIntegrface()
  const payload = {
    sample_name: sampleName
  }
  const url = `/api/v1/cohorts/${cohortId}/projects/${projectId}/samples/search`
  api.post(url, payload, callback,null, null, null, false)
}
// export function addSample(payload, callback, successMessage){
//   const api = new ApiIntegrface()
//   const url = '/api/v1/samples'
//   api.post(url, payload, callback, null, successMessage)
// }


// export function updateSample(payload, sampleId,callback, successMessage){
//   const api = new ApiIntegrface()
//   const url = '/api/v1/samples/'+ sampleId
//   api.put(url, payload, callback, null, successMessage)
// }

// export function deleteSample(sampleId, callback, successMessage){
//   const api = new ApiIntegrface()
//   const url = '/api/v1/samples/' + sampleId
//   api.delete(url, null, callback, null, successMessage)
// }

// export function clearCache(sampleId, successMessage){
//   const api = new ApiIntegrface()
//   const url = '/api/v1/samples/' + sampleId + '/cache'
//   api.delete(url, null, null, null, successMessage)
// }

export function getSampleStatuses(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/samplestatuses'
  api.get(url, null, callback, null, null, null)
}
export function getSampleTechnologies(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/sampletechnologies'
  api.get(url, null, callback, null, null, null)
}

