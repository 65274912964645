<template>
  <div class="pa-12">
    <div class="d-flex flex-row">
      <div class="text-h4 font-weight-bold mb-4" style="width: 330px">
        <v-icon x-large color="primary">mdi-cloud-upload-outline</v-icon>
        {{ $route.meta.routeTitle }}
      </div>
      <div class="mt-2 text-h6 grey--text text--darken-2">
        <span> <router-link :to="{ name: 'UserCohorts' }" style="text-decoration: none !important"> Cohorts </router-link> > </span>
        <span> {{ cohortName }} - {{ projectName }} </span>

        <span> > {{ $route.meta.routeTitle }}</span>
      </div>
    </div>
    <div class="boxFrame pa-10">
      <div>
        <div class="text-h5 font-weight-medium mr-auto"><v-icon class="mb-1 mr-1" color="primary">mdi-stethoscope</v-icon>Relevant Clinical Data</div>
        <div class="mt-1 body-2 grey--text">Pedigree and Family Information, Complete Clinical History (from the birth to sequencing), Associated HPO Terms, List of Symptoms all as a single ZIP file</div>
        <div class="d-flex flex-row mt-6">
          <div class="grey--text text--darken-2" style="min-width: 400px">File Name:{{ clinicalDataFileName }}</div>
          <div class="grey--text text--darken-2">Last Updated: {{ clinicalDataLastUpdate }}</div>
          <ClinicalDataFileUploader class="ml-auto" :cohortId="cohortId" :projectId="projectId" @onUploaded="onClinicalFileUploaded"></ClinicalDataFileUploader>
        </div>
      </div>
    </div>

    <div class="boxFrame pa-10 mt-4">
      <div class="d-flex flex-row">
        <div class="text-h5 font-weight-medium mr-auto"><v-icon class="mb-1 mr-1" color="primary">mdi-data-matrix</v-icon>Samples</div>
        <v-menu offset-y :rounded="false" :close-on-content-click="false" transition="slide-y-transition" bottom left v-model="newSampleMenu" style="box-shadow: none !important">
          <template v-slot:activator="{ on, attrs }">
            <v-btn small elevation="0" color="primary" outlined v-bind="attrs" v-on="on" width="120px"> Add Sample<v-icon small>mdi-chevron-down</v-icon> </v-btn>
          </template>
          <div class="d-flex flex-row pa-6 grey lighten-4">
            <v-text-field hide-details dense solo class="Filter" v-model="newSampleName" placeholder="Sample Name"></v-text-field>
            <v-btn icon :disabled="!newSampleName" color="primary" @click="onAddSample"><v-icon>mdi-check</v-icon></v-btn>
          </div>
        </v-menu>
      </div>

      <div v-if="seqDataResult.length > 0">

        <SampleRowUploader v-for="item in seqDataResult" :key="item.sample_name" :sample="item" :cohortId="cohortId" :projectId="projectId" @onFileUploaded="onFileUploaded" ></SampleRowUploader>
      </div>
      <div v-else class="grey--text text-center">To start uploading data, click on ADD SAMPLE</div>
    </div>
  </div>
</template>
<script>
// import { getCohort } from "@/api/cohort.js";
import { getCohort } from "@/api/user/dataset.js";
import { getDatasetsByProject } from "@/api/dataset.js";
import { getCohortSeqData, addCohortSeqDataSample, getClinicalData } from "@/api/user/seqdata.js";
import { toDateTimeString } from "@/utils/datetime.js";
// import SeqFileUploader from "@/components/UploadSequenceData/SeqFileUploader.vue";
import SampleRowUploader from "@/views/user/upload/SampleRowUploader.vue"
import ClinicalDataFileUploader from "@/components/UploadSequenceData/ClinicalDataFileUploader.vue";
import App from "@/main";

export default {
  name: "LucidAllAnalysis",
  components: {  ClinicalDataFileUploader, SampleRowUploader },

  data: () => ({
    projectId: null,
    cohortId: null,
    cohortName: null,
    projectName: null,
    newSampleName: null,
    newSampleMenu: false,
    sampleUploadType: {},
    headers: [
      { text: "Sample Name", divider: true, align: "start", width: "250", sortable: true, value: "sample_name", class: "primary white--text ", cellClass: "variantCell" },
      { text: "FASTQ R1", divider: true, sortable: false, align: "center", value: "fastq1", class: "primary white--text ", cellClass: "variantCell" },
      { text: "FASTQ R2", divider: true, sortable: false, align: "center", value: "fastq2", class: "primary white--text ", cellClass: "variantCell" },
      // { text: "Uploaded By", divider:true, align: "center", width: "250", value: "uploadedBy", class: "primary white--text ", cellClass: "variantCell" },
    ],
    rawSeqDataResult: [],
    uploadMenu: {},
    files: {},
    fileValidationRules: [(value) => !value || value.size < 50000000000 || "Fastq file size should be less than 50 GB!"],
    CHUNK_SIZE: 500 * 1024 * 1024, //500MB,
    THREAD_COUNT: 15,
    rawClinicalResult: null,
  }),
  computed: {
    seqDataResult() {
      return this.rawSeqDataResult;
    },
    clinicalDataFileName() {
      if (this.rawClinicalResult && this.rawClinicalResult.length > 0) {
        return this.rawClinicalResult[0].clinical_file_name;
      } else {
        return null;
      }
    },
    clinicalDataLastUpdate() {
      if (this.rawClinicalResult && this.rawClinicalResult.length > 0) {
        return toDateTimeString(this.rawClinicalResult[0].updated_at);
      } else {
        return null;
      }
    },
  },
  created() {
    this.projectId = Number(this.$route.params.id);
    this.cohortId = Number(this.$route.params.cohortid);
    const _this = this;
    getCohort(this.cohortId, (res) => {
      _this.cohortName = res.name;
    });
    getDatasetsByProject(this.projectId, (res) => {
      _this.projectName = res[0]?.sample.name || null;
    });
    getCohortSeqData(this.cohortId, this.projectId, (res) => {
      _this.rawSeqDataResult = res;
      _this.rawSeqDataResult.forEach((item => {
        if(!item.fastq1 || item.fastq1.toLowerCase().endsWith('gz')){
          _this.sampleUploadType[item.sample_name] = 'FASTQ'
        } else {
          _this.sampleUploadType[item.sample_name] = 'BAM'

        }
        
      }))
    });
    this.loadClinicalData();
  },
  methods: {
    toDateTimeString: toDateTimeString,
    onClinicalFileUploaded() {
      this.loadClinicalData();
      console.log("onClinicalFileUploaded");
    },
    loadClinicalData() {
      const _this = this;
      getClinicalData(this.cohortId, this.projectId, (res) => {
        _this.rawClinicalResult = res;
      });
    },
    onFileUploaded(id) {
      const _this = this;
      console.log('uploaded ', id)
      getCohortSeqData(this.cohortId, this.projectId, (res) => {
        const idx = _this.rawSeqDataResult.findIndex((item) => (item.id = id));
        const item = res.find((item) => (item.id = id));
        if (idx > -1) {
          _this.$set(_this.rawSeqDataResult, idx, item);
        }
      });
    },
    onAddSample() {
      const _this = this;
      addCohortSeqDataSample(
        this.cohortId,
        this.projectId,
        this.newSampleName,
        (res) => {
          _this.rawSeqDataResult.push(res);
          this.newSampleMenu = false;
          this.newSampleName = null;
        },
        (err) => {
          console.log("errr", err);
          if (err?.data?.Reason === "NON_FIELD_ERRORS_ALREADY_EXIST") {
            App.notify.show({ message: `Sample '${this.newSampleName}' already exist`, type: "warning" });
          } else {
            App.notify.show({ message: `Invalid data on ${this.newSampleName} `, type: "error" });
          }
        }
      );
    },
    onUploadClinical() {},
    // sendCompleteRequest(seqFileId, seqFileNo,uploadId, uploadedParts){
    //   CompleteCohortSeqDataSampleUpload(this.cohortId, this.projectId,  seqFileId, seqFileNo , uploadId, uploadedParts, (res)=>{
    //     console.log(res)
    //   })
    // },
    // onUpload(id, fastqNo) {
    //   this.uploadMenu[`${id}-${fastqNo}`] = false;
    //   const fileName = this.files[`${id}-${fastqNo}`].name;
    //   const numberOfparts = Math.ceil(this.files[`${id}-${fastqNo}`].size / this.CHUNK_SIZE);
    //   const _this = this
    //   getCohortSeqDataUploadParts(this.cohortId, this.projectId, id, fastqNo, fileName, numberOfparts, (res) => {

    //     const uploaderOptions= {
    //       useTransferAcceleration: false,
    //       chunkSize: _this.CHUNK_SIZE,
    //       threadsQuantity: _this.THREAD_COUNT,
    //       file: _this.files[`${id}-${fastqNo}`],
    //       fileName: fileName,
    //       parts: res.parts.map(part => { return { signedUrl: part.url, PartNumber: part.part_number }}),
    //       sendCompleteRequestCallback: _this.sendCompleteRequest,
    //       uploadId:res.upload_id
    //     }

    //     const uploader = new Uploader(uploaderOptions);
    //     uploader.onProgress(({ percentage: newPercentage }) => {
    //       console.log(newPercentage)
    //         // to avoid the same percentage to be logged twice
    //         // if (percentage === 100) {
    //           // setPerf((performance.now() - tBegin) / 1000);
    //         // }
    //         // if (newPercentage !== percentage) {
    //         //   percentage = newPercentage;
    //           // setPgvalue(percentage);
    //         // }
    //       })
    //       .onError((error) => {
    //         // setFile(undefined);
    //         console.error(error);
    //       });
    //     uploader.start()

    //     console.log(res);

    //   });

    //   console.log(id, fastqNo);
    // },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .Filter.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.07) !important;
}

::v-deep tr.v-data-table__selected {
  background: var(--v-accent-base) !important;
  border-bottom: 0px !important;
}
.v-data-table::v-deep .v-data-table__expanded__content {
  box-shadow: none !important;
}
.theme--light.v-data-table::v-deep {
  background-color: transparent;
}
.theme--light.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr > td.variantCell {
  height: 48px !important;
  // border-spacing: 10px 10px !important;
  // border-bottom: 1px solid var(--v-primary-base) !important;
  border-bottom: 1px solid #0393a850 !important;
}

.v-data-table::v-deep > .v-data-table__wrapper > table > tbody > tr.v-data-table__expanded__row > td.variantCell {
  border-bottom: 0px solid red !important;
}

.sampleRow {
  border-radius: 8px;
  border: 1px solid #b8d7db;
  background: #fcfcfc;
  border-style: solid;
  padding: 8px;
  margin-top: 10px;
}
</style>