<template>
  <div class="d-flex flex-row rounded white" :class="isPinned ? 'stickyPatientInfo' : ''">
    <v-card flat width="30%" outlined>
      <v-card-title class="grey lighten-3 font-weight-medium pt-2" style="height: 50px !important">
        <div><v-icon class="mr-1">mdi-tag-text-outline</v-icon> Details</div>
        <!-- <v-icon class="ml-auto" @click="isPinned.details = !isPinned.details">{{ isPinned.details ? "mdi-pin-off-outline" : "mdi-pin-outline" }}</v-icon> -->
      </v-card-title>
      <v-card-text class="px-1" v-if="sampleInfo">
        <v-row dense class="mt-2 px-4">
          <v-col cols="5" class="font-weight-medium">Case Name: </v-col>
          <v-col cols="7"
            >{{ sampleInfo.sample_name }} - <span :style="'color:' + getDiseaseColor(sampleInfo.disease_status.name)">{{ sampleInfo.disease_status.name }}</span>
          </v-col>
        </v-row>
        <v-row dense class="grey lighten-4 px-4">
          <v-col cols="5" class="font-weight-medium">Reference Genome</v-col>
          <v-col cols="7">GRCh38</v-col>
        </v-row>
        <v-row dense class="px-4">
          <v-col cols="5" class="font-weight-medium">Father</v-col>
          <v-col cols="7">
            <a v-if="fatherInfo.href" :href="fatherInfo.href" target="_blank" class="text-decoration-none">
               {{ fatherInfo.sample_name }} 
               <v-icon x-small class="mb-2" color="primary">mdi-open-in-new</v-icon>
            </a>
            <span v-else>{{ fatherInfo.sample_name }}</span>
             - <span :style="'color:' + getDiseaseColor(fatherInfo.status)"> {{ fatherInfo.status }}</span>
          </v-col>
        </v-row>
        <v-row dense class="grey lighten-4 px-4">
          <v-col cols="5" class="font-weight-medium">Mother</v-col>
          <v-col cols="7">
            <a v-if="motherInfo.href" :href="motherInfo.href" target="_blank" class="text-decoration-none">
               {{ motherInfo.sample_name }} 
               <v-icon x-small class="mb-2" color="primary">mdi-open-in-new</v-icon>
            </a>
            <span v-else>{{ motherInfo.sample_name }}</span>
            - <span :style="'color:' + getDiseaseColor(motherInfo.status)"> {{ motherInfo.status }}</span></v-col
          >
        </v-row>
        <v-row dense class="px-4">
          <v-col cols="5" class="font-weight-medium">Family</v-col>
          <v-col cols="7">{{ sampleInfo.family_id }} </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card flat width="40%" class="ml-4" outlined>
      <v-card-title class="grey lighten-3 font-weight-medium d-flex flex-row pt-2" style="height: 50px !important">
        <div><v-icon class="mr-1">mdi-tag-text-outline</v-icon> Disease Information</div>
        <!-- <v-icon class="ml-auto" @click="isPinned.phenotype = !isPinned.phenotype">{{ isPinned.phenotype ? "mdi-pin-off-outline" : "mdi-pin-outline" }}</v-icon> -->
      </v-card-title>
      <v-card-text class="d-flex flex-column mt-4">
        <div class="d-flex flex-wrap">
          <div class="mr-2 font-weight-medium">Inheritance:</div>
          <div v-for="i in sampleInfo?.disease_inheritance || []" :key="i">
            <v-chip small class="mr-2 mb-2 caption font-weight-medium grey lighten-3" label>{{ i }}</v-chip>
          </div>
        </div>
        <div class="d-flex flex-wrap">
          <div class="mr-2 font-weight-medium">Phenotypes and Terms:</div>
          <div v-for="(term, index) in terms.slice(0, 8)" :key="index">
            <v-chip small class="mr-2 mb-2 caption primary--text accent" label>{{ term }}</v-chip>
          </div>
          <v-tooltip v-if="terms.length > 8" bottom :open-delay="500">
            <template v-slot:activator="{ on, attrs }">
              <v-chip v-on="on" v-bind="attrs" class="mr-2 mb-2 text-body-2 primary--text accent" label>...</v-chip>
            </template>
            <div class="d-flex flex-wrap py-2" style="max-width: 800px">
              <div v-for="(term, index) in terms.slice(8)" :key="index">
                <v-chip small class="mr-2 mb-2 text-body-2 primary--text accent" label>{{ term }}</v-chip>
              </div>
            </div>
          </v-tooltip>
        </div>
      </v-card-text>
    </v-card>
    <v-card flat width="40%" class="ml-4" outlined>
      <v-card-title class="d-flex flex-row grey lighten-3 font-weight-medium pt-2" style="height: 50px !important">
        <div>
          <div>
            <v-icon class="mr-1">mdi-human-male-female-child</v-icon> Pedigree
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon x-small v-bind="attrs" v-on="on" class="mb-3">mdi-help-circle-outline </v-icon>
              </template>
              <div class="grey--text text--darken-3">
                <div class="d-flex flex-row">
                  <div class="pedigree-legend female affected"></div>
                  Female - Affected
                  <div class="pedigree-legend female unaffected ml-12"></div>
                  Female - Unaffected
                </div>
                <div class="d-flex flex-row">
                  <div class="pedigree-legend male affected"></div>
                  Male - Affected
                  <div class="pedigree-legend male unaffected ml-16"></div>
                  Male - Unffected
                </div>
                <div class="d-flex flex-row"></div>
                <span>* Use mouse scroll to zoom in/out and also mouse drag to move the pedigree</span>
              </div>
            </v-tooltip> -->
          </div>
        </div>
        <v-icon class="ml-auto" @click="isPinned = !isPinned">{{ isPinned ? "mdi-pin-off-outline" : "mdi-pin-outline" }}</v-icon>
      </v-card-title>
      <PatientPedigree :sampleInfo="sampleInfo" :cohortId="cohortId" :projectId="projectId"></PatientPedigree>
    </v-card>
  </div>
</template>
<script>
import { getPhenotypeHpo } from "@/api/gene/hpo.js";
// import { getData } from "@/api/user/dataset";
// import { getDatasetFields } from "@/api/dataset";
// import { getDatasetsByProject } from "@/api/dataset.js";
// import { getSample, getSampleByName } from "@/api/sample.js";

import PatientPedigree from "@/components/LucidAllAnalysis/PatientPedigree.vue";

// Vue.use(VueResource);
export default {
  name: "LucidAllAnalysisPatientInfo",
  components: { PatientPedigree },
  props: {
    sampleInfo: {
      type: Object,
      required: true,
    },
    cohortId: {
      type: Number,
      required: true,
    },
    projectId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    terms: [],
    isPinned: false,
    phenotypeMenu: false,
  }),
  computed: {
    fatherInfo() {
      return {
        sample_name: this.sampleInfo.father,
        status: this.sampleInfo.father_status?.name || null,
        color: this.getDiseaseColor(this.sampleInfo.father_status?.name || null),
        href: this.sampleInfo.fatherId != null ? this.$router.resolve({ name: "LucidAllAnalysisSampleVariants", params: { id: this.projectId, cohortid: this.cohortId, sampleid: this.sampleInfo.fatherId } }).href : null,
      };
    },
    motherInfo() {
      return {
        sample_name: this.sampleInfo?.mother,
        status: this.sampleInfo.mother_status?.name || null,
        color: this.getDiseaseColor(this.sampleInfo.mother_status?.name || null),
        href: this.sampleInfo.motherId != null ? this.$router.resolve({ name: "LucidAllAnalysisSampleVariants", params: { id: this.projectId, cohortid: this.cohortId, sampleid: this.sampleInfo.motherId } }).href : null,
      };
    },

    hpoTerms() {
      return this.sampleInfo.hpo_terms;
    },
  },
  watch: {
    hpoTerms() {
      this.fillTerms()
    },

  },
  created(){
    this.fillTerms()
  },
  methods: {

    fillTerms(){
      this.terms = [];
      this.hpoTerms.forEach((item, index) => {
        if (item.startsWith("HP:")) {
          this.terms.push("Loading...");
          this.setHpoTitle(item, index);
        } else {
          this.terms.push(item);
        }
      });

    },
    getDiseaseColor(status) {
      if (status == "affected") {
        return "red";
      } else {
        return "grey";
      }
    },
    setHpoTitle(hpoId, index) {
      const _this = this;
      getPhenotypeHpo(hpoId, (res) => {
        // console.log(res.name)
        this.$set(_this.terms, index, res.name);
      });
    },
  },
};
</script>
<style scoped>
.box {
  /* border-radius: 16px; */
  /* border: 1px solid #95d9e3; */
  /* background:  linear-gradient(90deg, rgba(3, 146, 168, 0.137) -59.56%, rgba(3, 146, 168, 0.055) 74.32%); */
  background: white;
  /* border-style: solid; */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.14) !important;
}
.chipColor {
  background-color: #7b9ebe !important;
}
.stickyPatientInfo {
  position: -webkit-sticky !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 10 !important;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.14) !important;
}

.linage {
  fill: none;
  stroke: #000;
}
.marriage {
  fill: none;
  stroke: black;
}
.man {
  background-color: lightblue;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  border-radius: 10px;
}
.woman {
  background-color: pink;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
}
.emphasis {
  font-style: italic;
}
svg {
  border-style: solid;
  border-width: 1px;
}

.pedigree-legend {
  width: 15px;
  height: 15px;
  border: 1px solid grey;
  margin-top: 3px;
  margin-right: 4px;
}

.affected {
  background-color: #001a55;
}

.unaffected {
  background-color: white;
}
.female {
  border-radius: 7px;
}
</style>