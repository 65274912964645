import ApiIntegrface from "../api"
import store from '@/store'
export function getCohorts(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts'
  api.get(url, null, callback)
}

export function getCohort(cohortId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId
  api.get(url, null, callback,null, null, null, false)
}

export function getDatasets(cohortId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets'
  api.get(url, null, callback)
}

export function getTargets(cohortId, datasetId, sampleId, type, inheritanceModes, strictOmimMatch, filters,  callback, loading=true){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets/' + datasetId + '/targets'
  const payload = {
    sampleId:sampleId,
    type: type,
    filters:filters,
    inheritance:inheritanceModes,
    strictOmimMatch:strictOmimMatch
  }
  api.post(url, payload, callback,null,null,null,loading)
}


export function getData(cohortId, datasetId, exactFilters, rangeFilters, inFilters, notFilters, column_groups, column_group_membership, itemsPerPage, pageNumber, sort, variantLabels,variantLabelstoExclude, columnTitles,collabLabels, callback, loading=true){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets/' + datasetId + '/search'
  const payload = {
    filters:{
      exactFilters: exactFilters,
      rangeFilters: rangeFilters,
      inFilters: inFilters,
      notFilters: notFilters
    },
    column_groups: column_groups,
    column_group_membership: column_group_membership,
    itemsPerPage:itemsPerPage,
    pageNumber:pageNumber,
    sort:sort,
    variantAnnotationLabels: variantLabels,
    variantLabelstoExclude: variantLabelstoExclude,
    columnTitles:columnTitles,
    collaborators_labels:collabLabels
  }
  // console.log(payload)
  api.post(url, payload, callback, null, null,null,loading)
}
export async function getData_sync(cohortId, datasetId, exactFilters, rangeFilters, inFilters, column_groups, column_group_membership, itemsPerPage, pageNumber, sort, variantLabels,variantLabelstoExclude,columnTitles, loading=true){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets/' + datasetId + '/search'
  const payload = {
    filters:{
      exactFilters: exactFilters,
      rangeFilters: rangeFilters,
      inFilters: inFilters,
    },
    exactFilters: exactFilters,
    rangeFilters: rangeFilters,
    inFilters: inFilters,
    column_groups: column_groups,
    column_group_membership: column_group_membership,
    itemsPerPage:itemsPerPage,
    pageNumber:pageNumber,
    sort:sort,
    variantAnnotationLabels: variantLabels,
    variantLabelstoExclude: variantLabelstoExclude,
    columnTitles:columnTitles
  }
  return await api.syncPost(url, payload,loading)
}

export function getAggregations(cohortId, datasetId, fields, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets/' + datasetId + '/aggregations'
  const payload = {
    fields: fields,
  }
  api.post(url, payload, callback, null, null)
}
export function getDistincts(cohortId, datasetId, fields, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets/' + datasetId + '/distincts'
  const payload = {
    fields: fields,
  }
  api.post(url, payload, callback, null, null)
}

export function updateVariantAnnotation(cohortId, datasetId, variantId, annotation, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets/' + datasetId + '/variants/' + variantId
  const payload = annotation
  api.patch(url, payload, callback, null, successMessage)
}

export function saveVariantAnalysisResultAnnotation(cohortId, datasetId,  variantId, payload, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets/' + datasetId + '/variants/' + variantId + '/analysis'
  api.put(url, payload, callback, null, null)
}

export function deleteVariantAnalysisResultAnnotation(cohortId, datasetId,  variantId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets/' + datasetId + '/variants/' + variantId + '/analysis'
  api.delete(url, null, callback, null, null)
}


export function downloadAnalysisResultReport(cohortId, datasetId,  sampleId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets/' + datasetId + '/report'
  const params = {
    sampleId: sampleId
  }
  api.get(url, params, callback, null, null)
}


export function addVariantNote(cohortId, datasetId, variantId, note, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets/' + datasetId + '/variantnotes'
  const payload = {
    variantId: variantId,
    note: note
  }
  api.post(url, payload, callback, null, successMessage)
}

export function getVariantNote(cohortId, datasetId, variantId, callback, errhandler){
  const api = new ApiIntegrface()
  const id = variantId + '-' + cohortId +'-'+ datasetId +'-' + store.state.UserId
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets/' + datasetId + '/variantnotes/' + id
  api.get(url, null, callback, errhandler, null)
}

export function getVariantTads(cohortId, datasetId, variantId, callback, errhandler){
  const api = new ApiIntegrface()
  // const id = variantId + '-' + cohortId +'-'+ datasetId +'-' + store.state.UserId
  const url = '/api/v1/cohorts/' + cohortId + '/datasets/' + datasetId + '/variants/' + variantId + '/coolbox'
  api.get(url, null, callback, errhandler, null)
}

export function getVariantModifiedTads(cohortId, datasetId, variantId, callback, errhandler){
  const api = new ApiIntegrface()
  const url = '/api/v1/cohorts/' + cohortId + '/datasets/' + datasetId + '/variants/' + variantId + '/coolbox/patient-regulome/'
  api.get(url, null, callback, errhandler, null)
}

export function getVariantMatcherCubanImage(variantId, callback, errhandler){
  const api = new ApiIntegrface()
  const url = '/api/v1/variant-matcher/variants/' + variantId + '/cuban'
  api.get(url, null, callback, errhandler, null, null, true, 'blob')
}

export function getCubanImage(cohortId, datasetId, variantId, callback, errhandler){
  const api = new ApiIntegrface()
  const url = '/api/v1/cohorts/' + cohortId + '/datasets/' + datasetId + '/variants/' + variantId + '/cuban'
  api.get(url, null, callback, errhandler, null, null, true, 'blob')

}


export function getDatasetSamples(cohortId, datasetId, callback, errhandler){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId +'/cohorts/' + cohortId + '/datasets/' + datasetId + '/samples'
  api.get(url, null, callback, errhandler, null)
}

export function getSamplePedigree(cohortId, projectId, sampleName, callback){
  const api = new ApiIntegrface()
  const url = `/api/v1/cohorts/${cohortId}/projects/${projectId}/samples/${sampleName}/pedigree`
  api.get(url, null, callback)
}

export function updateDatasetSampleStatus(cohortId, datasetId, sampleName, status, message=null){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId +'/cohorts/' + cohortId + '/datasets/' + datasetId + '/samples/'+sampleName
  const payload={
    status:status
  }
  api.put(url, payload, null, null, message)
}

export function updateDatasetSampleStatusAndDecision(cohortId, datasetId, sampleName, status, decision, message, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/users/' + store.state.UserId +'/cohorts/' + cohortId + '/datasets/' + datasetId + '/samples/'+sampleName
  const payload={
    status:status,
    decision:decision
  }
  api.put(url, payload, callback, null, message)
}

export function updateVariantNote(cohortId, datasetId, variantId, note, callback, successMessage){
  const api = new ApiIntegrface()
  const id = variantId + '-' + cohortId +'-'+ datasetId +'-' + store.state.UserId
  const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets/' + datasetId + '/variantnotes/' + id
  const payload = {
    variantId: variantId,
    note: note
  }
  api.put(url, payload, callback, null, successMessage)
}

