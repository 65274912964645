import ApiIntegrface from "./api"

export function getProjects(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/projects'
  api.get(url, null, callback, null, null, null)
}

export function getProject(projectId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/projects/' + projectId
  api.get(url, null, callback,null, null, null, false)
}

export function addProject(payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/projects'
  api.post(url, payload, callback, null, successMessage)
}

export function updateProject(payload, projectId,callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/projects/'+ projectId
  api.put(url, payload, callback, null, successMessage)
}

export function deleteProject(projectId, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/projects/' + projectId
  api.delete(url, null, callback, null, successMessage)
}

export function clearCache(projectId, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/projects/' + projectId + '/cache'
  api.delete(url, null, null, null, successMessage)
}