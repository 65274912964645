 <template>
  <div>
    <div class="d-flex flex-row">
      <v-text-field v-if="type == 'RANGE_BETWEEN' ||  type == 'RANGE_FROM'" dense solo class="pt-0 Filter" v-model="val[0]" hide-details :height="25" :label="minVal.toString()" > </v-text-field>
      <div v-else class="mr-1 ml-1 grey--text text--darken-1"> {{ minVal.toString() }} </div>
      <div class="grey--text text--darken-1 mx-1">~</div>

      <v-text-field v-if="type == 'RANGE_BETWEEN' ||  type == 'RANGE_TO'"  dense solo class="pt-0 flex-grow-1 Filter " v-model="val[1]" hide-details :height="25" :label="maxVal.toString()" > </v-text-field>
      <div v-else class="mr-1 ml-1  grey--text text--darken-1"> {{ maxVal.toString() }} </div>
    </div>
    <v-range-slider v-if="type == 'RANGE_BETWEEN'"
      class="mt-n1"
      thumb-label
      :thumb-color="changed ? 'primary' : '#bbbbbb'"
      :color="changed ? 'primary' : '#bbbbbb'"
      track-color="rgba(210, 243, 245, 1)"
      tick-size="1"
      dense
      hide-details
      @change="onChange()"
      v-model="sliderVal"
      :min="min"
      :step="step"
      :max="max"
    >
    <template v-slot:thumb-label="props">
        <span>{{ props.value }}</span>
      </template>
    </v-range-slider>


  <v-slider v-if="type == 'RANGE_FROM'"
      class="mt-n1"
      thumb-label
      :thumb-color="changed ? 'primary' : '#bbbbbb'"
      :color="changed ? '#0393A830' : '#bbbbbb'"
      :track-color="changed? 'primary': '#bbbbbb'"
      tick-size="1"
      dense
      hide-details
      @change="onChange()"
      v-model="sliderVal[0]"
      :min="min"
      :step="step"
      :max="max"
    >
    <template v-slot:thumb-label="props">
        <span>{{ props.value }}</span>
      </template>
    </v-slider>


    <v-slider v-if="type == 'RANGE_TO'"
      class="mt-n1"
      thumb-label
      :thumb-color="changed ? 'primary' : '#bbbbbb'"
      :color="changed ? 'primary' : '#bbbbbb'"
      track-color="rgba(210, 243, 245, 1)"
      tick-size="1"
      dense
      hide-details
      @change="onChange()"
      v-model="sliderVal[1]"
      :min="min"
      :step="step"
      :max="max"
    >
    <template v-slot:thumb-label="props">
        <span>{{ props.value }}</span>
      </template>
    </v-slider>
</div>

</template>
<script>
import { smartPruneNumber} from '@/utils/number'

export default {
  name: "RangeFilter",
  data: function () {
    return {
      val: [null, null],
      sliderVal: [null, null],
      changed: false,
    };
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    type:{
        type: String,
        required: true
    },
    datatype: {
      type: String,
      default: null,
    },
  },
  created() {
    // console.log(this.type, this.value)
    // if(Array.isArray(this.value)){
      
    // }
    this.sliderVal[0] = this.value[0] || this.minVal;
    this.sliderVal[1] = this.value[1] || this.maxVal;
    this.onChange();
  },
  watch: {
    value(){
        this.$set(this.sliderVal , 0, this.value[0] || this.minVal);
        this.$set(this.sliderVal , 1, this.value[1] || this.maxVal);
        this.onChange();
    },
    val() {
      if( this.val[0] == '-' || this.val[1] == '-' ){
        return
      }
      this.$set(this.sliderVal, 0, this.val[0] || this.minVal);
      this.$set(this.sliderVal, 1, this.val[1] || this.maxVal);

      if ((this.val[0] == this.minVal || this.val[0] == "" || this.val[0] == null) && (this.val[1] == this.maxVal || this.val[1] == "" || this.val[1] == null)) {
        this.changed = false;
      } else {
        this.changed = true;
      }
      this.$emit("input", this.val, this.type);
    },
  },
  computed: {
    minVal(){
      return smartPruneNumber(this.min)
    },
    maxVal(){
      return smartPruneNumber(this.max)
    },
    
    step() {
      const t = (this.maxVal - this.minVal) / 100;
      if(this.datatype == 'long' ){
        return  Math.max(Math.round(t), 1)
      } else {
        return smartPruneNumber(t)
      }
    },
  },
  methods: {
    onChange() {
      this.changed = false;
      if (this.sliderVal[0] !== this.minVal) {
        this.$set(this.val, 0, this.sliderVal[0]);
        this.changed = true;
      } else {
        this.$set(this.val, 0, null);
      }
      if (this.sliderVal[1] !== this.maxVal) {
        this.$set(this.val, 1, this.sliderVal[1]);
        this.changed = true;
      } else {
        this.$set(this.val, 1, null);
      }
    },
  },
};
</script>
<style  lang="scss" scoped>
::v-deep .v-slider__thumb {
  width: 8px !important;
  height: 8px !important;
}

::v-deep .v-slider__thumb::before {
  left: -5px !important;
  top: -5px !important;
  width: 18px;
  height: 18px;
  transform: scale(0.1);
}
.theme--dark ::v-deep .v-slider__track-fill:not(.primary) {
  background: #252525 !important;
}

::v-deep .v-input__slot {
  min-height: 26px !important;
  display: flex !important;
  align-items: center !important;
  padding-right: 0px !important;
  padding-left: 10px !important;
}
::v-deep .Filter.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.07) !important;
}

::v-deep .v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 26px !important;
}

::v-deep .v-label.theme--light {
  color: rgb(158, 158, 158) !important;
}


::v-deep .v-slider__thumb-label {
    color:grey !important;
    background-color: transparent !important;
    // background-color: grey !important;
    display: flex; 
    align-items: center;
    justify-content: center;
    margin-top: 10px !important;
    padding-top: 20px !important;
    font-size: 0.75rem !important;
    width: 32px !important;
    height: 10px !important;
    // border-radius: 50% 50% 0 !important;
    position: absolute !important;
    left: 0 !important;
    top: 0px !important;
    // bottom: 10px !important;
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    user-select: none !important;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) !important;
    transform: translateY(-20%) translateY(-12px) translateX(-50%) rotate(45deg);
    }
</style>