<template>
  <div>
    <div v-if="sampleInfo">
      <div class="d-flex justify-end" :class="nextSample != null ? 'mt-n12 mb-4' : ''">
        <v-tooltip :open-delay="300" bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <router-link v-if="nextSample != null" class="text-decoration-none" :to="{ name: 'LucidAllAnalysisSampleVariants', params: { id: projectId, cohortid: cohortId, sampleid: nextSample.id } }">
                <span class="text-uppercase">Next Sample</span> : {{ nextSample.sample_name }}<v-icon class="mb-1" color="primary">mdi-chevron-right</v-icon>
              </router-link>
            </div>
          </template>
          <span>{{ nextSampleFilters }}</span>
        </v-tooltip>
      </div>
      <IndividualAnalysisPatientInfo :class="nextSample != null ? 'mt-1' : ''" :projectId="projectId" :cohortId="cohortId" :sampleInfo="sampleInfo"></IndividualAnalysisPatientInfo>
      <div class="d-flex flex-row pt-10">
        <div class="mr-16 text-h5 font-weight-medium">
          <v-icon large class="mr-1" color="primary">mdi-target-account</v-icon>
          Highest Ranked Variants
        </div>

        <div class="ml-auto">
          <CardMenu title="Family Inheritance Mode" headerClass="pt-3 ml-1" headerIcon="mdi-source-fork" v-model="showHideInheritanceFilter" tooltip="Filter variants based on Inheritance Mode" width="350px" customClass="mx-0 px-0">
            <template v-slot:button>
              <v-btn text color="primary"> <v-icon class="mx-0 px-0">mdi-source-fork</v-icon> INHERITANCE MODE</v-btn>
            </template>
            <template v-slot:body>
              <v-list class="mt-n6 ml-4">
                <v-list-item dense v-for="item in Object.entries(inheritanceModes)" :key="item[0]" class="d-flex flex-row " style="min-height: 20px !important">
                  <v-checkbox dense v-model="localInheritanceModes" :label="item[1].name" color="primary" :value="item[0]" hide-details></v-checkbox>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
                <v-list-item dense >
                  <v-switch dense v-model="localStrictOmimMatch" class="mt-0" label="Strict OMIM Match" color="primary" hide-details></v-switch>
                </v-list-item>
              </v-list>
              <div class="d-flex flex-column">
                <v-btn small elevation="0" color="primary" class="mx-auto px-10 mb-2 mt-2" @click="onInheritanceChanged()"> Apply </v-btn>
                <!-- <div class="caption red--text" v-if="!localStrictOmimMatch && localInheritanceModes.length == 0">* Choose at lease one Inheritance Mode</div> -->
              </div>
            </template>
          </CardMenu>
        </div>
        <div class="ml-2">
          <v-menu offset-y min-width="380px" v-model="menu" :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" text color="primary" class="ml-auto" v-on="on"> <v-icon small>mdi-filter-cog-outline</v-icon> Variant Filters</v-btn>
            </template>
            <v-card style="width: 750px">
              <v-card-title class="d-flex flex-row">
                <div class="text-h6 font-weight-medium"><v-icon class="mb-1 mr-1" color="primary">mdi-filter-outline</v-icon>Filter</div>
                <v-btn icon class="ml-auto" @click="menu = false"><v-icon>mdi-close</v-icon></v-btn>
              </v-card-title>
              <v-card-text>
                <DefaultAnalysisFilters
                  v-if="svDs && snvDs && trDs"
                  :selectedDatabases="[svDs, snvDs, trDs]"
                  :cohortId="cohortId"
                  filterGroupName="INDIVIDUAL_ANALYSIS_FILTER"
                  @onFilterSet="onFilterSet"
                  searchButtonTitle="Apply"
                  searchButtonIcon=""
                  :saveFilters="true"
                  saveKeyName="IndividualAnalysis"
                  :showVariantLabelFilter="false"
                ></DefaultAnalysisFilters>
                <div class="caption mt-1"><b>Note:</b> If you don't choose any GT item, the default filter is ['1/1' ,'0/1' ,'./1' ,'1|1' ,'0|1' ,'.|1' ]</div>
              </v-card-text>
            </v-card>
          </v-menu>
        </div>
      </div>
      <div style="min-height: 10px">
        <div class="d-flex flex-row flex-wrap mt-2 body-2 ">
          <div class="grey--text text--darken-1 mr-1" v-if="filtersByTitle.length > 0">Custom Filters:</div>
          <v-chip v-for="item in filtersByTitle" :key="item.id" class="mr-1" color="primary lighten-1" rounded="0" label small outlined>
            <span v-html="item.fullText"></span>
          </v-chip>
          <div v-if="selectedInheritanceModes.length > 0" class="d-flex flex-row">
            <div class="grey--text text--darken-1 ml-1">Family Inheritance:</div>
            <v-chip class="ml-1" color="primary lighten-1" rounded="0" label small outlined>
              <span>OMIM Match: {{ selectedStrictOmimMatch ? "Strict" : "Relaxed" }}</span>
            </v-chip>
            <v-chip v-if="selectedInheritanceModes.length > 0" class="ml-1" color="primary lighten-1" rounded="0" label small outlined>
              <span>{{ selectedInheritanceModes.join(", ") }}</span>
            </v-chip>
          </div>
        </div>
      </div>
      <div v-for="tier in tiers" :key="tier.name">
        <div v-if="showVariants && snvDs && svDs && trDs" class="filterBox boxFrame mt-2 mb-2 px-6">
          <div class="text-h5 font-weight-medium d-flex flex-row header" @click="stepExpand[tier.name] = !stepExpand[tier.name]">
            <v-icon class="mr-1" color="primary">mdi-numeric-{{ tier.index }}-box-outline</v-icon>Tier {{ tier.index }}
            <div class="ml-auto mt-1 mr-2 d-flex flex-row">
              <HoverHint :text="`What are Tier ${tier.index} Targets?`" :title="`Tier ${tier.index} targets are the variants:`" :items="tierInfos[tier.name]"> </HoverHint>
              <HoverHint :text="`What are the predefined filters?`" :title="`The conditions applied are:`" :items="tierConditions[tier.name]"> </HoverHint>
            </div>
            <v-icon color="primary">{{ stepExpand[tier.name] ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
          </div>
          <v-expand-transition>
            <v-card v-if="stepExpand[tier.name]" class="ml-4 mt-4 d-flex flex-column" color="transparent" elevation="0">
              <v-tabs grow>
                <v-tab>SNV</v-tab>
                <v-tab>SV</v-tab>
                <v-tab>TR</v-tab>
                <v-tab-item class="pt-3">
                  <IndividualAnalysisViewVariants
                    title=""
                    :inheritanceModes="selectedInheritanceModes"
                    :filters="filters.SNV"
                    :targetType="tier.targetType"
                    :selectedDatabaseId="snvDs.id"
                    databaseType="SNV"
                    :cohortId="cohortId"
                    :sampleInfo="sampleInfo"
                    :strictOmimMatch="selectedStrictOmimMatch"
                  >
                  </IndividualAnalysisViewVariants>
                </v-tab-item>
                <v-tab-item class="pt-3">
                  <IndividualAnalysisViewVariants title="" :strictOmimMatch="selectedStrictOmimMatch" :inheritanceModes="selectedInheritanceModes" :filters="filters.SV" :targetType="tier.targetType" :selectedDatabaseId="svDs.id" databaseType="SV" :cohortId="cohortId" :sampleInfo="sampleInfo">
                  </IndividualAnalysisViewVariants>
                </v-tab-item>
                <v-tab-item class="pt-3">
                  <IndividualAnalysisViewVariants title="" :strictOmimMatch="selectedStrictOmimMatch" :inheritanceModes="selectedInheritanceModes" :filters="filters.TR" :targetType="tier.targetType" :selectedDatabaseId="trDs.id" databaseType="TR" :cohortId="cohortId" :sampleInfo="sampleInfo">
                  </IndividualAnalysisViewVariants>
                </v-tab-item>
              </v-tabs>

              <div class="mx-auto">
                <v-btn v-if="tier.index == 1" elevation="0" color="primary" class="mx-auto px-10" @click="onShowTier2()"> Next : Tier 2 Highest Ranked Variants <v-icon class="ml-2">mdi-chevron-right</v-icon> </v-btn>
                <v-btn v-else elevation="0" color="primary" class="mx-auto px-10" @click="onShowAllOverView()"> Overview <v-icon class="ml-2">mdi-check-all</v-icon> </v-btn>
              </div>
            </v-card>
          </v-expand-transition>
        </div>
      </div>

      <div v-if="showVariants" class="filterBox boxFrame mt-6 px-6">
        <div class="text-h5 font-weight-medium d-flex flex-row header" @click="stepExpand['overViewAllTargets'] = !stepExpand['overViewAllTargets']">
          <v-icon class="mb-1 mr-1" color="primary">mdi-file-document-check-outline</v-icon>All Tiers - Overview
          <!-- <v-btn class="ml-auto" icon @click="stepExpand['overView'] = !stepExpand['overView']"> -->
          <v-icon class="ml-auto" color="primary">{{ stepExpand["overViewAllTargets"] ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
          <!-- </v-btn> -->
        </div>

        <v-expand-transition>
          <v-card v-if="stepExpand['overViewAllTargets']" class="ml-4 mt-6 d-flex flex-column" color="transparent" elevation="0">
            <div class="mb-2 font-weight-medium">
              <v-icon color="primary" small>mdi-tag-check-outline</v-icon> SNVs and SVs Marked as <span class="green--text">KEEP</span>, <span class="grey--text text--darken-1">DON'T KNOW</span>, or
              <span class="orange--text text--lighten-1">VUS</span>
            </div>
            <IndividualAnalysisViewVariants title="Labeled" :strictOmimMatch="selectedStrictOmimMatch" :inheritanceModes="selectedInheritanceModes" targetType="ALL" :selectedDatabaseId="snvDs.id" :removeDiscards="true" databaseType="SNV" :cohortId="cohortId" :sampleInfo="sampleInfo">
            </IndividualAnalysisViewVariants>
            <IndividualAnalysisViewVariants title="Labeled" :strictOmimMatch="selectedStrictOmimMatch" :inheritanceModes="selectedInheritanceModes" targetType="ALL" :selectedDatabaseId="svDs.id" :removeDiscards="true" databaseType="SV" :cohortId="cohortId" :sampleInfo="sampleInfo">
            </IndividualAnalysisViewVariants>
            <IndividualAnalysisViewVariants title="Labeled" :strictOmimMatch="selectedStrictOmimMatch" :inheritanceModes="selectedInheritanceModes" targetType="ALL" :selectedDatabaseId="trDs.id" :removeDiscards="true" databaseType="TR" :cohortId="cohortId" :sampleInfo="sampleInfo">
            </IndividualAnalysisViewVariants>

            <div>
              <IndividualAnalysisActions :datasets="datasets" :cohortId="cohortId" :sampleInfo="sampleInfo"></IndividualAnalysisActions>
            </div>
          </v-card>
        </v-expand-transition>
      </div>
    </div>
  </div>
</template>
  <script>
import IndividualAnalysisViewVariants from "@/components/IndividualAnalysis/IndividualAnalysisViewVariants.vue";
import IndividualAnalysisActions from "@/components/IndividualAnalysis/IndividualAnalysisActions.vue";
import IndividualAnalysisPatientInfo from "@/components/IndividualAnalysis/IndividualAnalysisPatientInfo.vue";
import HoverHint from "@/components/HoverHint.vue";
import DefaultAnalysisFilters from "@/components/defaultAnalysis/DefaultAnalysisFilters.vue";
import CardMenu from "@/components/Common/CardMenu.vue";
import loadSampleList from "@/mixins/lucidAllAnalysis/loadSampleList.js";
import { get_user_pref } from "@/utils/userPreferences.js";
import { getDatasetsByProject } from "@/api/dataset.js";
import { getSample, getSampleByName } from "@/api/sample.js";

export default {
  name: "LucidAllAnalysisSampleVariants",
  components: { IndividualAnalysisViewVariants, HoverHint, IndividualAnalysisActions, DefaultAnalysisFilters, IndividualAnalysisPatientInfo, CardMenu },
  mixins: [loadSampleList],

  data: () => ({
    showHideInheritanceFilter: false,
    inheritanceModes: {
      AR: { name: "Autosomal Recessive", show: true },
      AD: { name: "Autosomal Dominant", show: true },
      XLR: { name: "X-Linked Recessive", show: true },
      XLD: { name: "X-Linked Dominant", show: true },
      YL: { name: "Y-Linked", show: true },
    },
    localStrictOmimMatch: true,
    selectedStrictOmimMatch: true,
    selectedInheritanceModes: [],
    localInheritanceModes: [],
    tier1SnvInfos: ["Hitting the exon of HPO genes", "Hitting the exon of genes sent from users"],
    tier1CommonConditions: ["GT in ['1/1' ,'0/1' ,'./1' ,'1|1' ,'0|1' ,'.|1' ]", "COHORT AC ≤ min(8, 10% Cohort Size) "],
    tier1SnvConditions: ["QUAL ≥ 50", "GNOMADG_AF ≤ 0.01", "GENE_GROUP contains at least one gene ", 'Excluding "benign", "likely_benign", "benign/likely_benign" CLINVAR variants', "Top variants ranked by AlphaMissense, MMSplice, Nonsense-CADD"],
    tier1SvInfos: ["Hitting the exon of HPO genes", "Hitting the exon of genes sent from users", "Hitting TAD boundaries candidate genes"],
    tier1SvConditions: ["QUAL ≥ 0.4", "Not present in PUBLIC_DB", "BACKGROUND_AC ≤ 0 ", "Top variants ranked by LUCID coding score"],

    tier2CommonConditions: ["GT in ['1/1' ,'0/1' ,'./1' ,'1|1' ,'0|1' ,'.|1' ]", "COHORT AC ≤ min(8, 10% Cohort Size) "],

    tier2SnvInfos: ["Hitting the exon of HPO genes", "Hitting the exon of genes sent from users", "Hitting TAD boundaries candidate genes"],
    tier2SnvConditions: ["QUAL ≥ 50", "GNOMADG_AF ≤ 0.01", "GENE_GROUP does not contain any genes", 'Excluding "benign", "likely_benign", "benign/likely_benign" CLINVAR variants', "Top variants ranked by AlphaMissense, MMSplice, Nonsense-CADD"],
    tier2SvInfos: ["Hitting the exon of HPO genes", "Hitting the exon of genes sent from users", "Hitting TAD boundaries candidate genes"],
    tier2SvConditions: ["QUAL ≥ 0.4", "Not present in PUBLIC_DB", "BACKGROUND_AC ≤ 0", "Top variants ranked by LUCID Non-Coding score and TADA Score"],
    menu: false,
    tiers: [
      {
        name: "tier1",
        index: 1,
        targetType: "EASY",
      },
      {
        name: "tier2",
        index: 2,
        targetType: "REASONABLE",
      },
    ],
    stepExpand: {
      tier1: true,
      tier2: false,
      overView: false,
      overViewAllTargets: false,
      filters: true,
    },
    tab: null,
    datasets: [],
    cohortId: null,
    projectId: null,
    sampleInfo: null,
    filters: {
      SV: {
        exactFilters: [],
        inFilters: [],
        notFilters: [],
        rangeFilters: [],
      },
      SNV: {
        exactFilters: [],
        inFilters: [],
        notFilters: [],
        rangeFilters: [],
      },
      TR: {
        exactFilters: [],
        inFilters: [],
        notFilters: [],
        rangeFilters: [],
      },
    },

    filtersByTitle: [],

    sampleId: null,
    showVariants: false,
    sampleAppliedFilters: null,
  }),
  created() {
    this.cohortId = Number(this.$route.params.cohortid);
    this.sampleId = Number(this.$route.params.sampleid);
    this.projectId = Number(this.$route.params.id);

    this.loadSampleInfo();

    this.loadSamples(this.projectId, this.cohortId);
    const _this = this;
    const pref = this.$store.state.UserPreferences;
    let filters = null;
    let filtersByTitle = null;
    if ((pref && pref.IndividualAnalysis && (pref.IndividualAnalysis[this.projectId] || {}).filtersLabels) || null) {
      filtersByTitle = pref.IndividualAnalysis[this.projectId].filtersLabels;
      filters = pref.IndividualAnalysis[this.projectId]?.filtersPerDs || null;
    }

    getDatasetsByProject(this.projectId, (res) => {
      _this.datasets = res.filter((item) => item.recordCount > 0).sort((a, b) => b.datasetType.id - a.datasetType.id);
      if (filters) {
        _this.onFilterSet(filters, null, filtersByTitle);
      } else {
        this.showVariants = true;
      }
    });
    this.sampleAppliedFilters = get_user_pref("IndividualAnalysisSamplesFilters");
  },
  computed: {
    nextSampleFilters() {
      return "Next Filtered Sample";
    },
    nextSample() {
      let idx = this.samples.findIndex((item) => item.id == this.sampleId);
      // console.log(">>>", idx, this.samples,this.sampleAppliedFilters );
      let v = null;
      if (idx >= 0) {
        if (this.sampleAppliedFilters) {
          const nextList = this.samples.slice(idx + 1);
          const s = nextList.find(
            (item) =>
              (!item.status?.decision?.id?.toString() || this.sampleAppliedFilters.filteredDecisions.includes(item.status?.decision?.id?.toString())) &&
              this.sampleAppliedFilters.selectedDiseaseStatuses.includes(item.disease_status.name) &&
              this.sampleAppliedFilters.filteredStatuses.includes(item.status.status)
          );
          if (s) {
            v = s;
          }
        } else {
          idx++;
          if (idx < this.samples.length) {
            v = this.samples[idx];
          }
        }
      }
      return v;
    },
    tierInfos() {
      return {
        tier1: this.tier1Infos,
        tier2: this.tier2Infos,
      };
    },
    tierConditions() {
      return {
        tier1: this.tier1Conditions,
        tier2: this.tier2Conditions,
      };
    },
    tier1Infos() {
      let x = this.tier1SnvInfos.map((item) => "SNVs " + item);
      return x.concat(this.tier1SvInfos.map((item) => "SVs " + item));
    },
    tier1Conditions() {
      let x = this.tier1CommonConditions;
      x = x.concat(this.tier1SnvConditions.map((item) => "SNV " + item));
      x = x.concat(this.tier1SvConditions.map((item) => "SV  " + item));
      return x;
    },

    tier2Infos() {
      let x = this.tier2SnvInfos.map((item) => "SNVs " + item);
      return x.concat(this.tier2SvInfos.map((item) => "SVs " + item));
    },
    tier2Conditions() {
      let x = this.tier2CommonConditions;
      x = x.concat(this.tier2SnvConditions.map((item) => "SNV " + item));
      x = x.concat(this.tier2SvConditions.map((item) => "SV  " + item));
      return x;
    },
    svDs() {
      return this.datasets.find((item) => item.datasetType.name == "SV") || null;
    },
    snvDs() {
      return this.datasets.find((item) => item.datasetType.name == "SNV") || null;
    },
    trDs() {
      return this.datasets.find((item) => item.datasetType.name == "TR") || null;
    },
  },
  methods: {
    loadSampleInfo() {
      const _this = this;
      getSample(this.cohortId, this.projectId, this.sampleId, (res) => {
        _this.sampleInfo = res;
        if (_this.sampleInfo.father) {
          getSampleByName(this.cohortId, this.projectId, _this.sampleInfo.father, (res) => {
            if (res.length > 0) {
              _this.sampleInfo.fatherId = res[0].id;
            }
          });
        }
        if (_this.sampleInfo.mother) {
          getSampleByName(this.cohortId, this.projectId, _this.sampleInfo.mother, (res) => {
            if (res.length > 0) {
              _this.sampleInfo.motherId = res[0].id;
            }
          });
        }
      });
    },
    onInheritanceChanged() {
      this.selectedInheritanceModes = this.localInheritanceModes;
      this.selectedStrictOmimMatch = this.localStrictOmimMatch;
      this.showHideInheritanceFilter = false;
      Object.keys(this.stepExpand).forEach((step) => {
        this.stepExpand[step] = false;
      });
      this.$nextTick(() => {
        this.showVariants = true;
        this.stepExpand.tier1 = true;
      });
    },
    onShowTier(tier) {
      this.stepExpand[tier] = true;
    },
    onShowTier2() {
      this.stepExpand.tier2 = true;
      this.stepExpand.tier1 = false;
    },
    // onShowHieghestRankedSvs() {
    //   this.stepExpand.easyTargetsSV = true;
    //   this.stepExpand.easyTargetsSNV = false;
    // },
    // onShowOverView() {
    //   this.stepExpand.easyTargetsSV = false;
    //   this.stepExpand.easyTargetsSNV = false;
    //   this.stepExpand.overView = true;
    // },
    // onShowReasonableTargetSNVs() {
    //   this.stepExpand.reasonableTargetsSNV = true;
    //   this.stepExpand.easyTargetsSV = false;
    //   this.stepExpand.overView = false;
    // },
    // onShowReasonableOverView() {
    //   this.stepExpand.reasonableTargetsSV = false;
    //   this.stepExpand.reasonableTargetsSNV = false;
    //   this.stepExpand.overViewReasonable = true;
    // },
    // onShowHieghestRankedReasonableSvs() {
    //   this.stepExpand.reasonableTargetsSV = true;
    //   this.stepExpand.reasonableTargetsSNV = false;
    // },
    onShowAllOverView() {
      this.stepExpand.tier1 = false;
      this.stepExpand.tier2 = false;
      this.stepExpand.overViewAllTargets = true;
    },
    onFilterSet(filtersPerDs, variantLabelsPerDs, selectedPropertiesLabels) {
      Object.keys(this.filters).forEach((dsType) => {
        this.filters[dsType].exactFilters = filtersPerDs[dsType].exactFilters;
        this.filters[dsType].inFilters = filtersPerDs[dsType].inFilters;
        this.filters[dsType].notFilters = filtersPerDs[dsType].notFilters;
        this.filters[dsType].rangeFilters = [...filtersPerDs[dsType].rangeFromfilters, ...filtersPerDs[dsType].rangeTofilters, ...filtersPerDs[dsType].rangeBetweenfilters];
      });
      this.filtersByTitle = selectedPropertiesLabels;
      this.menu = false;
      Object.keys(this.stepExpand).forEach((step) => {
        this.stepExpand[step] = false;
      });
      this.$nextTick(() => {
        this.showVariants = true;
        this.stepExpand.tier1 = true;
      });
    },
  },
};
</script>
<style scoped lang="scss">
.filterBox {
  // background: #e1fcff62 !important;
  background: #9aacad13 !important;
}
.tabHeaders {
  background-color: white !important;
  border-radius: 6px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05) !important;
}
.header {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
  border-radius: 6px;
}
.header:hover {
  cursor: pointer;
  // box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05) !important;
  background-color: #55555508 !important;
}
</style>