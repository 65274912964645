<template>
  <div class="pa-12">
    <div class="d-flex flex-row">
      <div class="text-h4 font-weight-bold mb-4" style="width: 380px">
        <icon xLarge color="primary" id="machine-learning-model"></icon>
        {{ $route.meta.routeTitle }}
      </div>
      <div class="mt-2 text-h6 grey--text text--darken-2">
        <span> <router-link :to="{ name: 'UserCohorts' }" style="text-decoration: none !important"> Cohorts </router-link> > </span>
        <span v-if="$route.name == 'LucidAllAnalysisCohortOverview'">{{ cohortName }} - {{ projectName }} > Overview</span>
        <span v-else>
          <router-link :to="{ name: 'LucidAllAnalysisCohortOverview', params: { id: projectId, cohortid: cohortId } }" style="text-decoration: none !important"> {{ cohortName }} - {{ projectName }} </router-link>
        </span>

        <span v-if="$route.name == 'LucidAllAnalysisSamples' && sampleId == null "> > Samples</span>
        <span v-if="sampleId != null"> >
          
          <router-link :to="{ name: 'LucidAllAnalysisSamples', params: { id: projectId, cohortid: cohortId } }" style="text-decoration: none !important"> Samples</router-link> 
          {{ " > " + sampleName }} 
        
        </span>
        <span v-if="$route.name == 'LucidAllCohortAnalysis'"> > Cohort Analysis</span>
        <span v-if="$route.name == 'DefaultAnalysis'"> > Explorer</span>
      </div>
    </div>

    <router-view :key="$route.path"></router-view>
  </div>
</template>
<script>
// import { getCohort } from "@/api/cohort.js";
import { getCohort } from "@/api/user/dataset.js";
import { getDatasetsByProject } from "@/api/dataset.js";
import { getSample } from "@/api/sample.js";
export default {
  name: "LucidAllAnalysis",

  data: () => ({
    // sampleId: null,
    cohortId: null,
    projectId: null,
    cohortName: null,
    projectName: null,
    sampleName: null,
  }),
  created() {
    console.log("this.$route.params", this.$route.params);

    this.projectId = Number(this.$route.params.id);
    // this.sampleId = Number(this.$route.params.sampleid);
    this.cohortId = Number(this.$route.params.cohortid);
    const _this = this;
    getCohort(this.cohortId, (res) => {
      _this.cohortName = res.name;
    });
    getDatasetsByProject(this.projectId, (res) => {
      _this.projectName = res[0]?.sample.name || null;
    });
    this.loadSampleInfo();
  },
  methods: {
    loadSampleInfo() {
      const _this = this;
      if (this.sampleId != null) {
        getSample(this.cohortId, this.projectId, this.sampleId, (res) => {
          _this.sampleName = res.sample_name;
          _this.projectName = res.project_id.name;
        });
      } else {
        this.sampleName = null
      }
    },
  },
  computed: {
    sampleId() {
      if(this.$route.params.sampleid != undefined){
        return Number(this.$route.params.sampleid);
      } else {
        return null
      }
    },
  },
  watch: {
    sampleId() {
      this.loadSampleInfo();
    },
  },
};
</script>