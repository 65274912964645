<template>
  <div>
    <v-menu v-if="compact" open-on-hover :open-delay="300" offset-y left :max-width="width" :min-width="width" v-model="menu" :close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-chip v-bind="attrs" v-on="on" class="mt-1 pr-2" label color="primary" small outlined>{{ id }}</v-chip>
      </template>
      <v-card>
        <v-card-title class="grey lighten-2 d-flex flex-row">
          <div class="mr-auto">
            {{ id }}
          </div>
          <div class="text-body-1 d-flex flex-row">
            <div>
              <a v-if="info.omim" :href="omimUrl" target="_black"> OMIM <v-icon x-small class="mb-2" color="primary">mdi-open-in-new</v-icon> </a>
              <span v-else class="grey--text">OMIM</span>
            </div>
            <div class="ml-4">
              <a :href="proteinAtlasUrl" target="_black"> Protein Atlas <v-icon x-small class="mb-2" color="primary">mdi-open-in-new</v-icon> </a>
            </div>
            <div class="ml-4">
              <a v-if="uniprotId" :href="uniprotUrl" target="_black"> Uniprot <v-icon x-small class="mb-2" color="primary">mdi-open-in-new</v-icon> </a>
              <span v-else class="grey--text">Uniprot</span>
            </div>
          </div>
        </v-card-title>
        <v-card-text class="grey lighten-4 text-darken-1">{{ info.description }}</v-card-text>
      </v-card>
    </v-menu>
    <div v-else>
      <div class="grey--text text--darken-2">{{ info.description }}</div>
      <div class="text-body-1 d-flex flex-row mt-2">
        <div  class="ml-auto">
          <a v-if="info.omim" :href="omimUrl" target="_black"> OMIM <v-icon x-small class="mb-2" color="primary">mdi-open-in-new</v-icon> </a>
          <span v-else class="grey--text">OMIM</span>
        </div>
        <div class="ml-4">
          <a :href="proteinAtlasUrl" target="_black"> Protein Atlas <v-icon x-small class="mb-2" color="primary">mdi-open-in-new</v-icon> </a>
        </div>
        <div class="ml-4">
          <a v-if="uniprotId" :href="uniprotUrl" target="_black"> Uniprot <v-icon x-small class="mb-2" color="primary">mdi-open-in-new</v-icon> </a>
          <span v-else class="grey--text">Uniprot</span>
        </div>
      </div>

    </div>
  </div>
</template>
<script>
import { getGene } from "@/api/gene/gene.js";
import { searchUniprotByGene } from "@/api/gene/uniprot.js";

export default {
  name: "GeneInfo",
  props: {
    id: {
      default: null,
      type: String,
    },
    compact: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    menu: false,
    info: {
      id: null,
      description: "Loading gene description...",
      omim: null,
    },
    uniprotId: null,
  }),
  watch: {
    menu() {
      if (this.info.id == null) {
        this.loadGeneInfo();
      }
      // console.log(newVal);
    },
    id(){
      if (this.compact == false) {
        this.loadGeneInfo();
      }

    }
  },
  created(){
    if(!this.compact ){
      this.loadGeneInfo();
    }
  },
  computed: {
    geneName(){
      return this.info.id
    },
    proteinAtlasUrl() {
      return `https://www.proteinatlas.org/search/gene_name:${this.id}`;
    },
    uniprotUrl() {
      return `https://www.uniprot.org/uniprotkb/${this.uniprotId}/feature-viewer`;
    },
    omimUrl() {
      if (this.info.omim) {
        return `https://www.omim.org/entry/${this.info.omim}?search=${this.id}&highlight=${this.id}`;
      } else {
        return null;
      }
    },
    width() {
      let w = 100;
      if (this.info.description) {
        w = Math.min(Math.max(this.info.description.length, 600), 800);
      } else {
        w = 600;
      }
      return `${w}px`;
    },
  },
  methods: {
    loadGeneInfo() {
      const _this = this;
      getGene(
        this.id,
        (res) => {
          _this.info.id = res.id;
          _this.info.description = res.description ? res.description : "No information found for the gene!";
          _this.info.omim = res.omim ? res.omim : null;
        },
        (err) => {
          if (err.status == 404) {
            _this.info.description = "No information found for the gene!";
          } else {
            _this.$root.notify.show({ message: "An error has occured! Sorry for having troubled you :( contact support", type: "error" });
          }
        },
        false
      );
      searchUniprotByGene(this.id, (res) => {
        // console.log(res.results)
        if (res.results.length > 0) {
          _this.uniprotId = res.results[0].primaryAccession;
        } else {
          _this.uniprotId = null;
        }
      });
    },
  },
};
</script>
<style scoped>
.infoIcon {
  border: dashed 1px rgb(175, 174, 174) !important;

  width: 17px;
  height: 17px;
}
</style>