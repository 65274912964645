<template>
  <div v-if="!hidden">
    <v-rect :config="isoformRectConfig"></v-rect>
    <v-text :config="textConfig"></v-text>
    <HighlightBar :startX="svHighlightInfo.start" :width="svHighlightInfo.width" :height="fiskboneTrackHeight" :baseLineY="baseLineY"></HighlightBar>
    <HighlightBar :startX="secondaryHighlightStart" :width="secondaryHighlightWidth" :height="fiskboneTrackHeight" :baseLineY="baseLineY"></HighlightBar>

    <Isoform :showName="showName" :sv="sv" :tadInfo="tadInfo" v-for="g in linedIsoforms.isoforms" :key="g.id" :spec="g" :baseLineY="baseLineY + g.line * isoformLineHeight + isoformTrackPaddingTop"></Isoform>
    <TrackSeparator :tadInfo="tadInfo" :y="baseLineY -1"></TrackSeparator>
  </div>
</template>
<script>
import Vue from "vue";
import VueKonva from "vue-konva";
import Isoform from "@/components/defaultAnalysis/tad/Transcripts/Isoform.vue";
import { toScatedX } from "@/utils/tad.js";
import TrackSeparator from "@/components/defaultAnalysis/tad/TrackSeparator.vue";
import HighlightBar from "@/components/defaultAnalysis/tad/HighlightBar.vue";
import { getTextSizeInPixel } from "@/utils/textUtils.js";

Vue.use(VueKonva);
export default {
  name: "FishboneTrack",
  components: { Isoform, HighlightBar , TrackSeparator},
  props: {
    tadInfo: {
      type: Object,
      required: true,
    },
    isoforms: {
      type: Array,
      default: () => [],
    },
    baseLineY: {
      type: Number,
      default: 500,
    },
    sv: {
      type: Object,
      default: () => ({}),
    },
    svHighlightInfo: {
      type: Object,
      required: true,
    },
    showName:{
      type: Boolean,
            default: false
    },
    hidden:{
      type: Boolean,
      default: false
    },
  },
  data: () => ({
    isoformLineHeight: 20,
    isoformTrackPaddingTop: 25,
    secondaryHightlightInfo: {
      start: 0,
      end: 0,
      widthInPx: 0,
    },
    showSecondaryHighlight: false,
  }),
  methods: {
    toX(num) {
      return toScatedX(num, this.tadInfo.min, this.tadInfo.scale);
    },
    onVariantMouseEnter(prm){
      this.secondaryHightlightInfo  = prm
      this.showSecondaryHighlight = true
    },
    onVariantMouseLeave(){
      this.showSecondaryHighlight = false
    },
    getRealStartEnd(g){
      let x = {}
      if(g.direction == '+'){
        x['xStart'] =  this.toX(g.start) - getTextSizeInPixel(this,  (g?.isoform_id || g.name), "normal 9px Roboto")
        x['xEnd'] =  this.toX(g.end)
      } else {
        x['xStart'] =  this.toX(g.start)
        x['xEnd'] =  this.toX(g.end) + getTextSizeInPixel(this,  (g?.isoform_id || g.name), "normal 9px Roboto")
      }
      return x
    }
  },
  mounted(){
    this.$eventBus.$on('onVariantMouseEnter',(prm)=>{ this.onVariantMouseEnter(prm)})
    this.$eventBus.$on('onVariantMouseLeave',(prm)=>{ this.onVariantMouseLeave(prm)})
  },
  watch: {
    linedIsoforms: {
      handler: function () {
        this.$emit("onFishboneTrackHeight", this.fiskboneTrackHeight);
      },
      deep: true,
    },
    fiskboneTrackHeight(val){
        // console.log('this====', val)
        this.$emit("onFishboneTrackHeight", val);
    },


  },
  computed: {
    secondaryHighlightStart(){
      return this.toX(this.secondaryHightlightInfo.start)
    },
    secondaryHighlightWidth(){
      return this.showSecondaryHighlight? this.secondaryHightlightInfo.widthInPx : 0
    },
    textConfig() {
      return {
        x: 4,
        y: this.baseLineY + 3,
        text: "Ensemble Transcripts",
        width: 300,
        fontSize: 13,
        fontStyle: "bold",
        fill: "#78a889",
      };
    },
    linedIsoforms() {
      const lines = [];
      let gn = [...this.isoforms].map(item => {
        let newItem = item
        const loc = this.getRealStartEnd(item)
        this.$set(newItem, 'xStart', loc.xStart)
        this.$set(newItem, 'xEnd', loc.xEnd)
        return newItem
      });
      gn.sort((a, b) => a.xStart - b.xStart).forEach((g) => {
        let added = false;
        for (let i = 0; i < lines.length; i++) {
          if (lines[i].findIndex((l) =>  g.xStart - 25 < l.xEnd) < 0) {
            lines[i].push(g);
            g["line"] = i;
            added = true;
            break;
          }
        }
        if (!added) {
          lines.push([g]);
          g["line"] = lines.length - 1;
        }
      });
      return { isoforms: gn, lines: lines };
    },
    start() {
      return this.tadInfo.min;
    },
    end() {
      return this.tadInfo.max;
    },
    fiskboneTrackHeight() {
      if(this.hidden){
        return 0
      }
      return this.linedIsoforms.lines.length * this.isoformLineHeight  + this.isoformTrackPaddingTop;
    },
    isoformRectConfig() {
      return { x: this.toX(this.start), y: this.baseLineY, width: this.toX(this.end) - this.toX(this.start), height: this.fiskboneTrackHeight, strokeWidth: 0, fill: "#AFE7C830" };
    },
  },
};
</script>