import ApiIntegrface from "./api"

export function getDatasets(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets'
  api.get(url, null, callback, null, null, null)
}

export function getDatasetsByProject(projectId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets?sampleid='+projectId
  api.get(url, null, callback, null, null, null)
}

export function getSampleDecisions(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/sample-decisions'
  api.get(url, null,callback, null)
}

export function getDataset(datasetId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId
  api.get(url, null, callback,null, null, null, false)
}


export function getDatasetTypes(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasettypes'
  api.get(url, null, callback, null)
}

export function addDataset(payload, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets'
  api.post(url, payload, callback, null, successMessage)
}

export function updateDataset(payload, datasetId, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/'+ datasetId
  api.put(url, payload, null, null, successMessage)
}


export function updateDatasetStatus(datasetId, payload, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId + "/status"
  api.put(url, payload, callback, null)
}

export function postprocessDataset(datasetId, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId + "/postprocess"
  api.put(url, null, null, null, successMessage)
}

export function deleteDataset(datasetId, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId
  api.delete(url, null, callback, null, successMessage)
}

export function getDatasetFields(datasetId, callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId + "/fields"
  api.get(url, null,callback, null)
}

export function getDatasetFieldsByGroup(datasetId, groups, callback){
  const api = new ApiIntegrface()
  const params = {
    groups : groups.toString()
  }
  const url = '/api/v1/datasets/' + datasetId + "/fields"
  api.get(url, params,callback, null)
}
export function getDatasetFieldsByOneOfGroups(datasetId, groups, callback){
  const api = new ApiIntegrface()
  const params = {
    hasoneofgroups : groups.toString()
  }
  const url = '/api/v1/datasets/' + datasetId + "/fields"
  api.get(url, params,callback, null)
}

export function addDatasetFieldsToGroup(datasetId,payload,  callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId + "/fields/groups"
  api.put(url, payload, callback, null, successMessage)
}

export function removeDatasetFieldsFromGroup(datasetId,payload,  callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId + "/fields/groups"
  api.delete(url, payload, callback, null, successMessage)
}

export function updateDatasetFieldOrder(datasetId, payload, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/datasets/' + datasetId + '/fields/order'
  api.put(url, payload, null, null, successMessage)
}

export function getCohorts(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/cohorts'
  api.get(url, null,callback, null)
}

export function getTemplates(callback){
  const api = new ApiIntegrface()
  const url = '/api/v1/fieldgrouptemplates'
  api.get(url, null, callback, null)
}

export function addTemplate(title, callback, successMessage){

  const api = new ApiIntegrface()
  const url = '/api/v1/fieldgrouptemplates'
  const payload = {
    title: title
  }
  api.post(url, payload, callback, null, successMessage )
}

export function deleteTemplate(id, callback, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/fieldgrouptemplates/' + id
  api.delete(url, null, callback, null, successMessage)
}

export function fillTemplate(id, payload, successMessage){
  const api = new ApiIntegrface()
  const url = '/api/v1/fieldgrouptemplates/' + id + '/columns/bulk'
  api.put(url, payload, null,null, successMessage )
}

export function applyTemplate(datasetId, templateId, callback, successMessage){
  const api = new ApiIntegrface()
  const url = `/api/v1/datasets/${datasetId}/fields/template`
  const payload = {
    templateId: templateId
  }
  api.put(url, payload, callback,null, successMessage )
}
// export function getVariantNotes(datasetId, noteId, callback){
//   const api = new ApiIntegrface()
//   const url = '/api/v1/users/' + store.state.UserId + '/cohorts/' + cohortId + '/datasets/' + datasetId + '/distincts'
//   api.post(url, payload, callback, null, null)

// }