import { render, staticRenderFns } from "./IndividualAnalysisPatientInfo.vue?vue&type=template&id=2484e216&scoped=true"
import script from "./IndividualAnalysisPatientInfo.vue?vue&type=script&lang=js"
export * from "./IndividualAnalysisPatientInfo.vue?vue&type=script&lang=js"
import style0 from "./IndividualAnalysisPatientInfo.vue?vue&type=style&index=0&id=2484e216&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2484e216",
  null
  
)

export default component.exports