export default {
    data() {
        return {
            VARIANT_COLORS:{
                'DEL': "#ff4462",
                'DUP': "#E9A643",
                'INS': "#4BAAd7",
                'INV': "#417037",
                'TRN': "#C585B7",
                'BND': "#753939",
                'SNP': "#4776a1",
                'DUP:TAND': "#67bf56",
                'TR': "#888888"
            }
        }
    }
}