<template>
  <div style="min-height: 300px">
    <TrSnvChart ref="refrenceTad" :type="type" :rarePathogenicTrackHintCondition="rarePathogenicTrackHintCondition" :variantId="variantId" :chromosome="chromosome" :mutations="sv" :isoforms="coolboxData.transcripts" :pedigree="pedigree" :geneEnhancers="geneEnhancers" :details="coolboxMoreDetails" :selectedUsersGenes="selectedUsersGenes"></TrSnvChart>
  </div>
</template>
<script>
import TrSnvChart from "@/components/defaultAnalysis/tad/TrSnvChart.vue";
import { getVariantTads } from "@/api/user/dataset";
import { getDatasetFields } from "@/api/dataset";
import { getData } from "@/api/user/dataset";
// import { getDatasetFields } from "@/api/dataset";
export default {
  name: "TrSnvcoolbox",
  components: { TrSnvChart },
  props: {
    cohortId: {
      type: Number,
      default: 1,
      // required: true,FF
    },
    selectedDatabaseId: {
      type: Number,
      default: 1,
      // required: true,
    },
    variantId: {
      type: String,
      // default: 'chr17-44524913-DEL-176'
      default: null,
      // required: true,
    },
    sv: {
      type: Array,
      default: () => [],
    },
    selectedUsersGenes: {
      type: Array,
      required: true,
    },
    chromosome: {
      type: Object,
      default: () => ({
        id: null,
        start: 0,
        end: 0,
      }),
      // required: true,
    },
    differentiallyExpressedGenes:{
      type: Array,
      default: () => [],
    },
    type:{
      type: String,
      required: true
    },
    rarePathogenicTrackHintCondition:{
      type:String,
      required: true
    }
  },
  data: () => ({
    coolboxData: {
      tads: [],
      connections: [],
      dynamic_tracks: [],
      enhancers: [],
      genes: [],
      transcripts: [],
      rare_pathogenic_svs: [],
      pedigree: {},
    },
    datasetFields: [],
    datasetFieldGroups: [],
    coolboxDetails: [],
  }),
  methods: {
    getImage() {
      return this.$refs.refrenceTad.getImage();
    },
    loadcoolboxDetails() {
      var id = this.datasetFields.find((item) => item.title == "ID").name;
      let idFilter = {};
      idFilter[id] = this.variantId;
      const allExactFilters = [idFilter];
      const _this = this;
      getData(this.cohortId, this.selectedDatabaseId, allExactFilters, [], [],[], _this.datasetFieldGroups, "one_of_all", 1, 0, [], [], [], ['ID','OVERLAPPING_GENES'],[], function (res) {
        _this.coolboxDetails = res.result[0];
        // console.log(_this.coolboxDetails);
        // console.log(res.result)
      });
    },
  },
  computed: {
    coolboxMoreDetails() {
      delete this.coolboxDetails.__usr
      delete this.coolboxDetails.__organization
      const details =  Object.keys(this.coolboxDetails).map((item) => {
        let x = {
          name: item,
          value: this.coolboxDetails[item],
        };
        const types = ["OMIM_LINK", "CLINVAR_LINK", "GENE", "SNP_LINK"];
        x["type"] = null;
        types.forEach(element => {
          // console.log('this.datasetFields',this.datasetFields, element )
          const c =this.datasetFields.find((x) => x.title == item)
          if (c && c.groups.findIndex((y) => y.groupType == element) > -1) {
            x["type"] = element;
          } 
        })

        return x;
      });
      return details
    },
    rarePathogenicSvs() {
      return [];
    },
    pedigree() {
      return this.coolboxData.pedigree;
    },
    geneEnhancers() {
      // console.log("geneEnhancers:180", this.coolboxData.genes);
      let genes = this.coolboxData.genes.map((item) => {
        let g = item;
        g["id"] = g.name;
        g["chromosome"] = g.chr;
        g["dir"] = g.direction;
        g["userSelected"]  = this.selectedUsersGenes.indexOf(g.name.toUpperCase()) >= 0
        g["differentiallyExpressed"]  = this.differentiallyExpressedGenes.indexOf(g.name.toUpperCase()) >= 0

        g["type"] = "GENE";
        return g;
      });
      let enhancers = this.coolboxData.enhancers.map((item) => {
        let e = item;
        e["id"] = e.name;
        e["chromosome"] = e.chr;

        e["dir"] = e?.direction || null;
        e["type"] = "RE";
        return e;
      });
      let connections = this.coolboxData.connections.map((c) => {
        return { from: c.enhancer, to: c.gene, color: 3 };
      });
      return { elements: genes.concat(enhancers), connections: connections };
    },
  },
  watch: {
    variantId() {
      const _this = this;
      getVariantTads(this.cohortId, this.selectedDatabaseId, this.variantId, (res) => {
        _this.coolboxData = res;
      });
      this.loadcoolboxDetails();
    },
  },
  created() {
    const _this = this;
    getDatasetFields(_this.selectedDatabaseId, (res)=>{
      _this.datasetFields = res.filter(item => item.title == 'OVERLAPPING_GENES' || item.title == 'ID' || item.groups.findIndex((g) => g.groupType == "COOLBOX_DETAILS") >=0)
      _this.datasetFieldGroups = [_this.datasetFields.find(item=> item.groups.findIndex(g => g.groupType == "COOLBOX_DETAILS") >= 0).groups.find(t=>t.groupType == "COOLBOX_DETAILS").id]
      _this.loadcoolboxDetails();
    })
    
    getVariantTads(this.cohortId, this.selectedDatabaseId, this.variantId, (res) => {
      _this.coolboxData = res;
    });
  },
};
</script>
<style scoped>
.tadBody {
  background-color: #f5f5f5;
  border-bottom: 1px solid #d3d3d3 !important;
  border-right: 1px solid #d3d3d3 !important;
  border-left: 1px solid #d3d3d3 !important;
  border-radius: 0 0 8px 8px;
}
.tadHeader {
  border-top: 1px solid #d3d3d3 !important;
  border-right: 1px solid #d3d3d3 !important;
  border-bottom: 1px solid #d3d3d3 !important;
  border-left: 1px solid #d3d3d3 !important;
  border-radius: 8px 8px 0 0;
}
::v-deep .jsoneditor-vue {
  height: 100% !important;
}

::v-deep .jsoneditor-frame {
  display: none;
}
</style>