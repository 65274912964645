<template>
  <div ref="container" class="mx-auto pa-2 d-flex flex-column" style="width: 100%">
    <div class="px-2 pt-3 pb-2 d-flex flex-row" style="width: 101%; background-color: #f5f5f5">
      <TrSnvDetails :details="moreDetails"></TrSnvDetails>
      <div class="ml-auto mt-n2 d-flex flex-row">
      <v-tooltip top >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on" icon @click="onResetZoom()"> <v-icon>mdi-magnify-scan</v-icon></v-btn>
        </template>
        <span>Reset Zoom</span>
      </v-tooltip>
      <v-tooltip top >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon color="primary"  v-bind="attrs" v-on="on" @click="onExportAsPng"> <v-icon>mdi-download</v-icon></v-btn>
        </template>
        <span>Download as PNG</span>
      </v-tooltip>
      <v-tooltip top >
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="primary" v-bind="attrs" v-on="on">mdi-help</v-icon>
        </template>
        <span>Hold Ctrl to enable Sticky-Tooltip mode. It helps you to:</span>
        <ul>
          <li>See multiple tooltips at the same time</li>
          <li>Drag and move tooltips </li>
        </ul>      </v-tooltip>
    </div>
    </div>


    <v-stage ref="stage" :config="stageSize" @mousedown="onZoomStarted" @mouseup="onZoomEnded" @mousemove="onZoomSelecting">
      <v-layer>
        <v-rect  :config="backgroundRectConfig"></v-rect>
      </v-layer>
      <v-layer>
        <!-- <v-rect ref="svShadow" :config="mutationHighlightRectConfig"></v-rect> -->
        <!-- <Chromosome  :spec="chromosomeInfo" :baseLineY="0"></Chromosome> -->
        <GeneTrack :legendItems="legendItems" :showEnhancers="false" :svHighlightInfo="svHighlightInfo" :tadInfo="tadInfo" :baseLineY="geneTrackBaseline" :genes="allGenes" :elements="allREs" :connections="allConnections" @onGeneTrackHeight="onGeneTrackHeight"></GeneTrack>
        <MutationTrack :trackName="type" :tadInfo="tadInfo" v-if="mutations.length > 0" :baseLineY="baseLineY + geneTrackHeight" :mutations="mutations"  @onSvBar="onSvBar"></MutationTrack>
        <RarePathogenicSVsTrack :title="`Proband ${type}s`" :hint="rarePathogenicTrackHintCondition" :svHighlightInfo="svHighlightInfo" :tadInfo="tadInfo" :baseLineY="rareProbandPathogenicSVsTrackBaseLine" :svs="pedigreeProbandSVs" @onRarePathogenicSVsTrackHeight="onRareProbandPathogenicSVsTrackHeight"></RarePathogenicSVsTrack>
        <RarePathogenicSVsTrack :showLegend="false" :hidden="pedigree.mother === undefined" :title="pedigree.mother ? `Mother (${pedigree.mother.sample_id }): ${pedigree.mother.status } ` : ''"   :svHighlightInfo="svHighlightInfo" :tadInfo="tadInfo" :baseLineY="rareMotherPathogenicSVsTrackBaseLine" :svs="pedigreeMotherSVs" @onRarePathogenicSVsTrackHeight="onRareMotherPathogenicSVsTrackHeight"></RarePathogenicSVsTrack>
        <RarePathogenicSVsTrack :showLegend="false" :hidden="pedigree.father === undefined" :title="pedigree.father ? `Father (${pedigree.father.sample_id }): ${pedigree.father.status } ` : ''"   :svHighlightInfo="svHighlightInfo" :tadInfo="tadInfo" :baseLineY="rareFatherPathogenicSVsTrackBaseLine" :svs="pedigreeFatherSVs" @onRarePathogenicSVsTrackHeight="onRareFatherPathogenicSVsTrackHeight"></RarePathogenicSVsTrack>
        <RarePathogenicSVsTrack :showLegend="false" :hidden="pedigree.family === undefined" :title="pedigree.family ? `Other Relatives (${pedigree.family.family })` : ''"   :svHighlightInfo="svHighlightInfo" :tadInfo="tadInfo" :baseLineY="rareFamilyPathogenicSVsTrackBaseLine" :svs="pedigreeFamilySVs" @onRarePathogenicSVsTrackHeight="onRareFamilyPathogenicSVsTrackHeight"></RarePathogenicSVsTrack>
        <FishboneTrack ref="FishboneTrack" :svHighlightInfo="svHighlightInfo" :showName="true" :tadInfo="tadInfo" :baseLineY="fishboneTrackBaseline" :isoforms="this.canonicalIsoforms" :sv="selectedMutation" ></FishboneTrack>
        <Axis :tadInfo="tadInfo" :baseLineY="axisBaseline"></Axis>
      </v-layer>

      <v-layer>
        <v-rect :config="zoomAreaRectConfig"></v-rect>
      </v-layer>
    </v-stage>
    
    <!-- <div v-if="mutations.length > 0" class="d-flex flex-row">
        <v-btn :disabled="selectedMutations.length == 0" class="mx-auto"  width="200px" @click="onApply">Apply</v-btn>
    </div> -->
  </div>
</template>
<script>
// const height = window.innerHeight*0.85;
// const height = 900;
// import Chromosome from "@/components/defaultAnalysis/tad/Chromosome.vue";
import Axis from "@/components/defaultAnalysis/tad/Axis.vue";
import MutationTrack from "@/components/defaultAnalysis/tad/MutationTrack.vue";
import FishboneTrack from "@/components/defaultAnalysis/tad/Transcripts/FishboneTrack.vue";
import RarePathogenicSVsTrack from "@/components/defaultAnalysis/tad/RarePathogenicSVsTrack.vue";
import GeneTrack from "@/components/defaultAnalysis/tad/GeneTrack.vue";
import TrSnvDetails from "@/components/defaultAnalysis/tad/TrSnvDetails.vue";

import { toScatedX } from "@/utils/tad.js";

export default {
  name: "TrSnvChart",
  components: {  Axis , FishboneTrack, MutationTrack, RarePathogenicSVsTrack, GeneTrack, TrSnvDetails},
  // components: { Tad , Axis, GeneTrack, MutationTrack, DynamicTrack},
  props: {
    chromosome: null,
    mutations: {
      type: Array(),
      default: () => [],
    },
    geneEnhancers: null,

    isoforms: {
      type: Array(),
      default: () => [],
    },
    variantId: {
      type: String,
      // default: 'chr17-44524913-DEL-176'
      default: null,
      // required: true,
    },
    pedigree:{
      type: Object,
      default: () => {},
    },
    details:{
      type: Array,
      required: true
    },
    type:{
      type: String,
      required: true
    },
    rarePathogenicTrackHintCondition:{
      type: String,
      required:true
    }
  },

  data() {
    return {
      legendItems: [
     {
        text:'User Filtered',
        // fillColor: null,
        fillColor: null,
        borderColor:'green',
        borderStyle:'dashed',
        type: 'line',
      },
      {
        text:'Hit by SV',
        // fillColor: null,
        fillColor: '#ff0000',
        borderColor:'black',
        borderStyle:'solid',
        type: 'line',
      },
      {
        text:'Differentially Expressed (q-value < 0.05)',
        // fillColor: null,
        fillColor: null,
        borderColor:'blue',
        borderStyle:'solid',
        type: 'hatched',
      },      
      {
        text:'HPO-Associated',
        fillColor: '#ff7575',  //#10
        borderColor:null,
        borderStyle: null,
        type: 'square',
      },
      {
        text:'Provided in Background',
        fillColor: '#e60049', //#2
        borderColor:null,
        borderStyle: null,
        type: 'square',
      },
      {
        text:'Others',
        fillColor: '#b3d4ff', //#0
        borderColor:null,
        borderStyle: null,
        type: 'square',
      }
    ],
      width: 0,
      mutationHighlightShow: false,
      rareProbandPathogenicSVsTrackHeight: 25,
      rareMotherPathogenicSVsTrackHeight: 0,
      rareFatherPathogenicSVsTrackHeight: 0,
      rareFamilyPathogenicSVsTrackHeight: 0,
      geneTrackHeight: 20,

      // fishboneTrackHeight: 50,
      // selectedMutation: null,
      // minMaxInfo: null,
      //COLORS from: https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
      colors: ["#b3d4ff", "#d0d0d0", "#e60049", "#0bb4ff", "#50e991", "#e6d800", "#9b19f5", "#ffa300", "#dc0ab4", "#00bfa0", "#ff7575"],
      
      mutationTrackHeight: 46,
      svHighlightInfo:{
        start: 0,
        width: 0
      },
      isZooming: false,
      zoomAreaRect: {
        end: 0,
        start: 0,
      },
      currentMinMax: null,
    };
  },
  mounted() {
    this.width = this.$refs.container.clientWidth * 0.99;
  },

  methods: {
    onResetZoom() {
      this.currentMinMax = this.minMax;
    },
    onZoomStarted(e) {
      if (!e.evt.ctrlKey && e.evt.button == 0) {
        this.isZooming = true;

        this.zoomAreaRect.start = e.currentTarget.getPointerPosition().x;
        this.zoomAreaRect.end = e.currentTarget.getPointerPosition().x;
      }
    },
    onZoomEnded() {
      if (this.isZooming) {
        this.isZooming = false;
        if (Math.abs(this.zoomAreaRect.start - this.zoomAreaRect.end) > 4) {
          if (this.currentMinMax == null) {
            this.currentMinMax = this.minMax;
          }
          const scale = (this.currentMinMax.mx - this.currentMinMax.mn) / this.width;
          const min = this.currentMinMax.mn + Math.round(Math.min(this.zoomAreaRect.start, this.zoomAreaRect.end) * scale);
          const max = this.currentMinMax.mn + Math.round(Math.max(this.zoomAreaRect.start, this.zoomAreaRect.end) * scale);
          this.currentMinMax = {
            mn: min,
            mx: max,
          };
          // console.log("currentMinMax", this.currentMinMax.mn, this.currentMinMax.mx);
        }
      }
    },
    onZoomSelecting(e) {
      if (this.isZooming) {
        this.zoomAreaRect.end = e.currentTarget.getPointerPosition().x;
      }
    },
    onRareProbandPathogenicSVsTrackHeight(val) {
      this.rareProbandPathogenicSVsTrackHeight = val;
    },
    onRareMotherPathogenicSVsTrackHeight(val) {
      this.rareMotherPathogenicSVsTrackHeight = val;
    }, 
    onRareFatherPathogenicSVsTrackHeight(val) {
      this.rareFatherPathogenicSVsTrackHeight = val;
    },  
    onRareFamilyPathogenicSVsTrackHeight(val) {
      this.rareFamilyPathogenicSVsTrackHeight = val;
    },        
    onSvBar(start, width){
        this.svHighlightInfo.start = start
        this.svHighlightInfo.width = width      
    },
    getImage(){
      let stage = this.$refs.stage.getNode();
      const header='data:image/png;base64,'
      return stage.toDataURL({ pixelRatio: 1.5 }).slice(header.length);
    },
    toX(num) {
      return toScatedX(num, this.tadInfo.min, this.tadInfo.scale);
    },
    downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      // delete link;
    },
    onExportAsPng() {
      let stage = this.$refs.stage.getNode();
      var dataURL = stage.toDataURL({ pixelRatio: 2 });
      this.downloadURI(dataURL, this.variantId + ".png");
    },
    onGeneTrackHeight(val) {
      this.geneTrackHeight = val + 2;
    },
  },
  computed: {
    zoomAreaRectConfig() {
      return {
        x: Math.min(this.zoomAreaRect.end, this.zoomAreaRect.start),
        y: 0,
        width: this.isZooming ? Math.abs(this.zoomAreaRect.end - this.zoomAreaRect.start) : 0,
        height: this.stageSize.height,
        stroke: "#0393A8",
        // strokeWidth: this.selected? 1 : 0 ,
        strokeWidth: this.isZooming ? 0.1 : 0,
        // fill: this.colors[this.info.type],
        fill: "#0393A810",
      };
    },
    moreDetails(){
      let x = [{name: 'Chromosome', value: `${this.chromosomeInfo.id} (${this.chromosomeInfo.start},${this.chromosomeInfo.end})`}]
      return x.concat(this.details)
    },
    geneTrackBaseline() {
      return this.baseLineY ;
    },
    pedigreeProbandSVs(){
      if (this.pedigree.proband && this.pedigree.proband.SVs){
        const selected = this.pedigree.proband.SVs.filter(item => item.start >= this.tadInfo.min && item.end <= this.tadInfo.max  ) 
        // console.log(this.variantId, ' Proband:', selected.length)
        return selected
      } else {
        return []
      }
    },
    pedigreeMotherSVs(){
      if (this.pedigree.mother && this.pedigree.mother.SVs){
        const selected =  this.pedigree.mother.SVs.filter(item => item.start >= this.tadInfo.min && item.end <= this.tadInfo.max  )
        // console.log(this.variantId, 'mother:', selected.length)
        return selected
      } else {
        return []
      }
    },
    pedigreeFatherSVs(){
      if (this.pedigree.father && this.pedigree.father.SVs){
        const selected =  this.pedigree.father.SVs.filter(item => item.start >= this.tadInfo.min && item.end <= this.tadInfo.max  )
        // console.log(this.variantId, 'father:', selected.length)

        return selected
      } else {
        return []
      }
    },
    pedigreeFamilySVs(){
      if (this.pedigreeSnvs?.family?.SVs){
        const selected =  this.pedigreeSnvs.family.SVs.filter(item => item.start >= this.tadInfo.min && item.end <= this.tadInfo.max  )
        // console.log(this.variantId, 'father:', selected.length)

        return selected
      } else {
        return []
      }
    },    
    rareProbandPathogenicSVsTrackBaseLine(){
      return this.baseLineY + this.geneTrackHeight + this.mutationTrackHeight
      // return this.baseLineY + this.mutationTrackHeight
    }, 
    rareMotherPathogenicSVsTrackBaseLine(){
      return this.rareProbandPathogenicSVsTrackBaseLine + this.rareProbandPathogenicSVsTrackHeight
    }, 
    rareFatherPathogenicSVsTrackBaseLine(){
      return this.rareMotherPathogenicSVsTrackBaseLine + this.rareMotherPathogenicSVsTrackHeight
    }, 
    rareFamilyPathogenicSVsTrackBaseLine(){
      return this.rareFatherPathogenicSVsTrackBaseLine + this.rareFatherPathogenicSVsTrackHeight
    }, 


    fishboneTrackBaseline() {
      return this.baseLineY + this.geneTrackHeight +  this.mutationTrackHeight +1 + this.rareFatherPathogenicSVsTrackHeight + this.rareMotherPathogenicSVsTrackHeight + this.rareFamilyPathogenicSVsTrackHeight + this.rareProbandPathogenicSVsTrackHeight;
    },
    fishboneTrackHeight(){
      return Math.max(this.fishboneTrackBaseline + this.canonicalIsoforms.length * 22 + 40 , 80)
    },
    axisBaseline() {
      // return this.baseLineY + 50;
      // console.log('this.fishboneTrackHeight', this.fishboneTrackHeight)
      return Math.max(this.fishboneTrackHeight , 130);
    },
    chromosomeInfo() {
      return {
        id: this.chromosome.id,
        start: this.tadInfo.min,
        end: this.tadInfo.max,
      };
    },
    selectedMutation() {
      if (this.mutations.length > 0) {
        return this.mutations[0];
      } else {
        return null;
      }
    },
    tadInfo() {

      let min = 0;
      let max = 0;
      if (this.currentMinMax != null) {
        // console.log(scale)
        min = this.currentMinMax.mn;
        max = this.currentMinMax.mx;
        // console.log(min,max)
      } else {
        min = this.minMax.mn;
        max = this.minMax.mx;
      }
      return {
        min: min,
        max: max,
        scale: this.width / (max - min),
      };

      // return {
      //   min: this.minMax.mn,
      //   max: this.minMax.mx,
      //   scale: this.width / (this.minMax.mx - this.minMax.mn),
      // };
    },
    backgroundRectConfig() {
      return {
        x: 0,
        y: 0,
        width: this.stageSize.width-10,
        height: this.stageSize.height,
        // stroke: "black",
        // strokeWidth: this.selected? 1 : 0 ,
        strokeWidth: 0,
        // fill: this.colors[this.info.type],
        fill: "#f5f5f5",
      };
    },

    stageHeight() {
      return this.fishboneTrackHeight + this.mutationTrackHeight + 50;
      // return this.fishboneTrackBaseline + this.isoforms.length * 23 + 40
    },

    stageSize() {
      return {
        width: this.width + 30,
        height: this.stageHeight,
      };
    },
    normalizationFactor() {
      return this.width / (this.minMax.mx - this.minMax.mn);
    },
    minMax() {
      const iso = this.canonicalIsoforms
      const mut = this.mutations 
      let ends = iso.map((item) => item.end);
      ends = mut.map((item) => item.end).concat(ends);

      let starts = iso.map((item) => item.start);
      starts = mut.map((item) => item.start).concat(starts);

      const mx = ends.reduce((a, b) => Math.max(a, b), 0);
      const mn = starts.reduce((a, b) => Math.min(a, b), +Infinity);
      const size = mx - mn
      return { mn: mn -  Math.round(size*0.2)  , mx: mx  + Math.round( size*0.2) };
    },
    baseLineY() {
      return 10
    },
    canonicalIsoforms() {
      return this.isoforms.filter(item => item.canonical == true || item.canonical == undefined);
    },
    allConnections() {
      return []
      // return this.geneEnhancers.connections.map((cn) => {
      //   const el = this.geneEnhancers.elements.find((el) => el.id == cn.from);
      //   const gn = this.geneEnhancers.elements.find((gn) => gn.id == cn.to);
      //   const r = {
      //     from: el.start + (el.end - el.start) / 2,
      //     fromId: el.id,
      //     to: gn.dir == "+" ? gn.start : gn.end, //+ (gn.end - gn.start) / 2,
      //     toId: gn.id,
      //     color: cn.color,
      //     colorStr: this.colors[cn.color % this.colors.length],
      //   };
      //   return r;
      // });
    },
    allGenes() {
      return this.geneEnhancers.elements
        .filter((item) => item.type == "GENE" && this.tadInfo.max >= item.start && this.tadInfo.min <= item.end)
        .map((item) => {
          item.colorStr = this.colors[item.color % this.colors.length];
          item.isHit = this.selectedMutation.end >= item.start && this.selectedMutation.start <= item.end;
          return item;
        });
    },
    allREs() {
      return []
      // return this.geneEnhancers.elements
      //   .filter((item) => item.type == "RE")
      //   .map((item) => {
      //     item.colorStr = this.colors[item.color % this.colors.length];
      //     item.isHit = this.selectedMutation.end >= item.start && this.selectedMutation.start <= item.end;
      //     return item;
      //   });
    },
  },
};
</script>

<style scoped>
.borderedBox {
  box-sizing: border-box;
  background-color: rgb(238, 238, 238);
  border: 1px solid rgb(177, 177, 177);
  border-radius: 8px;
}
</style>